import * as constants from "../forms/constants/completeProfileConstants";

const getGenderValueFromArrString = (value) => {
  const found = constants.genderOptions.find(
    (option) => option.value === value
  );
  return found ? found.label : "-";
};

export default getGenderValueFromArrString;
