import React from "react";
import ReactDOM from "react-dom";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import keycloak from "./keycloak";
import api from "./utils/api";
import endpoints from "./utils/endpoints";
import roles from "./utils/roles";

if (window.location.href.includes("prod-appser-fe-001.azurewebsites.net")) {
  window.location.replace("https://breathe.eno.org");
}

// const eventLogger = (event, error) => {
//   console.log("onKeycloakEvent", event, error);
// };

const tokenLogger = (tokens) => {
  sessionStorage.setItem("auth.accessToken", tokens.token);
  if (!roles.admin) {
    api.getAPI(`${endpoints.getUsers}/status`).then((data) => {
      sessionStorage.setItem("user.status", data.data);
    });
  }
};

ReactDOM.render(
  <React.StrictMode>
    <ReactKeycloakProvider
      authClient={keycloak}
      // onEvent={eventLogger}
      onTokens={tokenLogger}
    >
      <App />
    </ReactKeycloakProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
