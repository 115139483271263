import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { Box, Breadcrumbs, Grid, Typography } from "@material-ui/core";
import computeSectionIcons from "../utils/computeSectionIcons";
import SecondaryLink from "../components/SecondaryLink";
import UserContext from "../context/UserContext";
import EditableSection from "../components/EditableSection";

const AboutTheEnoBreatheProgramme = ({
  title,
  breadcrumbs,
  heroImage,
  navAnchors,
  editableContentLocations,
}) => {
  const [pageContent, setPageContent] = useState(
    '{ "blocks": [], "entityMap": {} }'
  );
  const [computedPageAnchors, setComputedPageAnchors] = useState([]);

  useEffect(() => {
    // TODO: replace with an async call to API endpoint
    setPageContent(editableContentLocations[0]);
  }, [editableContentLocations]);

  useEffect(() => {
    navAnchors &&
      setComputedPageAnchors(computeSectionIcons(pageContent, navAnchors));
  }, [navAnchors, pageContent]);

  return (
    <>
      {heroImage && (
        <Box mb={2} mt={1} clone>
          <img className="Page-hero" src={heroImage.url} alt={heroImage.alt} />
        </Box>
      )}

      {/* TODO: Integrate with React router? */}
      {breadcrumbs && (
        <Breadcrumbs separator="»" aria-label="breadcrumb">
          <SecondaryLink component={NavLink} color="inherit" to="/">
            ENO Breathe
          </SecondaryLink>
          <Typography variant="inherit">{title}</Typography>
        </Breadcrumbs>
      )}

      <Typography variant="h1" gutterBottom>
        {title}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {navAnchors && computedPageAnchors}
        </Grid>
        <Grid item xs={12} md={6}>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/yX1z_LneMAo"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Grid>
      </Grid>
      <UserContext.Consumer>
        {({ isAdmin }) => (
          <EditableSection
            isAdmin={isAdmin}
            editableContent={editableContentLocations[0]}
          />
        )}
      </UserContext.Consumer>
    </>
  );
};

AboutTheEnoBreatheProgramme.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.bool,
  heroImage: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }),
  navAnchors: PropTypes.oneOf(["header-two", "SUBTITLE", false]),
  editableContentLocations: PropTypes.arrayOf(PropTypes.string).isRequired,
};

AboutTheEnoBreatheProgramme.defaultProps = {
  breadcrumbs: true,
  heroImage: null,
  navAnchors: false,
};

export default AboutTheEnoBreatheProgramme;
