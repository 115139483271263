import React, { useEffect, useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import EditDetailsForm from "../../../components/admin/EditDetailsForm";
import { getNewGroupFormHeaders } from "../../../services/groupsService";
import api from "../../../utils/api";
import endpoints from "../../../utils/endpoints";
import NotificationSnackbarContext from "../../../context/NotificationSnackbarContext";
import { SnackbarTypes } from "../../../components/CustomSnackbar";

const CreateGroup = () => {
  const history = useHistory();

  const { groupId } = useParams();
  const [formLabels, setFormLabels] = useState([]);
  const [loading, setLoading] = useState(true);
  const { notification, setNotification } = useContext(
    NotificationSnackbarContext
  );
  const [group, setGroup] = useState({
    groupName: "",
    sessionLeader: "",
    participants: [],
    coordinator: "",
    week1DateTime: "",
    week2DateTime: "",
    week3DateTime: "",
    week4DateTime: "",
    week5DateTime: "",
    week6DateTime: "",
    sessionLink: "",
  });

  const handleSaveToDb = async (newData) => {
    const groupBody = JSON.stringify(newData);
    return api
      .postAPI(endpoints.postAdminGroups, null, groupBody)
      .then((data) => {
        if (data.status >= 400) {
          setNotification({
            ...notification,
            message: "The was an error saving this group",
            color: SnackbarTypes.ERROR,
            key: new Date(),
          });
        } else {
          setNotification({
            ...notification,
            message: "Group saved",
            color: SnackbarTypes.SUCCESS,
            key: new Date(),
          });
        }
      })
      .catch(() => {
        setNotification({
          ...notification,
          message: "The was an error saving this group",
          color: SnackbarTypes.ERROR,
          key: new Date(),
        });
      });
  };

  useEffect(() => {
    async function fetchData() {
      const headers = await getNewGroupFormHeaders();
      setFormLabels(headers);
    }

    fetchData().then(() => {
      setLoading(false);
    });
  }, [groupId]);

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <EditDetailsForm
          headers={formLabels}
          data={group}
          handleSave={handleSaveToDb}
          forCreate
        />
      )}
    </>
  );
};

export default CreateGroup;
