import "./App.css";
import React, { lazy, Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
  withRouter,
} from "react-router-dom";
import {
  Container,
  CssBaseline,
  ThemeProvider,
  useMediaQuery,
} from "@material-ui/core";
import { useKeycloak } from "@react-keycloak/web";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import axios from "axios";
import logo from "./assets/images/ENO_BREATHE_LOGOTYPE_POS_AW-FINAL.svg";
import enoBreatheTheme from "./theme/enoBreatheTheme";
import UserContext from "./context/UserContext";
import reactPlugin from "./utils/appInsights";

import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";

import contentPagesConfig from "./routes/contentPagesConfig";
import Login from "./components/Login";
import roles from "./utils/roles";
import NotificationSnackbarContext from "./context/NotificationSnackbarContext";
import NotificationSnackbar from "./components/NotificationSnackbar";
import { SnackbarTypes } from "./components/CustomSnackbar";
import { SMALL_SCREEN_MEDIA_CONDITION } from "./responsive/media-conditions";

// Lazy loaded components must be wrapped in a <Suspense/>
const SignUp = lazy(() => import("./routes/SignUp"));
const Styles = lazy(() => import("./routes/Styles"));
const Survey = lazy(() => import("./routes/Survey"));
const Programme = lazy(() => import("./routes/Programme"));
const Profile = lazy(() => import("./routes/Profile"));
const ParticipantHub = lazy(() => import("./routes/ParticipantHub"));
const NotFoundPage = lazy(() => import("./routes/NotFoundPage"));
const OutOfProgramPage = lazy(() => import("./routes/OutOfProgramPage"));
const PrivateRoute = lazy(() => import("./routes/PrivateRoute"));
const WeekOne = lazy(() => import("./routes/WeekOne"));
const WeekTwo = lazy(() => import("./routes/WeekTwo"));
const WeekThree = lazy(() => import("./routes/WeekThree"));
const WeekFour = lazy(() => import("./routes/WeekFour"));
const WeekFive = lazy(() => import("./routes/WeekFive"));
const WeekSix = lazy(() => import("./routes/WeekSix"));
const CalmingPlaylist = lazy(() => import("./routes/CalmingPlaylist"));
const AdminPage = lazy(() => import("./routes/AdminPage"));
const WhatToExpectFromASession = lazy(() =>
  import("./routes/WhatToExpectFromASession")
);
const fullWidthPaths = [
  "/admin/users",
  "/admin/groups",
  "/admin/surveys",
  "/admin/clinicsAndReferral",
  "/admin/dashboard",
];
const Loader = () => {
  return (
    <div className="App-loader">
      <img src={logo} alt="logo" className="App-loader--icon" />
    </div>
  );
};

const checkFullScreen = (location) => {
  let isFullScreen = false;
  fullWidthPaths.forEach((pathfull) => {
    if (!isFullScreen && location.indexOf(pathfull) >= 0) {
      isFullScreen = true;
    }
  });
  return isFullScreen;
};

function ScrollToTopFunc(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}
const ScrollToTop = withRouter(ScrollToTopFunc);

const App = () => {
  const { keycloak, initialized } = useKeycloak();
  const [userInfo, setUserInfo] = useState({});
  const [isFullWidth, setIsFullWidth] = useState(
    checkFullScreen(window.location.pathname)
  );
  const [notification, setNotification] = useState({ key: new Date() });

  const isSmallScreen = useMediaQuery(SMALL_SCREEN_MEDIA_CONDITION);

  axios.interceptors.response.use(
    (success) => {
      return success;
    },
    (error) => {
      if (error.config.method !== "get") {
        setNotification({
          ...notification,
          message:
            "There was an error. Please try again later or contact the administrator",
          color: SnackbarTypes.ERROR,
          key: new Date(),
        });
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    if (initialized && keycloak.authenticated) {
      // Add as needed
      setUserInfo({
        roles: keycloak.tokenParsed.realm_access.roles,
        isAdmin: keycloak.tokenParsed.realm_access.roles.includes("admin"),
        name: keycloak.tokenParsed.name,
      });
      localStorage.setItem("token", keycloak.token);
    }
  }, [keycloak, initialized]);
  const checkIsFullScreen = (pathname) => {
    setIsFullWidth(checkFullScreen(pathname) >= 0);
  };

  return (
    <>
      {initialized ? (
        <AppInsightsContext.Provider value={reactPlugin}>
          <ThemeProvider theme={enoBreatheTheme}>
            <CssBaseline />
            <div className="App">
              <Router>
                <ScrollToTop>
                  <NotificationSnackbarContext.Provider
                    value={{ notification, setNotification }}
                  >
                    <NotificationSnackbar key={notification.key} />
                    <UserContext.Provider value={userInfo}>
                      <AppHeader checkIsFullScreen={checkIsFullScreen} />
                      <Container
                        maxWidth={isFullWidth ? false : "lg"}
                        style={
                          isFullWidth
                            ? { padding: `0 ${isSmallScreen ? 10 : 54}px` }
                            : null
                        }
                      >
                        {/* Lazy loaded components must be wrapped in a <Suspense/> */}
                        <Suspense fallback={<Loader />}>
                          <Switch>
                            {contentPagesConfig.map((pageConfig) => (
                              <Route
                                exact
                                key={pageConfig.path}
                                path={`/${pageConfig.path}`}
                                render={() =>
                                  React.createElement(
                                    pageConfig.component,
                                    pageConfig.meta
                                  )
                                }
                              />
                            ))}
                            <Route exact path="/styles" component={Styles} />
                            <Route exact path="/signup" component={SignUp} />
                            <PrivateRoute
                              exact
                              path="/programme"
                              component={Programme}
                              allowedRoles={[
                                roles.preProgramme,
                                roles.inProgramme,
                                roles.postProgramme,
                              ]}
                            />
                            <PrivateRoute
                              exact
                              path="/profile"
                              component={Profile}
                              allowedRoles={[roles.preProgramme]}
                            />
                            <PrivateRoute
                              exact
                              path="/survey"
                              component={Survey}
                              allowedRoles={[
                                roles.preProgramme,
                                roles.inProgramme,
                                roles.postProgramme,
                              ]}
                            />
                            <PrivateRoute
                              exact
                              path="/programme/weekone"
                              component={WeekOne}
                              allowedRoles={[
                                roles.preProgramme,
                                roles.inProgramme,
                                roles.postProgramme,
                              ]}
                            />
                            <PrivateRoute
                              exact
                              path="/programme/weektwo"
                              component={WeekTwo}
                              allowedRoles={[
                                roles.preProgramme,
                                roles.inProgramme,
                                roles.postProgramme,
                              ]}
                            />
                            <PrivateRoute
                              exact
                              path="/programme/weekthree"
                              component={WeekThree}
                              allowedRoles={[
                                roles.preProgramme,
                                roles.inProgramme,
                                roles.postProgramme,
                              ]}
                            />
                            <PrivateRoute
                              exact
                              path="/programme/weekfour"
                              component={WeekFour}
                              allowedRoles={[
                                roles.preProgramme,
                                roles.inProgramme,
                                roles.postProgramme,
                              ]}
                            />
                            <PrivateRoute
                              exact
                              path="/programme/weekfive"
                              component={WeekFive}
                              allowedRoles={[
                                roles.preProgramme,
                                roles.inProgramme,
                                roles.postProgramme,
                              ]}
                            />
                            <PrivateRoute
                              exact
                              path="/programme/weeksix"
                              component={WeekSix}
                              allowedRoles={[
                                roles.preProgramme,
                                roles.inProgramme,
                                roles.postProgramme,
                              ]}
                            />
                            <PrivateRoute
                              exact
                              path="/a-calming-playlist"
                              component={CalmingPlaylist}
                              allowedRoles={[
                                roles.inProgramme,
                                roles.postProgramme,
                                roles.admin,
                              ]}
                            />
                            <PrivateRoute
                              exact
                              path="/what-to-expect-from-a-session"
                              component={WhatToExpectFromASession}
                              allowedRoles={[
                                roles.inProgramme,
                                roles.postProgramme,
                                roles.admin,
                              ]}
                            />

                            <Route exact path="/login" component={Login} />
                            <PrivateRoute
                              exact
                              path="/participanthub"
                              allowedRoles={[
                                roles.inProgramme,
                                roles.preProgramme,
                                roles.postProgramme,
                                roles.admin,
                              ]}
                              component={ParticipantHub}
                            />
                            <PrivateRoute
                              exact
                              path="/survey"
                              allowedRoles={[roles.inProgramme]}
                              component={Survey}
                            />
                            <PrivateRoute
                              exact
                              path="/outOfProgram"
                              allowedRoles={[roles.disabled]}
                              component={OutOfProgramPage}
                            />
                            <Route path="/admin" component={AdminPage} />
                            <Route component={NotFoundPage} />
                          </Switch>
                        </Suspense>
                      </Container>
                    </UserContext.Provider>
                  </NotificationSnackbarContext.Provider>
                  <AppFooter />
                </ScrollToTop>
              </Router>
            </div>
          </ThemeProvider>
        </AppInsightsContext.Provider>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default App;
