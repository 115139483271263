import React from "react";
import { Grid } from "@material-ui/core";
import UploadFileComponent from "./UploadFileComponent";
import ClinicsSection from "./ClinicsSection";
import environment from "../../../utils/environment";
import endpoints from "../../../utils/endpoints";

const ClinicsAndReferralPage = () => {
  return (
    <>
      <Grid container spacing={10}>
        <ClinicsSection />
        <Grid item xs={12}>
          <UploadFileComponent
            title="Referral codes"
            uploadUrl={`${environment.enoBackendBasePath}${endpoints.uploadReferralCodes}`}
            inputId="referral"
            uploadButtonLabel="upload referral codes"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ClinicsAndReferralPage;
