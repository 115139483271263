import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AdminTable from "../../../components/admin/AdminTable";
import {
  getAllGroups,
  getAllGroupsHeaders,
} from "../../../services/groupsService";
import ColorButton from "../../../components/ColorButton";
import ErrorPanel from "../../../components/ErrorPanel";
import endpoints from "../../../utils/endpoints";
import useLoadDataFromAPI from "../../../utils/useLoadDataFromAPI";
import GroupsFilter from "./GroupsFilter";

const GroupsList = () => {
  const [groupsHeaders, setGroupsHeaders] = useState([]);
  const [updatedGroups, setGroups] = useState([]);
  const [allGroupsFilter, setAllGroupsFiler] = useState([]);
  const [forceReload, setForceReload] = useState(false);
  const [fetchUrl, setFetchUrl] = useState(endpoints.getGroups);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [totalNumber, setTotalNumber] = useState(0);
  const [numberRow, setNumberRow] = useState(5);
  const { data, loading, statusCode } = useLoadDataFromAPI({
    url: fetchUrl.includes("?")
      ? `${fetchUrl}&availableGroups&page=${page}&size=${numberRow}`
      : `${fetchUrl}?availableGroups&page=${page}&size=${numberRow}`,
    forceReload,
    setForceReload,
  });

  const history = useHistory();

  useEffect(() => {
    const headers = getAllGroupsHeaders();
    setGroupsHeaders(headers);
    fetchData();
  }, []);

  useEffect(() => {
    if (!data) return;

    if (data.nextPageId !== null) {
      setPage(data.nextPageId - 1);
    }
    setGroups(data.groupDTOS);
    setTotalNumber(data.totalNumberOfFilteredGroups);
  }, [data]);

  async function fetchData() {
    const grps = await getAllGroups();
    setAllGroupsFiler(grps.groupDTOS);
  }

  const handleActions = (row, pathname) => {
    const actions = [
      {
        text: "View",
        query: row,
        handleClick: () => history.push(`${pathname}/${row.id}`),
        enable: true,
      },
    ];
    return getActionsTemplate(actions);
  };
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newPage, nrRows) => {
    setPage(newPage);
    setNumberRow(nrRows);
  };

  const getActionsTemplate = (actions) => {
    return actions.map((action) => (
      <ColorButton
        key={action.text}
        size="small"
        query={action.query}
        disabled={!action.enable}
        onClick={action.handleClick}
      >
        {action.text}
      </ColorButton>
    ));
  };
  return (
    <>
      <GroupsFilter
        groups={allGroupsFilter || []}
        fetchUrl={fetchUrl}
        setFetchUrl={setFetchUrl}
        isFiltersOpen={isFiltersOpen}
        setIsFiltersOpen={setIsFiltersOpen}
        setPage={setPage}
      />
      <ColorButton
        onClick={() => history.push("/admin/groups/create")}
        size="small"
      >
        Create Group
      </ColorButton>
      <br />
      {statusCode >= 400 && (
        <ErrorPanel heading="Error loading data">
          The page could not be loaded. Please try again later ot contact the
          administrator
        </ErrorPanel>
      )}
      {loading && <>Loading...</>}
      {!loading && statusCode < 400 && (
        <AdminTable
          dataColumns={groupsHeaders}
          dataRows={updatedGroups}
          actionsList={handleActions}
          showPagination
          page={page}
          rowsPerPageProp={numberRow}
          totalNumberOfPages={totalNumber}
          onChangePage={handleChangePage}
          onChangeRows={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};

export default GroupsList;
