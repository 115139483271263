import React from "react";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  modal: {
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  children: {
    backgroundColor: theme.palette.background.paper,
    padding: "25px",
    borderRadius: "10px",
  },
}));
const CustomModal = ({ open, children }) => {
  const classes = useStyles();

  return (
    <div>
      <Modal
        disableBackdropClick
        className={classes.modal}
        open={open}
        closeAfterTransition
      >
        <Fade in={open}>
          <div className={classes.children}>{children}</div>
        </Fade>
      </Modal>
    </div>
  );
};
CustomModal.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
};
CustomModal.initProps = {
  children: null,
  open: false,
};
export default CustomModal;
