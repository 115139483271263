import React, { useEffect, useState } from "react";
import "date-fns";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { useParams } from "react-router-dom";
import statuses from "../forms/constants/userStatusesConstants";
import useLoadDataFromAPI from "../utils/useLoadDataFromAPI";
import { getStatusValueFromArrObject } from "../utils/parseUserStatus";
import endpoints from "../utils/endpoints";

const ChangeStatusSection = ({ label, key, value, handleChangeValue }) => {
  const { userId } = useParams();
  const [selectedStatus, setSelectedStatus] = useState("");

  const { data: availableStatuses, loading, statusCode } = useLoadDataFromAPI({
    url: `${endpoints.getUsers}/${userId}/statuses`,
    method: "GET",
  });

  useEffect(() => {
    setSelectedStatus(value);
  }, [value]);

  const onStatusChanged = (status) => {
    setSelectedStatus(status);
    handleChangeValue(status);
  };

  return (
    <>
      <TextField
        color="secondary"
        key={key}
        variant="outlined"
        label="Select the new status"
        name="selectedStatus"
        value={selectedStatus}
        onChange={(ev) => onStatusChanged(ev.target.value)}
        select
      >
        <MenuItem key={value} value={value}>
          {`${getStatusValueFromArrObject(value, statuses)}`}
        </MenuItem>
        {availableStatuses?.map((option) => (
          <MenuItem key={option} value={option}>
            {`${getStatusValueFromArrObject(option, statuses)}`}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};

ChangeStatusSection.propTypes = {
  label: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  value: PropTypes.instanceOf(Date),
  handleChangeValue: PropTypes.func.isRequired,
};

ChangeStatusSection.defaultProps = {
  value: null,
};

export default ChangeStatusSection;
