import { useKeycloak } from "@react-keycloak/web";
import React, { useContext, useState } from "react";
import { Box, IconButton, Menu, MenuItem, Typography } from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import { NavLink } from "react-router-dom";
import StyledNavLink from "./StyledNavLink";
import UserContext from "../context/UserContext";

const LoggedInHeaderContent = () => {
  const { keycloak } = useKeycloak();
  const [anchorEl, setAnchorEl] = useState(null);
  const { name, roles } = useContext(UserContext);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      ml={7}
      sx={{
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Typography variant="body1">{`Welcome ${name}!`}</Typography>
      <IconButton
        edge="end"
        aria-label="account of current user"
        // aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
        title="Your account"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        disableScrollLock
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {roles && !roles.includes("admin") && !roles.includes("disabled")
          ? [
              <MenuItem key="participant_hub" onClick={handleClose}>
                <StyledNavLink component={NavLink} to="/participanthub">
                  Participant Hub
                </StyledNavLink>
              </MenuItem>,
            ]
          : null}
        <MenuItem
          onClick={() =>
            keycloak.logout({ redirectUri: window.location.origin })
          }
        >
          <StyledNavLink component={NavLink} to="/" onClick={handleClose}>
            Logout
          </StyledNavLink>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default LoggedInHeaderContent;
