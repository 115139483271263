import React from "react";
import { Redirect } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

const Login = () => {
  const { keycloak } = useKeycloak();

  const userRoles = keycloak.tokenParsed?.realm_access.roles;
  if (keycloak.authenticated) {
    if (userRoles.includes("admin")) return <Redirect to="/admin" />;
    if (!userRoles.includes("admin") && !userRoles.includes("disabled"))
      return <Redirect to="/participanthub" />;

    if (userRoles.includes("disabled")) return <Redirect to="/outOfProgram" />;
  }
  keycloak.login();
  return null;
};

export default Login;
