import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Breadcrumbs, Typography, Box } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import SecondaryLink from "../components/SecondaryLink";
import computeSectionIcons from "../utils/computeSectionIcons";
import EditableSection from "../components/EditableSection";
import UserContext from "../context/UserContext";

const ContentPage = ({
  title,
  breadcrumbs,
  heroImage,
  navAnchors,
  editableContentLocations,
}) => {
  const [pageContent, setPageContent] = useState(
    '{ "blocks": [], "entityMap": {} }'
  );
  const [computedPageAnchors, setComputedPageAnchors] = useState([]);
  const { isAdmin } = useContext(UserContext);

  useEffect(() => {
    // TODO: replace with an async call to API endpoint
    setPageContent(editableContentLocations[0]);
  }, [editableContentLocations]);

  useEffect(() => {
    navAnchors &&
      setComputedPageAnchors(computeSectionIcons(pageContent, navAnchors));
  }, [navAnchors, pageContent]);

  return (
    <>
      {heroImage && (
        <Box mb={2} mt={1} clone>
          <img className="Page-hero" src={heroImage.url} alt={heroImage.alt} />
        </Box>
      )}

      {/* TODO: Integrate with React router? */}
      {breadcrumbs && (
        <Breadcrumbs separator="»" aria-label="breadcrumb">
          <SecondaryLink component={NavLink} color="inherit" to="/">
            ENO Breathe
          </SecondaryLink>
          <Typography variant="inherit">{title}</Typography>
        </Breadcrumbs>
      )}

      <Typography variant="h1" gutterBottom>
        {title}
      </Typography>

      {navAnchors && computedPageAnchors}

      <EditableSection
        isAdmin={isAdmin}
        editableContent={editableContentLocations[0]}
      />
    </>
  );
};

ContentPage.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.bool,
  heroImage: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }),
  navAnchors: PropTypes.oneOf(["header-two", "SUBTITLE", false]),
  editableContentLocations: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ContentPage.defaultProps = {
  breadcrumbs: true,
  heroImage: null,
  navAnchors: false,
};

export default ContentPage;
