import React, { useEffect, useState } from "react";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import { Chip, makeStyles } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import Typography from "@material-ui/core/Typography";
import { format } from "date-fns";
import TablePagination from "@material-ui/core/TablePagination";
import TooltipForTruncatedText from "../TruncatedTooltip";
import statuses from "../../forms/constants/userStatusesConstants";
import { getStatusValueFromArrObject } from "../../utils/parseUserStatus";
import getEthnicityValueFromArrString from "../../utils/parseUserEthnicity";
import getGenderValueFromArrString from "../../utils/parseUserGender";

const tableCellValue = (row, col) => {
  if (col.key === "dropInSessions") {
    return row.dropInSessions.map((c) => c.dropInSessionDate).join(", ");
  }

  if (col.key === "ethnicity") {
    return getEthnicityValueFromArrString(row[col.key]);
  }

  if (col.key === "gender") {
    return getGenderValueFromArrString(row[col.key]);
  }

  if (col.key === "numberOfParticipants") {
    return row.participants.length;
  }

  if (col.tableType === "boolean") {
    if (
      row[col.key] === true ||
      row[col.key] === "true" ||
      (row[col.key] && row[col.key].toLowerCase() === "yes")
    ) {
      if (col.key === "sessionBarriers") {
        return (
          <Tooltip title={row.barrierToParticipation || ""}>
            <CheckIcon style={{ fontSize: 18 }} />
          </Tooltip>
        );
      }

      return <CheckIcon style={{ fontSize: 18 }} />;
    }
    return "-";
  }

  let colorStatus = "black";
  if (col.key === "registrationStatus" || col.key === "status") {
    const newValue = getStatusValueFromArrObject(row.status, statuses);
    if (
      row[col.key] === "ON_HOLD_FURTHER_DISCUSSION_NEEDED" ||
      row[col.key] === "IN_PROGRAMME_TBC"
    ) {
      colorStatus = "red";
    }
    if (row[col.key] === "ASSIGN_GROUP_AND_OFFER_ONE_ON_ONE") {
      colorStatus = "#00D100";
    }
    return (
      <Chip
        variant="outlined"
        size="small"
        label={newValue}
        style={{ fontSize: 12, color: colorStatus }}
      />
    );
  }

  if (row[col.key]) {
    if (col.key === "dateCovidInfection" || col.key === "registrationDate") {
      const dateFormat = format(new Date(row[col.key]), "dd/MM/yyyy");
      return (
        <TooltipForTruncatedText title={dateFormat}>
          <Typography
            style={{ maxWidth: 150, fontSize: "inherit" }}
            display="block"
            noWrap
          >
            {dateFormat}
          </Typography>
        </TooltipForTruncatedText>
      );
    }
    return (
      <TooltipForTruncatedText title={row[col.key]}>
        <Typography
          style={{ maxWidth: 150, fontSize: "inherit" }}
          display="block"
          noWrap
        >
          {row[col.key]}
        </Typography>
      </TooltipForTruncatedText>
    );
  }
  return row[col.key] || "-";
};
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  actionsContainer: {
    display: "flex",
  },
});
const AdminTable = ({
  dataColumns,
  dataRows,
  page,
  rowsPerPageProp,
  showPagination,
  totalNumberOfPages,
  actionsList,
  onChangePage,
  onChangeRows,
}) => {
  const classes = useStyles();
  const [myDataColumns, setMyDataColumns] = useState([]);
  const [myDataRows, setMyDataRows] = useState([]);

  const location = useLocation();
  const { pathname } = location;

  const [currentPage, setCurrentPage] = React.useState(page);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const headersWithParents = dataColumns.filter((item) => !!item.parent);
  const renderedParents = {};

  useEffect(() => {
    setMyDataRows(dataRows);
    setMyDataColumns(dataColumns);
    setCurrentPage(page);
    setRowsPerPage(rowsPerPageProp);
  }, [dataRows, dataColumns, page, rowsPerPageProp]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    onChangePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const rowsPerPg = +event.target.value;
    setCurrentPage(0);
    setRowsPerPage(rowsPerPg);
    onChangeRows(0, rowsPerPg);
  };

  const markParentAsRendered = (parent) => {
    renderedParents[parent] = true;
    return headersWithParents.filter((i) => i.parent === parent).length;
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} stickyHeader aria-label="admin table">
          <TableHead>
            <TableRow>
              {actionsList && (
                <TableCell
                  key="actions"
                  rowSpan={headersWithParents.length > 0 ? 2 : 1}
                >
                  Actions
                </TableCell>
              )}
              {myDataColumns.map((col) =>
                !col.parent || (col.parent && !renderedParents[col.parent]) ? (
                  <TableCell
                    key={col.key}
                    rowSpan={
                      headersWithParents.length > 0 && !col.parent ? 2 : 1
                    }
                    colSpan={
                      headersWithParents.length > 0 && col.parent
                        ? markParentAsRendered(col.parent)
                        : 1
                    }
                    align={col.parent ? "center" : "left"}
                  >
                    {col.parent ? col.parent : col.rowTitle}
                  </TableCell>
                ) : null
              )}
            </TableRow>
            {headersWithParents.length > 0 && (
              <TableRow>
                {headersWithParents.map((col) => (
                  <TableCell key={col.key}>{col.rowTitle}</TableCell>
                ))}
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {myDataRows &&
              myDataRows.length > 0 &&
              myDataRows.map((row) => (
                <TableRow key={row.id}>
                  {actionsList && (
                    <TableCell>
                      <div>{actionsList(row, pathname)}</div>
                    </TableCell>
                  )}
                  {myDataColumns.map((col) => (
                    <TableCell
                      key={`${col.key}_${row.id}`}
                      className={row.classNames && row.classNames[col.key]}
                    >
                      {tableCellValue(row, col)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {showPagination && (
        <TablePagination
          rowsPerPageOptions={[
            5,
            8,
            100,
            { label: "All", value: totalNumberOfPages },
          ]}
          component="div"
          count={totalNumberOfPages}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};

AdminTable.propTypes = {
  dataColumns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dataRows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  page: PropTypes.number,
  rowsPerPageProp: PropTypes.number,
  showPagination: PropTypes.bool,
  totalNumberOfPages: PropTypes.number,
  actionsList: PropTypes.func,
  onChangePage: PropTypes.func,
  onChangeRows: PropTypes.func,
};
AdminTable.defaultProps = {
  page: 0,
  rowsPerPageProp: 5,
  showPagination: false,
  totalNumberOfPages: 0,
  actionsList: null,
  onChangePage: null,
  onChangeRows: null,
};

export default AdminTable;
