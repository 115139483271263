/**
 * @name environment
 * @desc Environment variables as declared in the .env files
 * @type {{enoBackendBasePath: string, keycloakBasePath: string, medium: string}}
 */
const environment = {
  medium: process.env.NODE_ENV,
  enoBackendBasePath: process.env.REACT_APP_ENO_BACKEND_BASE_PATH,
  keycloakBasePath: process.env.REACT_APP_KEYCLOAK_BASE_PATH,
};

export default environment;
