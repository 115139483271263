import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@material-ui/core/styles";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { format } from "date-fns";
import DatePicker from "./DatePicker";

const RemoveIcon = styled(HighlightOffIcon)({
  paddingRight: "10px",
  width: "35px",
  cursor: "pointer",
  color: "red",
});

const DropInSession = ({ data, onChangeSessions }) => {
  const [dropInDate, setDropInDate] = useState();
  const [dropInDates, setDropInDates] = useState(
    data.dropInSessions ? [...data.dropInSessions] : []
  );

  const handleDateChange = (datePicked) => {
    setDropInDate({
      dropInSessionDate: datePicked,
    });
    setDropInDates(
      dropInDates?.concat({
        dropInSessionDate: format(datePicked, "MM/dd/yyyy"),
      })
    );
  };
  const formatDate = (date) => {
    const tempDate = date.split("/");
    return `${tempDate[1]}/${tempDate[0]}/${tempDate[2]}`;
  };

  const handleRemoveDropInSession = (index) => {
    const newDropIns = [];
    dropInDates.map((dropDate, i) =>
      i !== index ? newDropIns.push(dropDate) : null
    );
    setDropInDates(newDropIns);
    setDropInDate(null);
  };

  useEffect(() => {
    onChangeSessions(dropInDates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropInDates]);

  return (
    <div style={{ marginBottom: "-17px" }}>
      <DatePicker
        label="Twilight sessions attended"
        name="dropInSession"
        value={dropInDate?.dropInSessionDate}
        onChange={(datePicked) => handleDateChange(datePicked)}
      />
      <ul style={{ marginTop: "0px" }}>
        {dropInDates?.map((res, index) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <RemoveIcon onClick={() => handleRemoveDropInSession(index)} />
            {formatDate(res.dropInSessionDate)}
          </div>
        ))}
      </ul>
    </div>
  );
};

DropInSession.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeSessions: PropTypes.func.isRequired,
};

export default DropInSession;
