const dbJson = [
  {
    id: "39193e7d-9e0b-46c1-a257-b29f14de90a4",
    registrationDate: "07/02/2021",
    referralCode: "SUF490TPEB",
    county: "Norfolk",
    region: "East of England",
    clinicName: "Norfolk Community Health and Care Trust",
    threeLettersCode: "SUF",
    firstname: "Samuel",
    lastname: "Umtiti",
    status: "REGISTRATION_TO_BE_CHECKED",
    sessionLeader: "Allan Parker",
    coordinator: "Kevin Peterson",
    assignedGroup: "Group1",
    additionalNotes: null,
    scoresId: 1,
    gAD7: 0,
    sF36PhysicalFunctioning: 20,
    sF36RoleLimitationsDueToPhysicalHealth: 20,
    sF36Pain: 20,
    sF36GeneralHealth: 20,
    sF36EnergyOrFatigue: 20,
    sF36SocialFunctioning: 20,
    sFRoleLimitationsDueToEmotionalProblems: 20,
    sF36EmotionalWellBeing: 20,
    breathlessnessAtRest: 10,
    breathlessnessDuringWalking: 22,
    breathlessnessDuringClimbingStairs: 76,
    breathlessnessDuringRunning: 83,
  },
  {
    id: "9063f9ba-688f-4bf4-a0ad-1769286de975",
    registrationDate: "07/02/2021",
    referralCode: "SUF471TPEB",
    county: "Norfolk",
    region: "East of England",
    clinicName: "Norfolk Community Health and Care Trust",
    threeLettersCode: "SUF",
    firstname: "aa",
    lastname: "AA",
    status: "REGISTRATION_TO_BE_CHECKED",
    sessionLeader: "Allan Parker",
    coordinator: "Kevin Peterson",
    assignedGroup: "Group1",
    additionalNotes: null,
    scoresId: 2,
    gAD7: 5,
    sF36PhysicalFunctioning: 60,
    sF36RoleLimitationsDueToPhysicalHealth: 70,
    sF36Pain: 40,
    sF36GeneralHealth: 50,
    sF36EnergyOrFatigue: 90,
    sF36SocialFunctioning: 40,
    sFRoleLimitationsDueToEmotionalProblems: 20,
    sF36EmotionalWellBeing: 20,
    breathlessnessAtRest: 10,
    breathlessnessDuringWalking: 22,
    breathlessnessDuringClimbingStairs: 76,
    breathlessnessDuringRunning: 83,
    notes1to1: "This is the notes that have been added",
  },
  {
    id: "92091995-5db3-455c-8852-105e85e42038",
    registrationDate: "07/02/2021",
    referralCode: "SUF472TPEB",
    county: "Norfolk",
    region: "East of England",
    clinicName: "Norfolk Community Health and Care Trust",
    threeLettersCode: "SUF",
    firstname: "bb",
    lastname: "BB",
    status: "REGISTRATION_TO_BE_CHECKED",
    sessionLeader: "Allan Parker",
    coordinator: "Kevin Peterson",
    assignedGroup: "Group1",
    additionalNotes: null,
    scoresId: 3,
    gAD7: 10,
    sF36PhysicalFunctioning: 66,
    sF36RoleLimitationsDueToPhysicalHealth: 20,
    sF36Pain: 20,
    sF36GeneralHealth: 99,
    sF36EnergyOrFatigue: 10,
    sF36SocialFunctioning: 55,
    sFRoleLimitationsDueToEmotionalProblems: 33,
    sF36EmotionalWellBeing: 20,
    breathlessnessAtRest: 10,
    breathlessnessDuringWalking: 22,
    breathlessnessDuringClimbingStairs: 76,
    breathlessnessDuringRunning: 83,
  },
  {
    id: "2ac85254-e751-4e75-9342-acff17890af3",
    registrationDate: "07/02/2021",
    referralCode: "SUF473TPEB",
    county: "Norfolk",
    region: "East of England",
    clinicName: "Norfolk Community Health and Care Trust",
    threeLettersCode: "SUF",
    firstname: "cc",
    lastname: "CC",
    status: "REGISTRATION_TO_BE_CHECKED",
    sessionLeader: "Allan Parker",
    coordinator: "Kevin Peterson",
    assignedGroup: "Group1",
    additionalNotes: null,
    scoresId: 4,
    gAD7: 15,
    sF36PhysicalFunctioning: 20,
    sF36RoleLimitationsDueToPhysicalHealth: 97,
    sF36Pain: 78,
    sF36GeneralHealth: 23,
    sF36EnergyOrFatigue: 3,
    sF36SocialFunctioning: 44,
    sFRoleLimitationsDueToEmotionalProblems: 11,
    sF36EmotionalWellBeing: 5,
    breathlessnessAtRest: 10,
    breathlessnessDuringWalking: 22,
    breathlessnessDuringClimbingStairs: 76,
    breathlessnessDuringRunning: 83,
    notes1to1: "This is the notes that have been added",
  },
];
const dataTableHeaders = [
  {
    rowTitle: "First Name",
    key: "firstname",
    tableType: "text",
  },
  {
    rowTitle: "Last Name",
    key: "lastname",
    tableType: "text",
  },
  {
    rowTitle: "At rest:",
    key: "AT_REST",
    tableType: "text",
    parent: "Breathlessness",
  },
  {
    rowTitle: "During activity - Walking around the house:",
    key: "DURING_ACTIVITY_WALKING_AROUND",
    tableType: "text",
    parent: "Breathlessness",
  },
  {
    rowTitle: "During activity - Climbing stairs:",
    key: "DURING_ACTIVITY_CLIMBING_STAIRS",
    tableType: "text",
    parent: "Breathlessness",
  },
  {
    rowTitle: "During activity - Running:",
    key: "DURING_ACTIVITY_RUNNING",
    tableType: "text",
    parent: "Breathlessness",
  },
  {
    rowTitle: "GAD 7",
    key: "gad7",
    tableType: "text",
  },
  {
    rowTitle: "Physical Functioning",
    key: "sf36PhysicalFunctioning",
    tableType: "text",
    parent: "RAND 36",
  },
  {
    rowTitle: "Role Limitations Due To Physical Health",
    key: "sf36RoleLimitationsDueToPhysicalHealth",
    tableType: "text",
    parent: "RAND 36",
  },

  {
    rowTitle: "Role Limitations Due To Emotional Problems",
    key: "sf36RoleLimitationsDueToEmotionalProblems",
    tableType: "text",
    parent: "RAND 36",
  },

  {
    rowTitle: "Pain",
    key: "sf36Pain",
    tableType: "text",
    parent: "RAND 36",
  },
  {
    rowTitle: "General Health",
    key: "sf36GeneralHealth",
    tableType: "text",
    parent: "RAND 36",
  },
  {
    rowTitle: "Energy Or Fatigue",
    key: "sf36EnergyOrFatigue",
    tableType: "text",
    parent: "RAND 36",
  },
  {
    rowTitle: "Social Functioning",
    key: "sf36SocialFunctioning",
    tableType: "text",
    parent: "RAND 36",
  },
  {
    rowTitle: "Emotional Well Being",
    key: "sf36EmotionalWellBeing",
    tableType: "text",
    parent: "RAND 36",
  },
  {
    rowTitle: "Notes from 1:1",
    key: "notesFromOneOnOne",
    tableType: "text",
  },
];

const getAllSurveys = () => {
  return dbJson;
};

const getAllSurveysHeaders = () => {
  return dataTableHeaders;
};

const getGroup = async (id) => {
  const groupInfo = dbJson.find((e) => e.id === id);
  return groupInfo;
};

export { getAllSurveys, getGroup, getAllSurveysHeaders };
