import React, { useContext } from "react";
import { Button } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import PropTypes from "prop-types";
import { saveAs } from "file-saver";
import NotificationSnackbarContext from "../context/NotificationSnackbarContext";
import { SnackbarTypes } from "./CustomSnackbar";
import environment from "../utils/environment";

const DownloadButton = ({ downloadUrl, fileName }) => {
  const { notification, setNotification } = useContext(
    NotificationSnackbarContext
  );
  const downloadFile = () => {
    return fetch(`${environment.enoBackendBasePath}${downloadUrl}`, {
      method: "GET",
      headers: {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        Authorization: `Bearer ${sessionStorage.getItem("auth.accessToken")}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => saveAs(blob, fileName))
      .catch(() => {
        setNotification({
          ...notification,
          message:
            "There was an error. Please try again later or contact the administrator",
          color: SnackbarTypes.ERROR,
          key: new Date(),
        });
      });
  };
  return (
    <>
      <Button color="primary" onClick={downloadFile}>
        <GetAppIcon color="primary" />
        Download Excel
      </Button>
    </>
  );
};

DownloadButton.propTypes = {
  downloadUrl: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
};

export default DownloadButton;
