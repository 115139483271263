import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import EditableSection from "../components/EditableSection";
import UserContext from "../context/UserContext";

const Home = ({ title, heroImage, editableContentLocations }) => {
  const { isAdmin } = useContext(UserContext);
  return (
    <>
      <Box mb={2} mt={1} clone>
        <img className="Page-hero" src={heroImage.url} alt={heroImage.alt} />
      </Box>

      <Typography variant="h1" gutterBottom>
        {title}
      </Typography>

      <EditableSection
        isAdmin={isAdmin}
        editableContent={editableContentLocations[0]}
      />
    </>
  );
};

Home.propTypes = {
  title: PropTypes.string.isRequired,
  heroImage: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }),
  editableContentLocations: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Home.defaultProps = {
  heroImage: null,
};

export default Home;
