import { Button, withStyles } from "@material-ui/core";

// white to black button
const ColorButton = withStyles((enoBreatheTheme) => ({
  root: {
    color: enoBreatheTheme.palette.primary.main,
    backgroundColor: enoBreatheTheme.palette.common.white,
    "&:hover": {
      backgroundColor: enoBreatheTheme.palette.primary.dark,
      color: enoBreatheTheme.palette.common.white,
    },
  },
}))(Button);

export default ColorButton;
