import React from "react";
import PropTypes from "prop-types";
import {
  FormControlLabel,
  TextField,
  Grid,
  Button,
  Paper,
  MenuItem,
  Box,
  makeStyles,
  Drawer,
  Chip,
  IconButton,
  RadioGroup,
  FormLabel,
  Radio,
  FormControl,
} from "@material-ui/core";
import { format } from "date-fns";
import FilterListIcon from "@material-ui/icons/FilterList";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import DatePicker from "../DatePicker";
import SecondaryButton from "../SecondaryButton";
import NeutralButton from "../NeutralButton";
import DownloadButton from "../DownloadButton";
import statuses from "../../forms/constants/userStatusesConstants";
import { getStatusValueFromArrObject } from "../../utils/parseUserStatus";
import getEthnicityValueFromArrString from "../../utils/parseUserEthnicity";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    ".MuiGrid-spacing-xs-5 > .MuiGrid-item&": {
      padding: "0 20px",
      display: "flex",
      alignItems: "center",
    },
    // ".MuiGrid-spacing-xs-5 > .MuiGrid-item& .MuiFormControlLabel-label": {
    //   backgroundColor: "pink",
    //   fontSize: 12,
    // },
    ".MuiGrid-spacing-xs-5 > .MuiGrid-item& .MuiOutlinedInput-notchedOutline": {
      borderWidth: 0,
      borderBottomWidth: 1,
      borderRadius: 0,
    },
    ".MuiGrid-spacing-xs-5 > .MuiGrid-item& .MuiOutlinedInput-input": {
      padding: 14,
    },
  },
  drawerContents: {
    maxWidth: 750,
    margin: 20,
    padding: 20,
  },
  activeFiltersChip: {
    margin: "0 5px",
  },
  filterButton: {
    marginRight: 10,
  },
  filterContents: {
    paddingBottom: 20,
  },
  drawerTitleBar: {
    padding: 20,
    backgroundColor: "#fff",
    position: "sticky",
    zIndex: 999,
    alignItems: "center",
    top: 0,
    height: 70,
    display: "flex",
    "& div": {
      display: "inline-block",
      flex: 1,
      fontSize: 24,
      fontWeight: "bold",
    },
  },
  drawerButtonsContainer: {
    backgroundColor: "#fff",
    position: "sticky",
    zIndex: 999,
    alignItems: "center",
    justifyContent: "center",
    bottom: 0,
    display: "flex",
  },
  closeDrawerBtn: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

const getValueLabel = (type, key, value) => {
  if (type === "boolean") {
    if (value === true || value === "true") {
      return "Yes";
    }
    return "No";
  }
  if (type === "date") {
    return format(value, "dd/MM/yyyy");
  }
  if (type === "intRange") {
    return `${value.min || ""} - ${value.max || ""}`;
  }
  if (type === "dateRange") {
    return `${
      value.min && value.min.getTime() === value.min.getTime()
        ? format(value.min, "dd/MM/yyyy")
        : ""
    } - ${
      value.max && value.max.getTime() === value.max.getTime()
        ? format(value.max, "dd/MM/yyyy")
        : ""
    }`;
  }
  if (type === "text") {
    if (key.toLowerCase() === "ethnicity") {
      return getEthnicityValueFromArrString(value);
    }
  }
  return value;
};

const getValue = (key, value) => {
  if (key === "status") {
    return getStatusValueFromArrObject(value, statuses);
  }
  if (key === "ethnicity") {
    return getEthnicityValueFromArrString(value);
  }
  return value;
};

const TableFilters = ({
  filtersData,
  handleFilterChange,
  handleFilterReset,
  handleDeleteFilter,
  isFiltersOpen,
  setIsFiltersOpen,
  resultsLength,
  downloadUrl,
  downloadedFileName,
  setPage,
}) => {
  const classes = useStyles();

  const debounce = (func, wait) => {
    let timeout;

    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const filtersArray = filtersData?.map((filter) => {
    switch (filter.tableType) {
      case "text": {
        return (
          <Grid container spacing={2}>
            <Grid item sm={12} key={filter?.key} className={classes.gridItem}>
              <TextField
                select
                variant="outlined"
                label={filter?.rowTitle}
                id={filter?.key}
                name={filter?.key}
                value={filter?.value || ""}
                onChange={(e) => handleFilterChange(filter.key, e.target.value)}
                // helperText="Select a value"
              >
                {filter?.values?.map((value) => (
                  <MenuItem key={`option-${filter.key}_${value}`} value={value}>
                    {getValue(filter.key, value)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        );
      }
      case "textfield": {
        return (
          <Grid container spacing={2}>
            <Grid item sm={12} key={filter?.key} className={classes.gridItem}>
              <TextField
                variant="outlined"
                label={filter?.rowTitle}
                name={filter?.key}
                onChange={debounce(
                  (e) => handleFilterChange(filter.key, e.target.value),
                  500
                )}
                // helperText="Select a value"
              />
            </Grid>
          </Grid>
        );
      }
      case "intRange": {
        return (
          <Grid container spacing={2}>
            <Grid
              item
              sm={12}
              key={`${filter.key}-title`}
              className={classes.gridItem}
            >
              {filter.rowTitle} (in range)
              <Grid container spacing={2}>
                <Grid
                  item
                  sm={6}
                  key={`${filter.key}-min`}
                  className={classes.gridItem}
                >
                  <TextField
                    label="From"
                    variant="outlined"
                    id={filter.key}
                    name={filter.key}
                    value={filter.value?.min || ""}
                    onChange={(e) =>
                      handleFilterChange(filter.key, {
                        ...filter.value,
                        min: e.target.value,
                      })
                    }
                    type="number"
                    InputProps={{
                      // shrink: true,
                      inputProps: {
                        min: 18,
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                  key={`${filter.key}-max`}
                  className={classes.gridItem}
                >
                  <TextField
                    label="To"
                    variant="outlined"
                    id={filter.key}
                    name={filter.key}
                    value={filter.value?.max || ""}
                    onChange={(e) =>
                      handleFilterChange(filter.key, {
                        ...filter.value,
                        max: e.target.value,
                      })
                    }
                    type="number"
                    InputProps={{
                      // shrink: true,
                      inputProps: {
                        min: 18,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      }
      case "boolean": {
        return (
          <Grid container spacing={2}>
            <Grid item sm={12} key={filter.key} className={classes.gridItem}>
              <FormControl component="fieldset">
                <RadioGroup
                  name={filter.key}
                  value={filter.value || false}
                  onChange={(e, checked) => {
                    handleFilterChange(filter.key, checked);
                  }}
                  color="secondary"
                >
                  <Grid item sm={12}>
                    <FormLabel component="label" color="secondary">
                      {filter.rowTitle}
                    </FormLabel>
                  </Grid>
                  <FormControlLabel
                    value="true"
                    control={<Radio color="secondary" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio color="secondary" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        );
      }
      case "dateRange": {
        return (
          <Grid container spacing={2}>
            <Grid
              item
              sm={12}
              key={`${filter.key}-title`}
              className={classes.gridItem}
            >
              {filter.rowTitle} (in range)
              <Grid container spacing={2}>
                <Grid
                  item
                  sm={6}
                  key={`${filter.key}-min`}
                  className={classes.gridItem}
                >
                  <DatePicker
                    label="From"
                    onChange={(value) =>
                      handleFilterChange(filter.key, {
                        ...filter.value,
                        min: value,
                      })
                    }
                    name={`${filter.key}-min`}
                    value={
                      filter.value && filter.value.min
                        ? new Date(filter.value.min)
                        : null
                    }
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                  key={`${filter.key}-max`}
                  className={classes.gridItem}
                >
                  <DatePicker
                    label="To"
                    onChange={(value) =>
                      handleFilterChange(filter.key, {
                        ...filter.value,
                        max: value,
                      })
                    }
                    name={`${filter.key}-max`}
                    value={
                      filter.value && filter.value.max
                        ? new Date(filter.value.max)
                        : null
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      }
      case "date": {
        return (
          <Grid container spacing={2}>
            <Grid item sm={12} key={filter.key} className={classes.gridItem}>
              <DatePicker
                label={filter.rowTitle}
                onChange={(value) => handleFilterChange(filter.key, value)}
                name={filter.rowTitle}
                value={filter.value ? new Date(filter.value) : null}
              />
            </Grid>
          </Grid>
        );
      }
      default:
        return null;
    }
  });

  const handleFiltersButtonClick = () => {
    setIsFiltersOpen(!isFiltersOpen);
  };
  const handleFilterExit = () => {
    setPage(0);
  };

  return (
    <>
      <Button
        color="primary"
        onClick={() => {
          handleFilterExit();
          handleFiltersButtonClick();
        }}
        className={classes.filterButton}
      >
        <FilterListIcon color="primary" />
        Filter
      </Button>
      {downloadUrl && (
        <DownloadButton
          downloadUrl={downloadUrl}
          fileName={downloadedFileName}
        />
      )}
      <div
        className={
          filtersData?.filter((item) => item.active).length > 0
            ? classes.filterContents
            : null
        }
      >
        {filtersData
          .filter((item) => item.active)
          .map((item) => {
            return (
              <Chip
                key={`active-filter-${item.key}`}
                className={classes.activeFiltersChip}
                label={`${item.rowTitle}: ${getValueLabel(
                  item.tableType,
                  item.rowTitle,
                  item.value
                )}`}
                onDelete={() => handleDeleteFilter(item)}
                variant="outlined"
              />
            );
          })}
      </div>
      <Drawer
        anchor="left"
        open={isFiltersOpen}
        onClose={handleFiltersButtonClick}
      >
        <div className={classes.drawerTitleBar}>
          <div>Filters</div>
          <IconButton
            color="primary"
            onClick={handleFiltersButtonClick}
            className={classes.closeDrawerBtn}
          >
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Paper elevation={3} className={classes.drawerContents}>
          <Grid container>{filtersArray}</Grid>
        </Paper>
        <div className={classes.drawerButtonsContainer}>
          <Box mr={1} display="inline">
            <SecondaryButton
              variant="outlined"
              size="small"
              onClick={() => {
                handleFilterReset();
                handleFiltersButtonClick();
              }}
            >
              Reset
            </SecondaryButton>
          </Box>
          <Box display="inline">
            <NeutralButton
              variant="outlined"
              size="small"
              onClick={() => {
                handleFilterExit();
                handleFiltersButtonClick();
              }}
            >
              Filter
            </NeutralButton>
          </Box>
        </div>
        <Box mr={50} />
        <div />
      </Drawer>
    </>
  );
};

TableFilters.propTypes = {
  filtersData: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  handleDeleteFilter: PropTypes.func.isRequired,
  handleFilterReset: PropTypes.func.isRequired,
  isFiltersOpen: PropTypes.bool.isRequired,
  setIsFiltersOpen: PropTypes.func.isRequired,
  resultsLength: PropTypes.number.isRequired,
  downloadUrl: PropTypes.string,
  downloadedFileName: PropTypes.string,
  setPage: PropTypes.func.isRequired,
};

TableFilters.defaultProps = {
  downloadUrl: null,
  downloadedFileName: null,
};
export default TableFilters;
