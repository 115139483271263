import "date-fns";
import React from "react";
import PropTypes from "prop-types";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const DatePicker = ({
  label,
  name,
  onBlur,
  value,
  onChange,
  helperText,
  error,
  format,
  disableFuture,
  disabled,
  maxDate,
  minDate,
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        label={label}
        name={name}
        maxDate={maxDate}
        minDate={minDate}
        disableFuture={disableFuture}
        placeholder={format}
        variant="inline"
        color="secondary"
        inputVariant="outlined"
        format={format}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        helperText={helperText}
        error={error}
        disabled={disabled}
        autoOk
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        InputLabelProps={{
          shrink: value ? true : undefined,
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

DatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  format: PropTypes.string,
  disableFuture: PropTypes.bool,
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
};

DatePicker.defaultProps = {
  value: null,
  onBlur: null,
  helperText: null,
  error: false,
  disabled: false,
  format: "dd/MM/yyyy",
  disableFuture: false,
  maxDate: new Date("2100/01/01"),
  minDate: new Date("1900/01/01"),
};

export default DatePicker;
