import React from "react";
import { Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import SecondaryButton from "../../../components/SecondaryButton";
import NeutralButton from "../../../components/NeutralButton";
import useLoadDataFromAPI from "../../../utils/useLoadDataFromAPI";
import endpoints from "../../../utils/endpoints";
import api from "../../../utils/api";
import ErrorPanel from "../../../components/ErrorPanel";
import { SnackbarTypes } from "../../../components/CustomSnackbar";

const useStyles = makeStyles(() => ({
  container: {
    width: "350px",
  },
}));

const AssignGroupModal = ({ participant, handleCloseModal }) => {
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      selectedGroupId: "",
    },
    validationSchema: Yup.object({
      selectedGroupId: Yup.string().required("This field is required"),
    }),
    onSubmit: (values) => {
      handleAssignGroup(values.selectedGroupId);
    },
  });
  const { data: availableGroups, loading, statusCode } = useLoadDataFromAPI({
    url: endpoints.getGroups,
    queryParams: { availableGroups: true, page: 0 },
    method: "GET",
  });

  const handleAssignGroup = (selectedGroupId) => {
    if (selectedGroupId) {
      api
        .patchApi(
          `${endpoints.assignUserToGroup}${participant.id}`,
          null,
          JSON.stringify({ id: selectedGroupId })
        )
        .then((data) => {
          if (data.status >= 400) {
            handleCloseModal(
              SnackbarTypes.ERROR,
              "Sorry, it looks like the server is taking too long to respond. " +
                "This can be caused by either poor connectivity or an error with our servers. " +
                "Please try again in a while."
            );
          } else {
            handleCloseModal(
              SnackbarTypes.SUCCESS,
              "Participant has been successfully assigned"
            );
          }
        })
        .catch(() => {
          handleCloseModal(
            SnackbarTypes.ERROR,
            "Sorry, it looks like the server is taking too long to respond. " +
              "This can be caused by either poor connectivity or an error with our servers. " +
              "Please try again in a while."
          );
          handleCloseModal();
        });
    }
  };

  if (loading) {
    return <>Loading ... </>;
  }
  if (statusCode >= 400) {
    return (
      <>
        <ErrorPanel heading="Error loading data">
          The page could not be loaded. Please try again later ot contact the
          administrator
        </ErrorPanel>
        <Box display="inline">
          <NeutralButton
            variant="outlined"
            size="small"
            onClick={() => handleCloseModal()}
          >
            Cancel
          </NeutralButton>
        </Box>
      </>
    );
  }

  return (
    <>
      <div className={classes.container}>
        <Typography variant="h2" gutterBottom>
          Assign to Group
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {`${participant.firstname} ${participant.lastname}`}
        </Typography>
        <Grid item sm={12}>
          <TextField
            color="secondary"
            variant="outlined"
            label="Select a group"
            name="selectedGroupId"
            value={formik.values.selectedGroupId}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={
              formik.touched.selectedGroupId
                ? formik.errors.selectedGroupId
                : ""
            }
            error={
              formik.touched.selectedGroupId &&
              Boolean(formik.errors.selectedGroupId)
            }
            select
          >
            {availableGroups ? (
              availableGroups.groupDTOS.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {`${option.groupName} | ${option.sessionLeader} | ${option.coordinator}`}
                </MenuItem>
              ))
            ) : (
              <MenuItem>No available groups</MenuItem>
            )}
          </TextField>
        </Grid>
        <Box mr={1} display="inline">
          <SecondaryButton
            variant="outlined"
            size="small"
            disabled={formik.isSubmitting}
            onClick={() => formik.handleSubmit()}
          >
            Assign
          </SecondaryButton>
        </Box>
        <Box display="inline">
          <NeutralButton
            variant="outlined"
            size="small"
            onClick={() => handleCloseModal()}
          >
            Cancel
          </NeutralButton>
        </Box>
      </div>
    </>
  );
};
AssignGroupModal.propTypes = {
  participant: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  handleCloseModal: PropTypes.func,
};
AssignGroupModal.defaultProps = {
  handleCloseModal: null,
};
export default AssignGroupModal;
