import { Button, withStyles } from "@material-ui/core";

// white to black button
const ButtonNewsletter = withStyles((enoBreatheTheme) => ({
  root: {
    color: enoBreatheTheme.palette.primary.neutral,
    borderColor: enoBreatheTheme.palette.primary.neutral,
    borderRadius: 0,
    textTransform: "none",
    maxWidth: "100px",
    maxHeight: "48px",
    minHeight: "48px",
    fontSize: "80%",
    "&:hover": {
      backgroundColor: enoBreatheTheme.palette.primary.neutral,
      color: enoBreatheTheme.palette.primary.main,
    },
  },
}))(Button);

export default ButtonNewsletter;
