const endpoints = {
  getUsersProfiles: "/users-profiles",
  postAdminGroups: "/groups",
  getGroups: "/groups",
  getsessionLeaders: "/groups/sessionLeaders",
  getAssignedGroups: "/groups/assignedGroups",
  getcoordinatorsName: "/groups/coordinatorsName",
  getAssignedStatuses: "/statuses/assignedStatuses",
  getClinics: "/clinics",
  getClinicsDashboard: "/users/dashboard/region",
  getUsersDashboard: "/users/dashboard/status",
  getGroupsDashboard: "/groups/dashboard",
  getAssignedClinics: "/clinics/assignedClinics",
  uploadClinics: "/clinics/import",
  uploadReferralCodes: "/referral-codes/import",
  getSurvey: "/surveys",
  postAnswers: "/answers",
  getUsers: "/users",
  assignUserToGroup: "/users/",
  getGroupsSurveys: "/users/groups/{groupId}/scores/{surveyNumber}",
  getDownloadedGroupSurveys: "/users/groups/{groupId}/surveys/{surveyNumber}",
  getUserSurvey: "/users/{userId}/survey-score/{surveyId}",
  getMembersEmailAddresses: "/groups",
};

export default endpoints;
