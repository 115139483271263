const USER_FILTERS_KEY = "userFilters";

// eslint-disable-next-line import/prefer-default-export
export class SessionController {
  #sessionStorage;

  constructor(sessionStorage) {
    this.#sessionStorage = sessionStorage;
  }

  setUserFilters(userFilters) {
    this.#sessionStorage.setItem(USER_FILTERS_KEY, JSON.stringify(userFilters));
  }

  getUserFilters() {
    const usersFiltersString = this.#sessionStorage.getItem(USER_FILTERS_KEY);

    if (!usersFiltersString) {
      return [];
    }

    try {
      return JSON.parse(usersFiltersString);
    } catch (e) {
      return [];
    }
  }
}
