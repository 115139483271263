import { colors } from "@material-ui/core";

const labelColors = Object.keys(colors)
  .map((key) => colors[key][600])
  .slice(1);

export const chartLegend = (data) => {
  return data.map((elem, idx) => ({ ...elem, color: labelColors[idx] }));
};

export const computeChartDataSet = (data) => {
  const chartValues = data.map((elem) => elem.percentage);
  return {
    datasets: [
      {
        data: chartValues,
        backgroundColor: labelColors.slice(0, chartValues.length),
      },
    ],
  };
};
