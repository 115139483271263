import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";

const TooltipForTruncatedText = ({ children, title }) => {
  const [opened, setOpened] = React.useState(false);
  const onMouseEnter = (e) => {
    setOpened(e.target.scrollWidth > e.target.clientWidth);
  };
  const onMouseLeave = () => {
    setOpened(false);
  };

  return (
    <>
      <Tooltip
        open={opened}
        onMouseEnter={onMouseEnter}
        onMouseOut={onMouseLeave}
        disableHoverListener
        title={title}
      >
        {children}
      </Tooltip>
    </>
  );
};

TooltipForTruncatedText.propTypes = {
  children: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
};

export default TooltipForTruncatedText;
