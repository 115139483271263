import React, { useState } from "react";
import PropTypes from "prop-types";
import { Alert } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";

export const SnackbarTypes = {
  ERROR: "error",
  SUCCESS: "success",
  WARNING: "warning",
};
const CustomSnackbar = (props) => {
  const { message, autoHideDuration, vertical, horizontal, color } = props;
  const [open, setOpen] = useState(true);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return (
    <Snackbar
      open={open}
      anchorOrigin={{
        vertical,
        horizontal,
      }}
      onClose={handleClose}
      autoHideDuration={autoHideDuration}
    >
      <Alert severity={color}>{message}</Alert>
    </Snackbar>
  );
};

CustomSnackbar.propTypes = {
  message: PropTypes.string.isRequired,
  color: PropTypes.oneOf(["info", "success", "warning", "error"]).isRequired,
  vertical: PropTypes.oneOf(["top", "bottom"]),
  horizontal: PropTypes.oneOf(["right", "left", "center"]),
  autoHideDuration: PropTypes.number,
};
CustomSnackbar.defaultProps = {
  vertical: "top",
  horizontal: "right",
  autoHideDuration: 3000,
};
export default CustomSnackbar;
