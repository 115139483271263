import { Box, Link, List, ListItem } from "@material-ui/core";
import React from "react";
import scrollToElement from "./scrollToElement";

/**
 * @name computeSectionIcons
 * @desc Generates a list of in-page anchors to specified sections in the page.
 * @param {string} rawPageContent Stringified Draft.js RawContentState object
 * @param {string} anchorHooks The type that the anchors will link to. Must be a "type" property of the blocks in rawPageContent.
 * @returns {JSX.Element} A list of links
 */
const computeSectionIcons = (rawPageContent, anchorHooks) => {
  let headings = [];

  const generateHeadings = (content) => {
    return (
      JSON.parse(content).blocks?.filter(
        (block) => block.type === anchorHooks
      ) || []
    );
  };

  if (rawPageContent instanceof Array) {
    rawPageContent.forEach((content) => {
      headings = headings.concat(generateHeadings(content));
    });
  } else {
    headings = generateHeadings(rawPageContent);
  }

  const anchors = headings.map((heading) => (
    <ListItem disableGutters key={heading.key}>
      <Link
        // href={`#${heading.key}`}
        onClick={() => scrollToElement(heading.key)}
      >
        {heading.text}
      </Link>
    </ListItem>
  ));

  return (
    <Box mb={3}>
      <List aria-label="In-page navigation">{anchors}</List>
    </Box>
  );
};

export default computeSectionIcons;
