import axios from "axios";
import environment from "./environment";

const fetchAPI = (url, method, headers, body, queryParams) => {
  const baseUrl = `${environment.enoBackendBasePath}${url}`;
  const fetchUrl = queryParams
    ? `${baseUrl}?${new URLSearchParams(queryParams)}`
    : baseUrl;

  return axios({
    baseURL: fetchUrl,
    method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Ocp-Apim-Subscription-Key": "5d0bead443864a23b5eb134554de3971",
      Authorization: `Bearer ${sessionStorage.getItem("auth.accessToken")}`,
      ...headers,
    },
    data: body,
  });
};

const getAPI = (url, headers = null, queryParams) => {
  return fetchAPI(url, "GET", headers, null, queryParams);
};

const postAPI = (url, headers, body) => {
  return fetchAPI(url, "POST", headers, body);
};

const putAPI = (url, headers, body) => {
  return fetchAPI(url, "PUT", headers, body);
};

const patchApi = (url, headers, body) => {
  return fetchAPI(url, "PATCH", headers, body);
};

const api = {
  fetchAPI,
  getAPI,
  postAPI,
  putAPI,
  patchApi,
};
export default api;
