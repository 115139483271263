import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import endpoints from "../../../utils/endpoints";
import { getGroupsFilters } from "../../../services/groupsService";
import TableFilters from "../../../components/admin/TableFilters";
import useLoadDataFromAPI from "../../../utils/useLoadDataFromAPI";
import CustomSnackbar, {
  SnackbarTypes,
} from "../../../components/CustomSnackbar";

const GroupsFilter = ({
  fetchUrl,
  setFetchUrl,
  isFiltersOpen,
  setIsFiltersOpen,
  groups,
  setPage,
}) => {
  const [filtersData, setFiltersData] = useState([]);
  const [forceReload, setForceReload] = useState(false);

  const { data: sessionLeaders } = useLoadDataFromAPI({
    url: endpoints.getsessionLeaders,
    forceReload,
    setForceReload,
  });

  const { data: coordinatorsName } = useLoadDataFromAPI({
    url: endpoints.getcoordinatorsName,
    forceReload,
    setForceReload,
  });

  useEffect(() => {
    if (!sessionLeaders && !coordinatorsName) return;
    const updateFiltersAvailableData = async () => {
      const activeFilters = filtersData?.filter((item) => item.active);
      if (groups && Array.isArray(groups) && activeFilters.length === 0) {
        const disableRefreshDataForFilters = [
          "sessionBarriers",
          "age",
          "gender",
          "dateCovidInfection",
          "admittedToHospital",
          "sessionAvailability",
          "englishFirstLanguage",
          "twilightSession",
        ];
        const updatedFiltersData = filtersData?.map((filter) => {
          if (disableRefreshDataForFilters.indexOf(filter.key) > -1) {
            return filter;
          }
          if (filter.key === "sessionLeaders") {
            return { ...filter, values: sessionLeaders };
          }
          if (filter.key === "coordinatorsName") {
            return { ...filter, values: coordinatorsName };
          }
          return {
            ...filter,
            values: [
              ...new Set(
                groups?.map((user) => user[filter.key]).filter((item) => !!item)
              ),
            ],
          };
        });
        setFiltersData(updatedFiltersData);
      }
    };

    updateFiltersAvailableData();
  }, [groups, sessionLeaders, coordinatorsName]);

  useEffect(() => {
    const loadInitialFilters = async () => {
      const initialFilterNameValue = groups.map((item) => item.groupName);
      const initialValues = {
        groupName: initialFilterNameValue,
      };
      const filters = await getGroupsFilters(initialValues);
      setFiltersData(filters);
    };

    loadInitialFilters();
  }, []);

  useEffect(() => {
    // update fetch url based on filters
    const activeFilters = filtersData?.filter((item) => item.active);

    let queryParams = activeFilters
      .map((item) => {
        switch (item.key) {
          case "sessionAvailability":
            switch (item.value) {
              case "morning":
                return "userProfile.morningSessionAvailability==true";
              case "lunch time":
                return "userProfile.lunchtimeSessionAvailability==true";
              case "afternoon":
                return "userProfile.afternoonSessionAvailability==true";
              default:
                // "evening"
                return "userProfile.eveningSessionAvailability==true";
            }
          default:
            if (item.tableType === "date") {
              return `${item.queryParamKey}=="${format(
                item.value,
                "yyyy-MM-dd"
              )}"`;
            }
            return `${item.queryParamKey}=="${item.value}"`;
        }
      })
      .join(";");
    if (queryParams) {
      queryParams = `?search=${queryParams}`;
    }
    setFetchUrl(`${endpoints.getGroups}${queryParams}`);
  }, [filtersData]);

  const handleFilterChange = (key, value) => {
    const newFilterData = filtersData?.map((item) => {
      if (item.key === key) {
        return { ...item, active: true, value };
      }
      return item;
    });
    setFiltersData(newFilterData);
  };
  const handleDeleteFilter = (item) => {
    const newFilterData = filtersData?.map((filter) => {
      if (item.key === filter.key) {
        return { ...filter, active: false, value: null };
      }
      return filter;
    });
    setFiltersData(newFilterData);
  };
  const resetFilters = () => {
    setFiltersData(
      filtersData?.map((item) => ({ ...item, active: false, value: null }))
    );
  };

  return (
    <>
      {groups?.length > 1000 && fetchUrl !== endpoints.getGroups && groups && (
        <CustomSnackbar
          color={SnackbarTypes.WARNING}
          message="There are more than 1000 results currently displayed. Please refine your search, in order to download."
          autoHideDuration={3000}
        />
      )}
      <TableFilters
        filtersData={filtersData}
        handleFilterChange={handleFilterChange}
        handleFilterReset={resetFilters}
        handleDeleteFilter={handleDeleteFilter}
        isFiltersOpen={isFiltersOpen}
        setIsFiltersOpen={setIsFiltersOpen}
        resultsLength={groups?.length || 0}
        setPage={setPage}
      />
    </>
  );
};
GroupsFilter.propTypes = {
  fetchUrl: PropTypes.string.isRequired,
  setFetchUrl: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFiltersOpen: PropTypes.bool.isRequired,
  setIsFiltersOpen: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
};

export default GroupsFilter;
