import React, { useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { saveAs } from "file-saver";
import { getAllSurveysHeaders } from "../../../services/surveysService";
import endpoints from "../../../utils/endpoints";
import AdminTable from "../../../components/admin/AdminTable";
import ColorButton from "../../../components/ColorButton";
import NeutralButton from "../../../components/NeutralButton";
import environment from "../../../utils/environment";
import NotificationSnackbarContext from "../../../context/NotificationSnackbarContext";
import { SnackbarTypes } from "../../../components/CustomSnackbar";

const useStyles = makeStyles({
  orangeText: {
    color: "orange",
  },
  redText: {
    color: "red",
  },
  downloadButton: {
    marginLeft: 10,
  },
  dropdownSurveys: {
    zIndex: 10,
  },
});

const GroupSurvey = ({ surveyValues, id, name }) => {
  const classes = useStyles();
  const history = useHistory();
  const { groupId } = useParams();
  const { notification, setNotification } = useContext(
    NotificationSnackbarContext
  );
  const [surveysHeaders, setSurveysHeaders] = useState(getAllSurveysHeaders());
  const [disableDownload, setDisableDownload] = useState(false);

  const getScore = (score) => {
    return score || score === 0 ? score : "-";
  };

  const addClassNamesToData = (data) => {
    if (!data) {
      return data;
    }
    return data.map((row) => {
      let classNameGad7 = null;
      if (row.scores?.gad7 >= 10) {
        if (row.scores?.gad7 < 15) {
          classNameGad7 = classes.orangeText;
        } else {
          classNameGad7 = classes.redText;
        }
      }

      let classNameSF36EnergyOrFatigue = null;
      if (row.scores?.sf36EnergyOrFatigue <= 10) {
        classNameSF36EnergyOrFatigue = classes.redText;
      }

      return {
        ...row,
        AT_REST: `${getScore(row.surveyAnswersByCategory?.AT_REST)}`,
        DURING_ACTIVITY_CLIMBING_STAIRS: `${getScore(
          row.surveyAnswersByCategory?.DURING_ACTIVITY_CLIMBING_STAIRS
        )}`,
        DURING_ACTIVITY_RUNNING: `${getScore(
          row.surveyAnswersByCategory?.DURING_ACTIVITY_RUNNING
        )}`,
        DURING_ACTIVITY_WALKING_AROUND: `${getScore(
          row.surveyAnswersByCategory?.DURING_ACTIVITY_WALKING_AROUND
        )}`,
        gad7: `${getScore(row.scores?.gad7)}/21`,
        sf36EnergyOrFatigue: `${getScore(row.scores?.sf36EnergyOrFatigue)}`,
        sf36SocialFunctioning: `${getScore(row.scores?.sf36SocialFunctioning)}`,
        sf36EmotionalWellBeing: `${getScore(
          row.scores?.sf36EmotionalWellBeing
        )}`,
        sf36GeneralHealth: `${getScore(row.scores?.sf36GeneralHealth)}`,
        sf36Pain: `${getScore(row.scores?.sf36Pain)}`,
        sf36PhysicalFunctioning: `${getScore(
          row.scores?.sf36PhysicalFunctioning
        )}`,
        sf36RoleLimitationsDueToPhysicalHealth: `${getScore(
          row.scores?.sf36RoleLimitationsDueToPhysicalHealth
        )}`,
        sf36RoleLimitationsDueToEmotionalProblems: `${getScore(
          row.scores?.sf36RoleLimitationsDueToEmotionalProblems
        )}`,
        notesFromOneOnOne: row.notesFromOneOnOne ? row.notesFromOneOnOne : "-",
        classNames: {
          gad7: classNameGad7,
          sf36EnergyOrFatigue: classNameSF36EnergyOrFatigue,
        },
      };
    });
  };

  const handleActions = (row) => {
    return (
      row.scores?.survey.id && (
        <ColorButton
          key={`viewSurvey${row.id}`}
          size="small"
          query={row}
          onClick={() =>
            history.push(
              `/admin/user/${row.id}/surveys/${row.scores?.survey.id}`
            )
          }
          style={{ fontSize: "inherit" }}
        >
          View
        </ColorButton>
      )
    );
  };

  const downloadSurvey = () => {
    setDisableDownload(true);
    const url = endpoints.getDownloadedGroupSurveys
      .replace("{groupId}", groupId)
      .replace("{surveyNumber}", id);
    const fileName = `survey${id}_group ${name}`;

    fetch(`${environment.enoBackendBasePath}${url}`, {
      method: "GET",
      headers: {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        Authorization: `Bearer ${sessionStorage.getItem("auth.accessToken")}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        setDisableDownload(false);
        saveAs(blob, fileName);
      })
      .catch(() => {
        setDisableDownload(false);
        setNotification({
          ...notification,
          message:
            "There was an error. Please try again later or contact the administrator",
          color: SnackbarTypes.ERROR,
          key: new Date(),
        });
      });
  };

  return (
    <>
      <NeutralButton
        variant="outlined"
        size="small"
        className={classes.downloadButton}
        onClick={downloadSurvey}
        disabled={disableDownload}
      >
        Download Survey
      </NeutralButton>
      <AdminTable
        dataColumns={surveysHeaders}
        dataRows={addClassNamesToData(surveyValues) || []}
        actionsList={handleActions}
      />
    </>
  );
};

GroupSurvey.propTypes = {
  surveyValues: PropTypes.oneOfType([PropTypes.object]).isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default GroupSurvey;
