import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import endpoints from "../../../utils/endpoints";
import { getUsersFilters } from "../../../services/usersService";
import TableFilters from "../../../components/admin/TableFilters";
import useLoadDataFromAPI from "../../../utils/useLoadDataFromAPI";
import CustomSnackbar, {
  SnackbarTypes,
} from "../../../components/CustomSnackbar";
import { SessionController } from "../../../utils/session";
import { useFilterUtils } from "./hooks";

const UsersFilter = ({
  fetchUrl,
  setFetchUrl,
  isFiltersOpen,
  setIsFiltersOpen,
  users,
  setPage,
  sessionController,
}) => {
  const [filtersData, setFiltersData] = useState([]);
  const [forceReload, setForceReload] = useState(false);
  const { filterData2QueryParams } = useFilterUtils();

  const { data: assignedGroups } = useLoadDataFromAPI({
    url: endpoints.getAssignedGroups,
    forceReload,
    setForceReload,
  });

  const { data: assignedStatuses } = useLoadDataFromAPI({
    url: endpoints.getAssignedStatuses,
    forceReload,
    setForceReload,
  });

  const { data: assignedClinics } = useLoadDataFromAPI({
    url: endpoints.getAssignedClinics,
    forceReload,
    setForceReload,
  });

  useEffect(() => {
    if (!assignedClinics && !assignedGroups && !assignedStatuses) return;
    const updateFiltersAvailableData = async () => {
      const activeFilters = filtersData?.filter((item) => item.active);
      if (users && Array.isArray(users) && activeFilters.length === 0) {
        const disableRefreshDataForFilters = [
          "sessionBarriers",
          "age",
          "gender",
          "dateCovidInfection",
          "admittedToHospital",
          "sessionAvailability",
          "englishFirstLanguage",
          "twilightSession",
        ];
        const updatedFiltersData = filtersData?.map((filter) => {
          if (disableRefreshDataForFilters.indexOf(filter.key) > -1) {
            return filter;
          }
          if (filter.key === "assignedGroup") {
            return { ...filter, values: assignedGroups };
          }
          if (filter.key === "clinicName") {
            return { ...filter, values: assignedClinics };
          }
          if (filter.key === "status") {
            return { ...filter, values: assignedStatuses };
          }
          return {
            ...filter,
            values: [
              ...new Set(
                users?.map((user) => user[filter.key]).filter((item) => !!item)
              ),
            ],
          };
        });
        setFiltersData(updatedFiltersData);
      }
    };

    updateFiltersAvailableData();
  }, [users, assignedGroups, assignedStatuses, assignedClinics]);

  useEffect(() => {
    const loadInitialFilters = async () => {
      const sessionFilters = sessionController.getUserFilters();
      const filters = sessionFilters.length
        ? sessionFilters
        : await getUsersFilters();
      setFiltersData(filters);
      setFetchUrl(`${endpoints.getUsers}${filterData2QueryParams(filters)}`);
    };

    loadInitialFilters();
  }, []);

  const handleFilterChange = (key, value) => {
    const newFilterData = filtersData?.map((item) => {
      if (item.key === key) {
        return { ...item, active: true, value };
      }
      return item;
    });
    setFiltersData(newFilterData);
    setFetchUrl(
      `${endpoints.getUsers}${filterData2QueryParams(newFilterData)}`
    );
    sessionController.setUserFilters(newFilterData);
  };
  const handleDeleteFilter = (item) => {
    const newFilterData = filtersData?.map((filter) => {
      if (item.key === filter.key) {
        return { ...filter, active: false, value: null };
      }
      return filter;
    });
    setFiltersData(newFilterData);
    setFetchUrl(
      `${endpoints.getUsers}${filterData2QueryParams(newFilterData)}`
    );
    sessionController.setUserFilters(newFilterData);
  };
  const resetFilters = () => {
    const newFilterData = filtersData?.map((item) => ({
      ...item,
      active: false,
      value: null,
    }));
    setFiltersData(newFilterData);
    setFetchUrl(
      `${endpoints.getUsers}${filterData2QueryParams(newFilterData)}`
    );
    sessionController.setUserFilters(newFilterData);
  };

  return (
    <>
      {users?.length > 1000 && fetchUrl !== endpoints.getUsers && users && (
        <CustomSnackbar
          color={SnackbarTypes.WARNING}
          message="There are more than 1000 results currently displayed. Please refine your search, in order to download."
          autoHideDuration={3000}
        />
      )}
      <TableFilters
        filtersData={filtersData}
        handleFilterChange={handleFilterChange}
        handleFilterReset={resetFilters}
        handleDeleteFilter={handleDeleteFilter}
        isFiltersOpen={isFiltersOpen}
        setIsFiltersOpen={setIsFiltersOpen}
        resultsLength={users?.length || 0}
        setPage={setPage}
        downloadUrl={
          fetchUrl !== endpoints.getUsers && users?.length <= 1000
            ? fetchUrl
            : null
        }
        downloadedFileName={`${format(
          new Date(),
          "dd-MM-yy"
        )}-Participant-List.xlsx`}
      />
    </>
  );
};

UsersFilter.propTypes = {
  fetchUrl: PropTypes.string.isRequired,
  setFetchUrl: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFiltersOpen: PropTypes.bool.isRequired,
  setIsFiltersOpen: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  sessionController: SessionController,
};

UsersFilter.defaultProps = {
  sessionController: new SessionController(sessionStorage),
};

export default UsersFilter;
