import api from "../utils/api";
import endpoints from "../utils/endpoints";
import environment from "../utils/environment";

const dataTableHeaders = [
  {
    rowTitle: "Group Number",
    key: "groupName",
    tableType: "text",
  },
  {
    rowTitle: "Session Leader",
    key: "sessionLeader",
    tableType: "text",
  },
  {
    rowTitle: "Group Coordinator",
    key: "coordinator",
    tableType: "text",
  },
  {
    rowTitle: "Zoom session link",
    key: "sessionLink",
    tableType: "text",
  },
  {
    rowTitle: "Session 1",
    key: "week1DateTime",
    tableType: "date",
  },
  {
    rowTitle: "Session 2",
    key: "week2DateTime",
    tableType: "date",
  },
  {
    rowTitle: "Session 3",
    key: "week3DateTime",
    tableType: "date",
  },
  {
    rowTitle: "Session 4",
    key: "week4DateTime",
    tableType: "date",
  },
  {
    rowTitle: "Session 5",
    key: "week5DateTime",
    tableType: "date",
  },
  {
    rowTitle: "Session 6",
    key: "week6DateTime",
    tableType: "date",
  },
  {
    rowTitle: "Total number of participants in the Group",
    key: "numberOfParticipants",
    tableType: "text",
  },
];

const dataLabels = [
  {
    label: "Group Number",
    key: "groupName",
    type: "text",
    availableAnswers: [],
    editable: true,
    required: true,
  },
  {
    label: "Session Leader",
    key: "sessionLeader",
    type: "text",
    availableAnswers: [],
    editable: true,
    required: true,
  },
  {
    label: "Group Coordinator",
    key: "coordinator",
    type: "text",
    availableAnswers: [],
    editable: true,
    required: false,
  },
  {
    label: "Zoom session link",
    key: "sessionLink",
    type: "text",
    availableAnswers: [],
    editable: true,
    required: true,
  },
  {
    label: "Session 1",
    key: "week1DateTime",
    type: "dateAndTime",
    availableAnswers: [],
    editable: false,
    required: true,
  },
  {
    label: "Session 2",
    key: "week2DateTime",
    type: "dateAndTime",
    availableAnswers: [],
    editable: false,
    required: true,
  },
  {
    label: "Session 3",
    key: "week3DateTime",
    type: "dateAndTime",
    availableAnswers: [],
    editable: false,
    required: true,
  },
  {
    label: "Session 4",
    key: "week4DateTime",
    type: "dateAndTime",
    availableAnswers: [],
    editable: false,
    required: true,
  },
  {
    label: "Session 5",
    key: "week5DateTime",
    type: "dateAndTime",
    availableAnswers: [],
    editable: false,
    required: true,
  },
  {
    label: "Session 6",
    key: "week6DateTime",
    type: "dateAndTime",
    availableAnswers: [],
    editable: false,
    required: true,
  },
  {
    label: "Total number of participants in the Group",
    key: "participants",
    type: "count",
    availableAnswers: [],
    editable: false,
    required: true,
  },
  {
    label: "Notes",
    key: "notes",
    type: "textarea",
    availableAnswers: [],
    editable: true,
    required: true,
  },
];

const dataLabelsCreateAdminGroup = [
  {
    label: "Group Number",
    key: "groupName",
    type: "text",
    availableAnswers: [],
    editable: true,
    required: true,
    parent: "createGroup",
  },
  {
    label: "Session Leader",
    key: "sessionLeader",
    type: "text",
    availableAnswers: [],
    editable: true,
    required: true,
    parent: "createGroup",
  },
  {
    label: "Group Coordinator",
    key: "coordinator",
    type: "text",
    availableAnswers: [],
    editable: true,
    required: false,
    parent: "createGroup",
  },
  {
    label: "Session 1",
    key: "week1DateTime",
    type: "dateAndTime",
    availableAnswers: [],
    editable: true,
    required: true,
    parent: "createGroup",
  },
  {
    label: "Session 2",
    key: "week2DateTime",
    type: "dateAndTime",
    availableAnswers: [],
    editable: true,
    required: true,
    parent: "createGroup",
  },
  {
    label: "Session 3",
    key: "week3DateTime",
    type: "dateAndTime",
    availableAnswers: [],
    editable: true,
    required: true,
    parent: "createGroup",
  },
  {
    label: "Session 4",
    key: "week4DateTime",
    type: "dateAndTime",
    availableAnswers: [],
    editable: true,
    required: true,
    parent: "createGroup",
  },
  {
    label: "Session 5",
    key: "week5DateTime",
    type: "dateAndTime",
    availableAnswers: [],
    editable: true,
    required: true,
    parent: "createGroup",
  },
  {
    label: "Session 6",
    key: "week6DateTime",
    type: "dateAndTime",
    availableAnswers: [],
    editable: true,
    required: true,
    parent: "createGroup",
  },
  {
    label: "Zoom session link",
    key: "sessionLink",
    type: "text",
    availableAnswers: [],
    editable: true,
    required: true,
    parent: "createGroup",
  },
];
const participantHeaders = [
  {
    rowTitle: "First Name",
    key: "firstName",
    tableType: "text",
  },
  {
    rowTitle: "Last Name",
    key: "lastName",
    tableType: "text",
  },
  {
    rowTitle: "Status",
    key: "status",
    tableType: "text",
  },
  {
    rowTitle: "Email address",
    key: "emailAdress",
    tableType: "text",
  },
  {
    rowTitle: "Phone number",
    key: "mobilePhoneNumber",
    tableType: "text",
  },
  {
    rowTitle: "Survey scores",
    key: "surveyScores",
    tableType: "text",
  },
  {
    rowTitle: "Session 1",
    key: "sessionOne",
    tableType: "text",
  },
  {
    rowTitle: "Session 2",
    key: "sessionTwo",
    tableType: "text",
  },
  {
    rowTitle: "Session 3",
    key: "sessionThree",
    tableType: "text",
  },
  {
    rowTitle: "Session 4",
    key: "sessionFour",
    tableType: "text",
  },
  {
    rowTitle: "Session 5",
    key: "sessionFive",
    tableType: "text",
  },
  {
    rowTitle: "Session 6",
    key: "sessionSix",
    tableType: "text",
  },
  {
    rowTitle: "Drop in sessions",
    key: "dropInSessions",
    tableType: "text",
  },
];
// const test123 = async () => {
//   const requestOptions = {
//     method: "POST",
//     body: JSON.stringify(requestOptions),
//     headers: {
//       Authorization:
//         "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJhbFpNel90Tmkxc3FOTjMzdV8yVGxRMmxkYWhjb19yTlhXalBvdE8ycUFFIn0.eyJleHAiOjE2MjIxMTkxMTcsImlhdCI6MTYyMjExODgxNywiYXV0aF90aW1lIjoxNjIyMTE4ODE1LCJqdGkiOiJlOWE1M2IyZS1hODZmLTQ3YzItYmQ1Ni0xOWUzZjQxNTc0YTEiLCJpc3MiOiJodHRwczovL2Rldi1hcHBzZXIta2V5Y2xvYWstMDAxLmF6dXJld2Vic2l0ZXMubmV0L2F1dGgvcmVhbG1zL0VubyIsImF1ZCI6ImFjY291bnQiLCJzdWIiOiI1NTMzODlhNC1hYTA0LTQ1NjMtYmExMy01ZjY1Zjg1ODcwN2EiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJSZWFjdC1hcHAiLCJub25jZSI6IjRiNTc0ZTAwLWRlZjctNDkxOC1hNjZhLTA1YjIzMjU3NjlkZiIsInNlc3Npb25fc3RhdGUiOiIwMTQ5NTUzMS0wNDM0LTRjYjctYjBlOS04YWZjNDE2ODY3MzgiLCJhY3IiOiIxIiwiYWxsb3dlZC1vcmlnaW5zIjpbIioiXSwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbIm9mZmxpbmVfYWNjZXNzIiwiYWRtaW4iLCJ1bWFfYXV0aG9yaXphdGlvbiJdfSwicmVzb3VyY2VfYWNjZXNzIjp7ImFjY291bnQiOnsicm9sZXMiOlsibWFuYWdlLWFjY291bnQiLCJtYW5hZ2UtYWNjb3VudC1saW5rcyIsInZpZXctcHJvZmlsZSJdfX0sInNjb3BlIjoib3BlbmlkIGVtYWlsIHByb2ZpbGUiLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsIm5hbWUiOiJCZW4gQWZmbGVjayIsInByZWZlcnJlZF91c2VybmFtZSI6ImJlbi5hZmZsZWNrIiwiZ2l2ZW5fbmFtZSI6IkJlbiIsImZhbWlseV9uYW1lIjoiQWZmbGVjayIsImVtYWlsIjoiYmVuLmFmZmxlY2tAbWVtby5jb20ifQ.UmMCkMst-m6MqOC7ZnkL00bog6W-M4KMUGr4raMOgMItyw6jGIxvtOKmAhlwYYQXs7YVB2Yc662s2OjqwjTxNicGWC4dA5Ju5I4CyB8K1N7951fkMu65SwnU12pldN3tN9hqdLnEr_3F65fPMolouwv9INsUmK3EMhqZ60eQzcRw1V7bRyXDPhIKF_aBWTDzavfqzQkC4ddmy4M8PjF636s-ZW_GiXjUFC_S8NxkCQnQavSpI2PTC0Sp8DaC-Cipr7f10NbgCPMUrVmhrhzENmlPKWbAMHQrzKI2DaFSe1XX6qTBI2jdqGlk4rsIs1GlAtjaavgVonFqxjlU-vGXTg",
//     },
//   };
//   fetch("https://jsonplaceholder.typicode.com/posts", requestOptions)
//     .then((response) => response.json())
//     .then((data) => {
//       console.log(data.json());
//     })
//     .catch((error) => {
//       console.log(error);
//     });
//   return dbJson;
// };

const groupsFilters = [
  {
    rowTitle: "Group Number",
    key: "groupName",
    tableType: "text",
    values: ["aaaa", "Group 2", "Group 3"],
    active: false,
    value: null,
    queryParamKey: "name",
  },
  {
    rowTitle: "Session leader",
    key: "sessionLeader",
    tableType: "text",
    values: ["In programme", "Post-programme"],
    active: false,
    value: null,
    queryParamKey: "sessionLeader",
  },
  {
    rowTitle: "Group coordinator",
    key: "coordinator",
    tableType: "text",
    values: ["John", "Post-programme"],
    active: false,
    value: null,
    queryParamKey: "coordinator",
  },
];

const getAllGroups = async () => {
  return api
    .getAPI(endpoints.getGroups, null, { availableGroups: false, page: 0 })
    .then((response) => {
      return response.data;
    });
};

const getAllGroupsDashboard = async () => {
  return api.getAPI(endpoints.getGroupsDashboard).then((response) => {
    return response.data;
  });
};

const getAllGroupsHeaders = () => {
  return dataTableHeaders;
};

const getGroup = async (id) => {
  const headers = {
    Authorization: `Bearer ${sessionStorage.getItem("auth.accessToken")}`,
  };
  return fetch(`${environment.enoBackendBasePath}/groups/${id}`, {
    headers,
  })
    .then((response) => {
      return response.json();
    })
    .then((res) => res);
};

const getGroupByName = (groups, name) => {
  if (groups.find((group) => group.groupName === name)) return true;
  return false;
};

const getGroupFormHeaders = () => {
  return dataLabels;
};

const getAllGroupParticipantsHeaders = () => {
  return participantHeaders;
};

const getNewGroupFormHeaders = () => {
  return dataLabelsCreateAdminGroup;
};

const getGroupsFilters = (initialValues) => {
  return groupsFilters.map((item) => ({
    ...item,
    values: initialValues[item.key], // || item.values,
  }));
};

export {
  getAllGroups,
  getGroup,
  getGroupByName,
  getAllGroupsHeaders,
  getGroupFormHeaders,
  getAllGroupParticipantsHeaders,
  getNewGroupFormHeaders,
  getGroupsFilters,
  getAllGroupsDashboard,
};
