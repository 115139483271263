import api from "../utils/api";
import endpoints from "../utils/endpoints";

const dataHeaders = [
  {
    rowTitle: "Full name",
    key: "clinicName",
    tableType: "text",
    inputType: "text",
    availableAnswers: [],
    disabled: false,
  },
  {
    rowTitle: "Short name",
    key: "threeLettersCode",
    tableType: "text",
    inputType: "text",
    availableAnswers: [],
    disabled: false,
  },
  {
    rowTitle: "County",
    key: "county",
    tableType: "text",
    inputType: "text",
    availableAnswers: [],
    disabled: false,
  },
  {
    rowTitle: "Region name",
    key: "region",
    tableType: "text",
    inputType: "text",
    availableAnswers: [],
    disabled: false,
  },
];

const getAllClinics = async () => {
  return api
    .getAPI(endpoints.getClinics)
    .then((response) => {
      return response.data;
    })
    .then((res) => res);
};

const getAllClinicsDashboard = async () => {
  return api
    .getAPI(endpoints.getClinicsDashboard)
    .then((response) => {
      return response.data;
    })
    .then((res) => res);
};

const getAllClinicsHeaders = () => {
  return dataHeaders;
};

const getClinic = async (id) => {
  const clincs = getAllClinics();
  const ClinicsInfo = clincs.find((e) => e.id === id);
  return ClinicsInfo;
};

export {
  getAllClinics,
  getClinic,
  getAllClinicsHeaders,
  getAllClinicsDashboard,
};
