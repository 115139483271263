import React from "react";
import { NavLink } from "react-router-dom";
import { Box } from "@material-ui/core";
import StyledNavLink from "../StyledNavLink";

const Header = () => {
  return (
    <Box pb={5}>
      <StyledNavLink
        component={NavLink}
        activeClassName="App-nav--item__active"
        to="/admin/dashboard"
      >
        Dashboard
      </StyledNavLink>
      <StyledNavLink
        component={NavLink}
        activeClassName="App-nav--item__active"
        to="/admin/users"
      >
        Users
      </StyledNavLink>
      <StyledNavLink
        component={NavLink}
        to="/admin/groups"
        activeClassName="App-nav--item__active"
      >
        Groups
      </StyledNavLink>
      <StyledNavLink
        component={NavLink}
        to="/admin/clinicsAndReferral"
        activeClassName="App-nav--item__active"
      >
        Clinics and Referral Codes
      </StyledNavLink>
    </Box>
  );
};

export default Header;
