const statuses = [
  {
    value: "WAITING",
    label: "Waiting",
  },
  {
    value: "REGISTRATION_TO_BE_COMPLETED",
    label: "Registration to be completed",
  },
  {
    value: "REGISTRATION_TO_BE_CHECKED",
    label: "Registration to be checked",
  },
  {
    value: "ON_HOLD_FURTHER_DISCUSSION_NEEDED",
    label: "On hold further discussion needed",
  },
  {
    value: "SURVEY_ONE_TO_BE_COMPLETED",
    label: "Survey one to be completed",
  },
  {
    value: "ASSIGN_GROUP_AND_OFFER_ONE_ON_ONE",
    label: "Assign group and offer one on one",
  },
  {
    value: "ONE_ON_ONE_PENDING",
    label: "One on one pending",
  },
  {
    value: "ONE_ON_ONE_CONFIRMED",
    label: "One on one confirmed",
  },
  {
    value: "IN_PROGRAMME_TBC",
    label: "In programme TBC",
  },
  {
    value: "IN_PROGRAMME",
    label: "In programme",
  },
  {
    value: "SURVEY_TWO_TO_BE_COMPLETED",
    label: "Survey two to be completed",
  },
  {
    value: "POST_PROGRAMME",
    label: "Post programme",
  },
  {
    value: "POST_PROGRAMME_NO_TWILIGHT",
    label: "Post programme no twilight",
  },
  {
    value: "REJECTED_REFERRAL_AND_MEDICAL_CRITERIA_NOT_MET",
    label: "Rejected referral and medical criteria not met",
  },
  {
    value: "REJECTED_RECOVERY_DATE_TOO_SOON",
    label: "Rejected recovery date too soon",
  },
  {
    value: "REJECTED_OTHER",
    label: "Rejected other",
  },
  {
    value: "LEFT_NOT_FEELING_WELL_ENOUGH",
    label: "Left not feeling well enough",
  },
  {
    value: "LEFT_FEELING_BETTER",
    label: "Left feeling better",
  },
  {
    value: "LEFT_NOT_FOR_THEM",
    label: "Left not for them",
  },
  {
    value: "LEFT_DUE_TO_TECHNICAL_ASPECTS",
    label: "Left due to technical aspects",
  },
  {
    value: "LEFT_OTHER",
    label: "Left other",
  },
  {
    value: "POST_PROGRAMME_SURVEY_THREE_TO_BE_COMPLETED",
    label: "Post programme survey three to be completed",
  },
  {
    value: "POST_PROGRAMME_SURVEY_THREE_COMPLETED",
    label: "Post programme survey three completed",
  },
  {
    value: "POST_PROGRAMME_NO_TWILIGHT_SURVEY_THREE_TO_BE_COMPLETED",
    label: "Post programme no twilight survey three to be completed",
  },
  {
    value: "POST_PROGRAMME_NO_TWILIGHT_SURVEY_THREE_COMPLETED",
    label: "Post programme no twilight survey three completed",
  },
];

export default statuses;
