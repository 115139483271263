import { Link, styled } from "@material-ui/core";

const StyledNavLink = styled(Link)({
  color: "#000",
  marginInlineEnd: 20,
  textDecoration: "none",
  display: "inline-block",

  "&:hover": {
    textDecoration: "underline",
  },
});

export default StyledNavLink;
