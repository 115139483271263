import React, { useEffect, useState, useContext } from "react";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, createMuiTheme } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  DateTimePicker,
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { format } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import NeutralButton from "../NeutralButton";
import SecondaryButton from "../SecondaryButton";
import DatePicker from "../DatePicker";
import NotificationSnackbarContext from "../../context/NotificationSnackbarContext";
import { SnackbarTypes } from "../CustomSnackbar";
import { getAllGroups, getGroupByName } from "../../services/groupsService";
import DropInSession from "../DropInSession";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
  },
  createGroupNote: {
    fontSize: "smaller",
    fontStyle: "italic",
  },
  invalidDateMsg: {
    color: "red",
    fontSize: "smaller",
    fontStyle: "italic",
    customTemplate: {
      display: "flex",
    },
  },
}));

export const customTheme = createMuiTheme({
  "&.MuiPickersModal-dialogRootWider": {
    minWidth: 400,
  },
  ".MuiFormControl-root": {
    display: "flex",
  },
});

const EditDetailsForm = ({
  headers,
  data,
  handleSave,
  forCreate = false,
  forEdit = false,
  handleGetCustomTemplates,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [modalStyle] = useState(getModalStyle);

  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editedData, setEditedData] = useState(null);
  const [invalidInputKeys, setInvalidInputKeys] = useState([]);
  const [saveError, setSaveError] = useState(false);
  const { notification, setNotification } = useContext(
    NotificationSnackbarContext
  );
  const [disableYesButtonSubmit, setDisableYesButtonSubmit] = useState(false);
  const [disableSaveButtonSubmit, setDisableSaveButtonSubmit] = useState(false);

  useEffect(() => {
    setEditedData(data);
    setLoading(false);
  }, [data]);

  const validateInput = (date) => !!(new Date(date) > new Date());

  const validateInputText = (text) => {
    const regex = /^[a-z0-9 ]+$/i;
    const itIsName = regex.test(text);
    return itIsName;
  };

  const validateLink = (text) => {
    const regex = /(ftp|https?):\/\/[^ "]+$/;
    const itIsURL = regex.test(text);
    return itIsURL;
  };

  const findParentAndUpdateValue = (stateData, value, key, parent) => {
    const newData = { ...stateData };
    if (forCreate) {
      newData[key] = value;
    } else if (parent) {
      if (parent in stateData && stateData[parent]) {
        newData[parent][key] = value;
      } else {
        newData[parent] = {};
        newData[parent][key] = value;
      }
    } else {
      newData[key] = value;
    }

    return newData;
  };

  const findParentAndGetValue = (key, parent) => {
    if (parent)
      if (
        parent in editedData &&
        editedData[parent] !== null &&
        key in editedData[parent]
      ) {
        return editedData[parent][key];
      } else return null;

    return editedData[key];
  };

  const formValidation = (parent, name, value, newData) => {
    switch (parent) {
      case "createGroup":
        if (name === "groupName") setEditedData(newData);
        else if (name === "sessionLink" && validateLink(value))
          setEditedData(newData);
        else if (validateInputText(value)) setEditedData(newData);
        break;
      default:
        setEditedData(newData);
    }
  };

  const formInvalidInputs = (parent, name, value) => {
    switch (parent) {
      case "createGroup":
        if (name === "sessionLeader" || name === "coordinator") {
          if (forCreate && validateInputText(value)) {
            const updatedInvalidDates = invalidInputKeys?.filter(
              (inputKey) => inputKey !== name
            );
            setInvalidInputKeys(updatedInvalidDates);
          } else if (forCreate && !validateInputText(value)) {
            if (!invalidInputKeys || !invalidInputKeys?.includes(name)) {
              invalidInputKeys.push(name);
              setInvalidInputKeys([...invalidInputKeys]);
            }
          }
        }
        if (name === "sessionLink") {
          if (forCreate && validateLink(value)) {
            const updatedInvalidDates = invalidInputKeys?.filter(
              (inputKey) => inputKey !== name
            );
            setInvalidInputKeys(updatedInvalidDates);
          } else if (forCreate && !validateLink(value)) {
            if (!invalidInputKeys || !invalidInputKeys?.includes(name)) {
              invalidInputKeys.push(name);
              setInvalidInputKeys([...invalidInputKeys]);
            }
          }
        }
        break;
      default:
        setInvalidInputKeys([...invalidInputKeys]);
    }
    if (forEdit) {
      if (name === "notes") {
        if (forEdit && value.length < 2000) {
          const updatedInvalidDates = invalidInputKeys?.filter(
            (inputKey) => inputKey !== name
          );
          setInvalidInputKeys(updatedInvalidDates);
        } else if (forEdit && value.length > 2000) {
          if (!invalidInputKeys || !invalidInputKeys?.includes(name)) {
            invalidInputKeys.push(name);
            setInvalidInputKeys([...invalidInputKeys]);
          }
        }
      }
    }
  };

  const handleInputChange = (e, parent) => {
    const newUpdatedData = findParentAndUpdateValue(
      editedData,
      e.target.value,
      e.target.name,
      parent
    );

    formValidation(parent, e.target.name, e.target.value, newUpdatedData);
    formInvalidInputs(parent, e.target.name, e.target.value);
  };

  const allInputFieldsCompleted = () => {
    let response = true;
    if (forCreate) {
      const inputFields = headers.filter(
        (header) =>
          (header.type === "text" ||
            header.type === "date" ||
            header.type === "dateAndTime") &&
          header.key !== "week2DateTime" &&
          header.key !== "week3DateTime" &&
          header.key !== "week4DateTime" &&
          header.key !== "week5DateTime" &&
          header.key !== "week6DateTime"
      );
      response = inputFields.some((element) => editedData[element.key] === "");
    }
    return !response;
  };

  const getFutureSessionsDates = (date, n) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + n * 7);
    return newDate;
  };

  const getDateTimeFieldValue = (parent, key) => {
    if (parent === "createGroup") {
      return editedData[key] ? Date.parse(editedData[key]) : null;
    }
    return Date.parse(findParentAndGetValue(key, parent));
  };

  const getAllDatesKeys = () => {
    const datesArr = headers.filter(
      (header) => header.type === "date" || header.type === "dateAndTime"
    );
    const resultKeys = datesArr.map((date) => date.key);
    return resultKeys;
  };

  const formatDate = (date) => {
    if (typeof date === "object") {
      return `${new Date(date).getDay()}/${new Date(
        date
      ).getMonth()}/${new Date(date).getFullYear()}`;
    }
    const tempDate = date.split("/");
    return `${tempDate[1]}/${tempDate[0]}/${tempDate[2]}`;
  };

  const setAllSessions = (initialDate) => {
    const formatPattern = "dd/MM/yyyy HH:mm";
    const keysForDates = getAllDatesKeys();
    let counter = 0;
    const sessionDates = {};
    keysForDates.map((currentDate) => {
      const finalDate = getFutureSessionsDates(initialDate, counter);
      const formatedFinalDate = format(finalDate, formatPattern);
      const parsedDate = formatDate(formatedFinalDate);
      sessionDates[currentDate] = parsedDate;
      counter += 1;
    });
    setEditedData({ ...editedData, ...sessionDates });
  };

  const handleDateChange = (date, key, parent) => {
    if (forCreate && validateInput(date)) {
      setAllSessions(date);
    } else if (!validateInput(date)) {
      const formatPattern = "MM/dd/yyyy";
      const newUpdatedData = findParentAndUpdateValue(
        editedData,
        format(date, formatPattern),
        key,
        parent
      );
      setEditedData(newUpdatedData);
    }
  };

  const handleDropInSessions = (dates) => {
    const newUpdatedData = { ...editedData };
    newUpdatedData.dropInSessions = [];
    dates.map((res) => {
      return res.id
        ? newUpdatedData?.dropInSessions.push(res)
        : newUpdatedData?.dropInSessions.push({
            dropInSessionDate: res.dropInSessionDate,
          });
    });
    setEditedData(newUpdatedData);
  };

  const handleDateAndTimeChange = (date, key, parent) => {
    if (forCreate) {
      if (validateInput(date)) {
        if (editedData[key]) {
          const formatPattern = "MM/dd/yyyy HH:mm";
          const newUpdatedData = findParentAndUpdateValue(
            editedData,
            format(new Date(date), formatPattern),
            key,
            null
          );
          setEditedData(newUpdatedData);
        } else {
          setAllSessions(date);
        }
        setInvalidInputKeys(invalidInputKeys.filter((k) => k !== key));
      } else {
        setInvalidInputKeys([...invalidInputKeys, key]);
      }
    } else {
      const newUpdatedData = findParentAndUpdateValue(
        editedData,
        format(new Date(date), "MM/dd/yyyy HH:mm"),
        key,
        parent
      );
      setEditedData(newUpdatedData);
    }
  };

  const handleGoBack = () => {
    let rootUrl = history.location.pathname.split("/");
    rootUrl.pop();
    rootUrl = rootUrl.join("/");
    history.push(rootUrl);
  };

  const getSelectOptionValue = (option) => {
    if (option.toUpperCase() === "YES") return true;
    if (option.toUpperCase() === "NO") return false;
    return option.split(" ").join("_").toUpperCase();
  };

  const handleSaveForm = async () => {
    setDisableYesButtonSubmit(true);
    await handleSave(editedData)
      .then(() => {
        setOpenModal(false);
        handleGoBack();
      })
      .catch((e) => {
        setOpenModal(false);
        setSaveError(e.message);
      });
  };
  const handleStatusChange = async (value) => {
    const newUpdatedData = findParentAndUpdateValue(
      editedData,
      value,
      "status",
      null
    );
    setEditedData(newUpdatedData);
  };

  const handleModal = async () => {
    setDisableSaveButtonSubmit(true);
    const existingGroups = await getAllGroups();
    const exists = getGroupByName(
      existingGroups.groupDTOS,
      editedData.groupName
    );

    if (!forCreate) {
      setOpenModal(true);
    } else if (
      (forCreate && invalidInputKeys.length !== 0) ||
      (forCreate && !allInputFieldsCompleted())
    ) {
      setNotification({
        ...notification,
        message: "You can't save an incorrect or incomplete form",
        color: SnackbarTypes.ERROR,
        key: new Date(),
      });
      setDisableSaveButtonSubmit(false);
    } else if (allInputFieldsCompleted && exists) {
      setNotification({
        ...notification,
        message:
          "There's already an existing group with this name, please choose another name and try again.",
        color: SnackbarTypes.ERROR,
        key: new Date(),
      });
      setDisableSaveButtonSubmit(false);
    } else {
      setOpenModal(true);
    }
  };

  const getDate = (key, parent) => {
    return Date.parse(findParentAndGetValue(key, parent));
  };

  function getInputType() {
    const elements = headers.map((header) => {
      switch (header.type) {
        case "text":
          return (
            <>
              <TextField
                color="secondary"
                variant="outlined"
                key={`${header.parent}_${header.key}`}
                label={header.label}
                id={header.key}
                name={header.key}
                autoComplete={header.key === "groupName" ? "off" : "on"}
                value={findParentAndGetValue(header.key, header.parent)}
                onChange={(e) => handleInputChange(e, header.parent)}
                disabled={!header.editable}
              />
              {forCreate &&
                header.key === "coordinator" &&
                invalidInputKeys?.includes(header.key) && (
                  <p className={classes.invalidDateMsg}>
                    {header.label} name cannot be composed of special characters
                  </p>
                )}
              {forCreate &&
                header.key === "sessionLeader" &&
                invalidInputKeys?.includes(header.key) && (
                  <p className={classes.invalidDateMsg}>
                    {header.label} name cannot be composed of special characters
                  </p>
                )}
              {forCreate &&
                header.key === "sessionLink" &&
                invalidInputKeys?.includes(header.key) && (
                  <p className={classes.invalidDateMsg}>
                    {header.label} must be a valid link
                  </p>
                )}
            </>
          );
        case "textarea":
          return (
            <>
              <TextField
                color="secondary"
                variant="outlined"
                multiline
                rows={4}
                inputProps={{ maxLength: 2000 }}
                key={`${header.parent}_${header.key}`}
                label={header.label}
                id={header.key}
                name={header.key}
                value={findParentAndGetValue(header.key, header.parent)}
                onChange={(e) => handleInputChange(e, header.parent)}
                disabled={!header.editable}
              />
              {forEdit &&
                header.key === "notes" &&
                invalidInputKeys?.includes(header.key) && (
                  <p className={classes.invalidDateMsg}>
                    {header.label} should contain a maximum of 2000 chars
                  </p>
                )}
            </>
          );
        case "date":
          return header.key === "dropInSession" ? (
            <DropInSession
              data={data}
              onChangeSessions={(dates) => handleDropInSessions(dates)}
            />
          ) : (
            <>
              <DatePicker
                key={`${header.parent}_${header.key}`}
                label={header.label}
                id={header.key}
                name={header.key}
                value={
                  header.parent === "userProfile" && editedData[header.parent]
                    ? editedData[header.parent][header.key]
                    : editedData[header.key]
                }
                onChange={(e) => handleDateChange(e, header.key, header.parent)}
                disabled={
                  header.parent === "createGroup" &&
                  header.key !== "week1DateTime"
                }
                format={forCreate ? "dd/MM/yyyy HH:mm" : "dd/MM/yyyy"}
                disableFuture={!forCreate}
              />

              {forCreate &&
                header.parent === "createGroup" &&
                header.key === "week3DateTime" && (
                  <span className={classes.createGroupNote}>
                    *Mid-point evaluation 30 min
                  </span>
                )}
              {forCreate &&
                header.parent === "createGroup" &&
                header.key === "week1DateTime" && (
                  <span className={classes.createGroupNote}>
                    *Plan your sessions one week from each other based on
                    session one selection
                  </span>
                )}
              {forCreate &&
                header.parent === "createGroup" &&
                header.key === "week1DateTime" &&
                invalidInputKeys?.includes(header.key) && (
                  <p className={classes.invalidDateMsg}>
                    {header.label} date must be higher than the current one.
                  </p>
                )}
            </>
          );
        case "select":
          return (
            <TextField
              key={`${header.parent}_${header.key}`}
              color="secondary"
              variant="outlined"
              select
              label={header.label}
              id={header.key}
              name={header.key}
              value={findParentAndGetValue(header.key, header.parent)}
              onChange={(e) => handleInputChange(e, header.parent)}
              disabled={!header.editable}
            >
              {header.availableAnswers.map((option) => (
                <MenuItem key={option} value={getSelectOptionValue(option)}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          );
        case "dateAndTime":
          return (
            <>
              <MuiThemeProvider theme={customTheme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDateTimePicker
                    key={`${header.parent}_${header.key}`}
                    name={header.key}
                    autoOk
                    ampm={false}
                    strictCompareDates
                    format="dd/MM/yyyy HH:mm"
                    value={getDateTimeFieldValue(header.parent, header.key)}
                    disabled={!header.editable}
                    onChange={(e) =>
                      handleDateAndTimeChange(
                        e,
                        header.key,
                        forCreate ? editedData[header.key] : header.parent
                      )
                    }
                    disablePast={
                      !!(forCreate || header.parent === "createGroup")
                    }
                    label={header.label}
                    variant="dialog"
                    style={{ display: "flex", marginBottom: "10px" }}
                  />
                  {forCreate &&
                    header.parent === "createGroup" &&
                    (header.key === "week1DateTime" ||
                      header.key === "week2DateTime" ||
                      header.key === "week3DateTime" ||
                      header.key === "week4DateTime" ||
                      header.key === "week5DateTime" ||
                      header.key === "week6DateTime") &&
                    invalidInputKeys?.includes(header.key) && (
                      <p className={classes.invalidDateMsg}>
                        {header.label} date must be higher than the current
                        date.
                      </p>
                    )}
                </MuiPickersUtilsProvider>
              </MuiThemeProvider>
              {header.key === "week3DateTime" && (
                <span className={classes.createGroupNote}>
                  *Followed by mid point evaluation
                </span>
              )}
            </>
          );
        case "count":
          return (
            <TextField
              color="secondary"
              variant="outlined"
              key={`${header.parent}_${header.key}`}
              label={header.label}
              id={header.key}
              name={header.key}
              value={findParentAndGetValue(header.key, header.parent).length}
              onChange={() => {}}
              disabled={!header.editable}
            />
          );
        case "custom": {
          return (
            <div className={classes.customTemplate} key={header.key}>
              {handleGetCustomTemplates(
                header.key === "dateOfBirth"
                  ? editedData[header.parent][header.key]
                  : data[header.key],
                header.key,
                header.parent,
                header.label,
                !header.editable,
                header.key === "status" ? handleStatusChange : handleDateChange
              )}
            </div>
          );
        }
        default:
          return <div>No Data</div>;
      }
    });
    return <div>{elements}</div>;
  }

  return (
    <>
      <Paper>
        {loading ? (
          <></>
        ) : (
          <>
            <Box p={5} pl={20} pr={20}>
              {getInputType()}
              <Box mt={5}>
                <Box mr={1} display="inline">
                  <SecondaryButton
                    variant="outlined"
                    size="small"
                    label="Save"
                    disabled={disableSaveButtonSubmit}
                    onClick={() => handleModal()}
                  >
                    Save
                  </SecondaryButton>
                </Box>
                <Box display="inline">
                  <NeutralButton
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      handleGoBack();
                    }}
                  >
                    Cancel
                  </NeutralButton>
                </Box>
              </Box>
              <Modal
                open={openModal}
                onClose={() => {
                  setDisableSaveButtonSubmit(false);
                  setOpenModal(false);
                }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                <div style={modalStyle} className={classes.paper}>
                  <div>Are you sure you want to save your changes?</div>
                  <Box mt={5}>
                    <Box mr={1} display="inline">
                      <SecondaryButton
                        variant="outlined"
                        size="small"
                        disabled={disableYesButtonSubmit}
                        onClick={() => handleSaveForm()}
                      >
                        Yes
                      </SecondaryButton>
                    </Box>
                    <Box display="inline">
                      <NeutralButton
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          setOpenModal(false);
                          setDisableSaveButtonSubmit(false);
                        }}
                      >
                        Cancel
                      </NeutralButton>
                    </Box>
                  </Box>
                </div>
              </Modal>
              <Modal
                open={saveError}
                onClose={() => setSaveError(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                <div style={modalStyle} className={classes.paper}>
                  <div>{saveError}</div>
                  <Box mt={5}>
                    <Box display="inline">
                      <NeutralButton
                        variant="outlined"
                        size="small"
                        onClick={() => setSaveError(false)}
                      >
                        Ok
                      </NeutralButton>
                    </Box>
                  </Box>
                </div>
              </Modal>
            </Box>
          </>
        )}
      </Paper>
    </>
  );
};

EditDetailsForm.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  data: PropTypes.shape({}).isRequired,
  handleSave: PropTypes.func.isRequired,
  handleGetCustomTemplates: PropTypes.func,
  forCreate: PropTypes.bool,
  forEdit: PropTypes.bool,
};
EditDetailsForm.defaultProps = {
  handleGetCustomTemplates: null,
  forCreate: false,
  forEdit: false,
};

export default EditDetailsForm;
