import React, { useContext } from "react";
import NotificationSnackbarContext from "../context/NotificationSnackbarContext";
import CustomSnackbar from "./CustomSnackbar";

const NotificationSnackbar = () => {
  const { notification } = useContext(NotificationSnackbarContext);
  const { message, color } = notification;
  return <>{message && <CustomSnackbar color={color} message={message} />}</>;
};
export default NotificationSnackbar;
