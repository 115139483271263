import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { NavLink } from "react-router-dom";
import {
  Card,
  CardContent,
  CardActionArea,
  CardActions,
  Button,
  Typography,
  Grid,
  useTheme,
} from "@material-ui/core";

import { computeChartDataSet, chartLegend } from "../../utils/computeChartData";
import enoBreatheTheme from "../../theme/enoBreatheTheme";
import { getAllUsersDashboard } from "../../services/usersService";
import { getAllClinicsDashboard } from "../../services/clinicsService";
import { getAllGroupsDashboard } from "../../services/groupsService";

const Dashboard = () => {
  const theme = useTheme(enoBreatheTheme);

  const [users, setUsers] = useState([]);
  const [clinics, setClinics] = useState([]);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cardsDetails, setCardsDetails] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const dbUsers = await getAllUsersDashboard();
      const dbGroups = await getAllGroupsDashboard();
      const dbClinics = await getAllClinicsDashboard();

      setUsers(dbUsers);
      setClinics(dbClinics);
      setGroups(dbGroups);
      setCardsDetails([
        {
          title: "Users",
          navLink: "/admin/users",
          data: dbUsers,
        },
        {
          title: "Groups",
          navLink: "/admin/groups",
          data: dbGroups,
        },
        {
          title: "Clinics and Referral codes",
          navLink: "/admin/clinicsAndReferral",
          data: dbClinics,
        },
      ]);
    }

    fetchData().then(() => {
      setLoading(false);
    });
  }, []);

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 80 },
    legend: {
      display: true,
    },
    maintainAspectRatio: true,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: "index",
      titleFontColor: theme.palette.text.primary,
    },
  };

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <Grid container spacing={5}>
          {cardsDetails.map(
            ({ title: cardTitle, navLink, data, keyToChart }) => {
              return (
                <Grid item xs={12} md={6} key={cardTitle}>
                  <Card>
                    <CardActionArea component={NavLink} to={navLink}>
                      <CardContent>
                        <Typography variant="h2" gutterBottom>
                          {cardTitle}
                        </Typography>
                        <Doughnut
                          data={computeChartDataSet(data)}
                          options={options}
                        />
                        <Grid container spacing={3}>
                          {chartLegend(data).map(
                            ({ category, percentage, color }) => (
                              <Grid item xs="auto" key={category}>
                                <Typography variant="body1">
                                  {category}
                                </Typography>
                                <Typography style={{ color }} variant="body1">
                                  {percentage}%
                                </Typography>
                              </Grid>
                            )
                          )}
                        </Grid>
                      </CardContent>
                    </CardActionArea>
                    <CardActions>
                      <Button
                        size="small"
                        color="primary"
                        component={NavLink}
                        to={navLink}
                      >
                        VIEW PAGE
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              );
            }
          )}
        </Grid>
      )}
    </>
  );
};

export default Dashboard;
