import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EditDetailsForm from "../../../components/admin/EditDetailsForm";
import { getUserFormHeaders } from "../../../services/usersService";
import useLoadDataFromAPI from "../../../utils/useLoadDataFromAPI";
import endpoints from "../../../utils/endpoints";
import ErrorPanel from "../../../components/ErrorPanel";
import api from "../../../utils/api";
import BirthDate from "../../../components/BirthDate";
import ChangeStatusSection from "../../../components/ChangeStatusSection";

const UserDetails = () => {
  const { userId } = useParams();
  const [formLabels, setFormLabels] = useState([]);

  const handleSaveToDb = async (newData) => {
    return api
      .putAPI(`${endpoints.getUsers}/${userId}`, null, JSON.stringify(newData))
      .then((data) => {
        if (data.status >= 400) {
          throw {
            message:
              "There was an error saving the survey. Please try again later or contact the administrator",
          };
        }
      })
      .catch(() => {
        throw {
          message:
            "There was an error saving the survey. Please try again later or contact the administrator",
        };
      });
  };

  useEffect(() => {
    const headers = getUserFormHeaders();

    setFormLabels(headers);
  }, [userId]);

  const getCustomTemplate = (
    inputValue,
    key,
    parent,
    label,
    disabled,
    handleChange
  ) => {
    switch (key) {
      case "dateOfBirth":
        return (
          <BirthDate
            name={key}
            label={label}
            inputValue={inputValue ? new Date(inputValue) : null}
            disabled={disabled}
            handleChangeValue={(value) => handleChange(value, key, parent)}
          />
        );
      case "status":
        return (
          <ChangeStatusSection
            label={label}
            id={key}
            name={key}
            value={inputValue}
            handleChangeValue={(value) => handleChange(value)}
          />
        );
      default:
        return <div>No Template</div>;
    }
  };

  const { data: user, loading, statusCode } = useLoadDataFromAPI({
    url: `${endpoints.getUsers}/${userId}`,
    method: "GET",
  });

  if (loading) {
    return <>Loading ... </>;
  }

  if (statusCode >= 400) {
    return (
      <ErrorPanel heading="Error loading data">
        The page could not be loaded. Please try again later ot contact the
        administrator
      </ErrorPanel>
    );
  }

  return (
    <>
      <EditDetailsForm
        headers={formLabels}
        data={user}
        handleSave={handleSaveToDb}
        handleGetCustomTemplates={getCustomTemplate}
      />
    </>
  );
};

export default UserDetails;
