import { format } from "date-fns";

const encodeURL = (string) => {
  return encodeURIComponent(string);
};

const filtersToQueryParamsMapFn = (item) => {
  let today;
  let age = "";
  let dateFilter = "";
  let itemValueWithoutSpaces;
  switch (item.key) {
    case "sessionAvailability":
      switch (item.value) {
        case "morning":
          return "userProfile.morningSessionAvailability==true";
        case "lunch time":
          return "userProfile.lunchtimeSessionAvailability==true";
        case "afternoon":
          return "userProfile.afternoonSessionAvailability==true";
        default:
          // "evening"
          return "userProfile.eveningSessionAvailability==true";
      }
    case "age":
      if (item.value?.min) {
        today = new Date();
        today.setFullYear(today.getFullYear() - parseInt(item.value.min, 10));
        age = `${item.queryParamKey}<=${format(today, "yyyy-MM-dd")}`;
      }
      if (item.value?.max) {
        today = new Date();
        today.setFullYear(
          today.getFullYear() - parseInt(item.value.max, 10) - 1
        );
        age = `${age}${age.length > 0 ? ";" : ""}${item.queryParamKey}>${format(
          today,
          "yyyy-MM-dd"
        )}`;
      }
      return age;
    case "registrationDate":
    case "dateCovidInfection":
      if (
        item.value?.min &&
        item.value?.min.getTime() === item.value?.min.getTime()
      ) {
        dateFilter = `${item.queryParamKey}>=${format(
          item.value?.min,
          "yyyy-MM-dd"
        )}`;
      }
      if (
        item.value?.max &&
        item.value?.max.getTime() === item.value?.max.getTime()
      ) {
        dateFilter = `${dateFilter}${
          dateFilter && dateFilter.length > 0 ? ";" : ""
        }${item.queryParamKey}<=${format(item.value?.max, "yyyy-MM-dd")}`;
      }
      return dateFilter;
    case "gender":
      return `${item.queryParamKey}=="${item.value
        .toUpperCase()
        .split(/-| /)
        .join("_")}"`;
    case "additionalNotes":
      itemValueWithoutSpaces = item.value.trim().split(/\s+/).join("*");
      return `${item.queryParamKey}==${encodeURL(
        `*${itemValueWithoutSpaces}*`
      )}`;
    default:
      if (item.tableType === "date") {
        return `${item.queryParamKey}=="${format(item.value, "yyyy-MM-dd")}"`;
      }
      return `${item.queryParamKey}=="${encodeURL(item.value)}"`;
  }
};

const filterData2QueryParams = (filtersData) => {
  const activeFilters = filtersData?.filter((item) => item.active);

  let queryParams = activeFilters.map(filtersToQueryParamsMapFn).join(";");

  if (queryParams) {
    queryParams = `?search=${queryParams}`;
  }

  return queryParams;
};

// eslint-disable-next-line import/prefer-default-export
export const useFilterUtils = () => {
  return { filtersToQueryParamsMapFn, filterData2QueryParams };
};
