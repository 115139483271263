import * as constants from "../forms/constants/completeProfileConstants";

const getEthnicityValueFromArrString = (value) => {
  const found = constants.ethnicityOptions.find(
    (option) => option.value === value
  );
  return found ? found.label : "-";
};

export default getEthnicityValueFromArrString;
