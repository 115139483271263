import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AdminTable from "../../../components/admin/AdminTable";
import { getAllUsersHeaders } from "../../../services/usersService";
import useLoadDataFromAPI from "../../../utils/useLoadDataFromAPI";
import endpoints from "../../../utils/endpoints";
import ErrorPanel from "../../../components/ErrorPanel";
import ColorButton from "../../../components/ColorButton";
import AssignGroupModal from "../Groups/AssignGroupModal";
import CustomModal from "../../../components/CustomModal";
import CustomSnackbar from "../../../components/CustomSnackbar";
import UsersFilter from "./UsersFilter";
import getAgeForBirthdate from "../../../utils/getAgeForBirthdate";

const addAge = (data) => {
  if (!data) {
    return data;
  }
  return data.map((item) => ({
    ...item,
    age: getAgeForBirthdate(item.dateOfBirth)?.toString(),
  }));
};

const UsersList = () => {
  const [usersHeaders, setUsersHeaders] = useState([]);
  const [users, setUsers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [snackbarType, setSnackbarType] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [forceReload, setForceReload] = useState(false);
  const [fetchUrl, setFetchUrl] = useState(null);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [totalNumber, setTotalNumber] = useState(0);
  const [numberRow, setNumberRow] = useState(5);

  const { data, loading, statusCode } = useLoadDataFromAPI({
    url:
      fetchUrl &&
      (fetchUrl.includes("?")
        ? `${fetchUrl}&page=${page}&size=${numberRow}`
        : `${fetchUrl}?page=${page}&size=${numberRow}`),
    forceReload,
    setForceReload,
  });
  const history = useHistory();

  useEffect(() => {
    const headers = getAllUsersHeaders();
    setUsersHeaders(headers);
  }, []);

  useEffect(() => {
    if (!data) return;

    if (data.nextPageId !== null) {
      setPage(data.nextPageId - 1);
    }
    setUsers(data.userDisplayDTOS);
    setTotalNumber(data.userListSize);
  }, [data]);

  const handleActions = (row, pathname) => {
    const user = users.find((usr) => usr.id === row.id);
    const actions = [
      {
        text: "Edit",
        query: row,
        handleClick: () => history.push(`${pathname}/${row.id}`),
        enable:
          user?.additionalNotes ||
          user?.sessionBarriers ||
          user?.barriersDescription ||
          user?.dateOfBirth ||
          user?.gender ||
          user?.ethnicity ||
          user?.dateCovidInfection ||
          user?.hospitalAdmitted ||
          user?.morningSessionAvailability ||
          user?.lunchtimeSessionAvailability ||
          user?.afternoonSessionAvailability ||
          user?.eveningSessionAvailability ||
          user?.englishFirstLanguage ||
          user?.city,
      },
      {
        text: "Assign group",
        query: row,
        handleClick: () => handleAssignGroup(row),
        enable: row.status === "ASSIGN_GROUP_AND_OFFER_ONE_ON_ONE",
      },
    ];
    return getActionsTemplate(actions);
  };

  const getActionsTemplate = (actions) => {
    return actions.map((action) => (
      <ColorButton
        key={action.text}
        size="small"
        query={action.query}
        disabled={!action.enable}
        onClick={action.handleClick}
        style={{ fontSize: "inherit" }}
      >
        {action.text}
      </ColorButton>
    ));
  };

  const handleAssignGroup = (row) => {
    setOpenModal(true);
    setSelectedUser(row);
    setSnackbarMessage("");
    setSnackbarType("");
  };

  const handleCloseModal = (type, message) => {
    setOpenModal(false);
    setSnackbarType(type);
    setSnackbarMessage(message);
    if (type && message) {
      setForceReload(true);
    }
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newPage, nrRows) => {
    setPage(newPage);
    setNumberRow(nrRows);
  };

  return (
    <>
      <UsersFilter
        users={users || []}
        fetchUrl={fetchUrl}
        setFetchUrl={setFetchUrl}
        isFiltersOpen={isFiltersOpen}
        setIsFiltersOpen={setIsFiltersOpen}
        setPage={setPage}
      />
      {statusCode >= 400 && (
        <ErrorPanel heading="Error loading data">
          The page could not be loaded. Please try again later ot contact the
          administrator
        </ErrorPanel>
      )}
      {loading && <>Loading...</>}
      {!loading && statusCode < 400 && (
        <>
          {snackbarType && snackbarMessage && (
            <CustomSnackbar
              color={snackbarType}
              message={snackbarMessage}
              autoHideDuration={2000}
            />
          )}
          <AdminTable
            dataColumns={usersHeaders}
            dataRows={addAge(users) || []}
            actionsList={handleActions}
            showPagination
            page={page}
            rowsPerPageProp={numberRow}
            totalNumberOfPages={totalNumber}
            onChangePage={handleChangePage}
            onChangeRows={handleChangeRowsPerPage}
          />
          {selectedUser && (
            <CustomModal open={openModal}>
              <AssignGroupModal
                participant={selectedUser}
                handleCloseModal={handleCloseModal}
              />
            </CustomModal>
          )}
        </>
      )}
    </>
  );
};

export default UsersList;
