import { Button, withStyles } from "@material-ui/core";

// white to black button
const NeutralButton = withStyles((enoBreatheTheme) => ({
  root: {
    color: enoBreatheTheme.palette.secondary.main,
    backgroundColor: enoBreatheTheme.palette.common.white,
    "&:hover": {
      backgroundColor: enoBreatheTheme.palette.secondary.main,
      color: enoBreatheTheme.palette.common.white,
    },
  },
}))(Button);

export default NeutralButton;
