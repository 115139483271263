import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AdminTable from "../../../components/admin/AdminTable";
import {
  getAllClinicsHeaders,
  getAllClinics,
} from "../../../services/clinicsService";

const ClinicsList = (props) => {
  const { render } = props;
  const [loading, setLoading] = useState(true);
  const [clinics, setClinics] = useState([]);
  const [clinicsHeaders, setClinicsHeaders] = useState([]);

  const fetchData = async () => {
    const dbUsers = await getAllClinics();
    setClinics(dbUsers);
  };

  useEffect(() => {
    const titleHeaders = getAllClinicsHeaders();
    setClinicsHeaders(titleHeaders);
    fetchData().then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (render) {
      setLoading(true);
      fetchData().then(() => {
        setLoading(false);
      });
    }
  }, [render]);

  return (
    <>
      {loading ? (
        <>
          <h1>Loading data...</h1>
        </>
      ) : (
        <AdminTable dataColumns={clinicsHeaders} dataRows={clinics} />
      )}
    </>
  );
};

ClinicsList.propTypes = {
  render: PropTypes.bool.isRequired,
};

export default ClinicsList;
