import { Button, withStyles } from "@material-ui/core";

// black to white button
const SecondaryButton = withStyles((enoBreatheTheme) => ({
  root: {
    color: enoBreatheTheme.palette.common.white,
    backgroundColor: enoBreatheTheme.palette.secondary.main,
    "&:hover": {
      backgroundColor: enoBreatheTheme.palette.common.white,
      color: enoBreatheTheme.palette.secondary.main,
    },
  },
  disabled: {
    color: enoBreatheTheme.palette.common.white,
    backgroundColor: enoBreatheTheme.palette.secondary.contrastText,
  },
}))(Button);

export default SecondaryButton;
