import environment from "../utils/environment";
import api from "../utils/api";
import endpoints from "../utils/endpoints";

const dbJson = [
  {
    id: "1",
    registrationDate: "11/02/2020",
    referralCode: "SUF490TPEB",
    referralClinic: "Norfolk Community Health and Care Trust",
    clinicCounty: "Norfolk",
    region: "East of England",
    firstname: "John",
    lastname: "Doe",
    status: "Active",
    assignedGroup: "Group 1",
    sessionLeader: "Leader 1",
    groupCoordinator: "Coordinator 1",
    additionalNotes:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    sessionBarriers: "No",
    barrierToParticipation: "No Barrier",
    dateOfBirth: "11/12/1982",
    age: "59",
    city: "Birmingham",
    gender: "female",
    ethnicity: "caucasian",
    dateCovidInfection: "01/2021",
    admittedToHospital: "true",
    sessionAvailability: "Tuesdays from 5PM",
    englishFirstLanguage: "false",
    dropinSessionAttended: "true",
  },
  {
    id: "2",
    firstname: "Ana",
    lastname: "Blandiana",
    status: "In programme",
    assignedGroup: "Group 1",
    sessionLeader: "Leader 1",
    groupCoordinator: "Coordinator 1",
    additionalNotes:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    sessionBarriers: "No",
    barrierToParticipation: "No Barrier",
    registrationDate: "04/04/2021",
    referralClinic: "Royal Victoria Infirmary, Newcastle",
    region: "West Midlands",
    age: "46",
    city: "London",
    gender: "female",
    ethnicity: "asian",
    dateCovidInfection: "03/2021",
    admittedToHospital: "false",
    sessionAvailability: "weekdays after 8PM",
    englishFirstLanguage: "true",
    dropinSessionAttended: "false",
  },
  {
    id: "3",
    firstname: "John",
    lastname: "Smith",
    status: "In programme",
    assignedGroup: "Group 2",
    sessionLeader: "Leader 2",
    groupCoordinator: "Coordinator 2",
    additionalNotes:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    sessionBarriers: "No",
    barrierToParticipation: "No Barrier",
    registrationDate: "05/04/2021",
    referralClinic: "Royal Victoria Infirmary, Newcastle",
    region: "West Midlands",
    age: "46",
    city: "London",
    gender: "male",
    ethnicity: "caucasian",
    dateCovidInfection: "01/2021",
    admittedToHospital: "true",
    sessionAvailability: "weekdays after 8PM",
    englishFirstLanguage: "true",
    dropinSessionAttended: "false",
  },
  {
    id: "4",
    firstname: "Bill",
    lastname: "John",
    status: "Post-programme",
    assignedGroup: "Group 1",
    sessionLeader: "Leader 1",
    groupCoordinator: "Coordinator 1",
    additionalNotes:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    sessionBarriers: "No",
    barrierToParticipation: "No Barrier",
    registrationDate: "04/04/2021",
    referralClinic: "Royal Victoria Infirmary, Newcastle",
    region: "North West",
    age: "32",
    city: "Southampton",
    gender: "male",
    ethnicity: "asian",
    dateCovidInfection: "12/2020",
    admittedToHospital: "true",
    sessionAvailability: "weekends",
    englishFirstLanguage: "true",
    dropinSessionAttended: "true",
  },
  {
    id: "5",
    firstname: "Samantha",
    lastname: "Robinson",
    status: "post-programme",
    assignedGroup: "Group 3",
    sessionLeader: "Leader 3",
    groupCoordinator: "Coordinator 3",
    additionalNotes: "Additional notes",
    sessionBarriers: "true",
    barrierToParticipation: "doesn't like singing",
    registrationDate: "15/09/2020",
    referralClinic: "St George’s Hospital NHS Trust",
    region: "London",
    age: "68",
    city: "London",
    gender: "female",
    ethnicity: "asian",
    dateCovidInfection: "08/2020",
    admittedToHospital: "false",
    sessionAvailability: "anytime",
    englishFirstLanguage: "false",
    dropinSessionAttended: "true",
  },
  {
    id: "6",
    firstname: "Harry",
    lastname: "Potter",
    status: "On Hold",
    assignedGroup: "Group 3",
    sessionLeader: "Leader 3",
    groupCoordinator: "Coordinator 3",
    additionalNotes: "Additional notes",
    sessionBarriers: "No",
    barrierToParticipation: "No Barrier",
    registrationDate: "14/08/2020",
    referralClinic: "South Warwickshire Foundation Trust",
    region: "West Midlands",
    age: "23",
    city: "Liverpool",
    gender: "male",
    ethnicity: "caucasian",
    dateCovidInfection: "01/2021",
    admittedToHospital: "true",
    sessionAvailability: "Tuesdays from 5PM",
    englishFirstLanguage: "false",
    dropinSessionAttended: "true",
  },
  {
    id: "7",
    firstname: "Irma",
    lastname: "Pince",
    status: "On Hold",
    assignedGroup: "Group 1",
    sessionLeader: "Leader 1",
    groupCoordinator: "Coordinator 1",
    additionalNotes:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    sessionBarriers: "No",
    barrierToParticipation: "No Barrier",
  },
];

// const dataTableHeaders = [
//   {
//     rowTitle: "First Name",
//     key: "firstname",
//     tableType: "text",
//   },
//   {
//     rowTitle: "Last Name",
//     key: "lastname",
//     tableType: "text",
//   },
//   {
//     rowTitle: "Status",
//     key: "status",
//     tableType: "text",
//   },
//   {
//     rowTitle: "Assigned Group",
//     key: "assignedGroup",
//     tableType: "text",
//   },
//   {
//     rowTitle: "Session Leader",
//     key: "sessionLeader",
//     tableType: "text",
//   },
//   {
//     rowTitle: "Group Coordinator",
//     key: "groupCoordinator",
//     tableType: "text",
//   },
//   {
//     rowTitle: "Additional Notes",
//     key: "additionalNotes",
//     tableType: "input",
//   },
//   {
//     rowTitle: "Any Barrier To Participation",
//     key: "sessionBarriers",
//     tableType: "text",
//   },
//   {
//     rowTitle: "Barrier to participation",
//     key: "barrierToParticipation",
//     tableType: "text",
//   },
// ];

const dataTableHeaders2 = [
  {
    rowTitle: "First Name",
    key: "firstname",
    tableType: "text",
  },
  {
    rowTitle: "Last Name",
    key: "lastname",
    tableType: "text",
  },
  {
    rowTitle: "Status",
    key: "status",
    tableType: "text",
  },
  {
    rowTitle: "Assigned Group",
    key: "assignedGroup",
    tableType: "text",
  },
  {
    rowTitle: "Session Leader",
    key: "sessionLeader",
    tableType: "text",
  },
  {
    rowTitle: "Group Coordinator",
    key: "coordinator",
    tableType: "text",
  },
  {
    rowTitle: "Barriers",
    key: "sessionBarriers",
    tableType: "boolean",
  },
  {
    rowTitle: "Registration",
    key: "registrationDate",
    tableType: "date",
  },
  {
    rowTitle: "Referral clinic",
    key: "clinicName",
    tableType: "text",
  },
  {
    rowTitle: "Region",
    key: "region",
    tableType: "text",
  },
  {
    rowTitle: "Age",
    key: "age",
    tableType: "text", // interval??
  },
  {
    rowTitle: "City/town",
    key: "city",
    tableType: "text",
  },
  {
    rowTitle: "Gender",
    key: "gender",
    tableType: "text",
  },
  {
    rowTitle: "Ethnicity",
    key: "ethnicity",
    tableType: "text",
  },
  {
    rowTitle: "COVID infection date",
    key: "dateCovidInfection",
    tableType: "date",
  },
  {
    rowTitle: "Admitted to hospital",
    key: "admittedToHospital",
    tableType: "boolean",
  },
  {
    rowTitle: "Session availability",
    key: "sessionAvailability",
    tableType: "text",
  },
  {
    rowTitle: "English as first language",
    key: "englishFirstLanguage",
    tableType: "boolean",
  },
  {
    rowTitle: "Twilight sessions attended",
    key: "twilightSession",
    tableType: "boolean",
  },
  {
    rowTitle: "Additional notes",
    key: "additionalNotes",
    // tableType: "text",
    tableType: "input",
  },
];

const dataLabels = [
  {
    label: "Registration date",
    key: "registrationDate",
    type: "text",
    section: "user",
    availableAnswers: [],
    parent: null,
    editable: false,
    required: true,
  },
  {
    label: "Referral Code",
    key: "referralCode",
    type: "text",
    section: "user",
    availableAnswers: [],
    parent: null,
    editable: false,
    required: true,
  },
  {
    label: "Referral clinic",
    key: "referralClinic",
    type: "text",
    section: "user",
    availableAnswers: [],
    parent: null,
    editable: false,
    required: false,
  },
  {
    label: "Clinic county",
    key: "county",
    type: "text",
    section: "user",
    availableAnswers: [],
    parent: "region",
    editable: false,
    required: true,
  },
  {
    label: "Region",
    key: "name",
    type: "text",
    section: "user",
    availableAnswers: [],
    parent: "region",
    editable: false,
    required: true,
  },
  {
    label: "First Name",
    key: "firstname",
    type: "text",
    section: "user",
    availableAnswers: [],
    parent: null,
    editable: true,
    required: true,
  },
  {
    label: "Last Name",
    key: "lastname",
    type: "text",
    section: "user",
    availableAnswers: [],
    parent: null,
    editable: true,
    required: true,
  },
  {
    label: "Any barriers to participation",
    key: "sessionBarriers",
    parent: "userProfile",
    type: "select",
    section: "user",
    availableAnswers: ["Yes", "No"],
    editable: true,
    required: true,
  },
  {
    label: "Barriers to participation",
    key: "barriersDescription",
    type: "textarea",
    section: "user",
    availableAnswers: [],
    parent: "userProfile",
    editable: true,
    required: true,
  },
  {
    label: "Date of Birth",
    key: "dateOfBirth",
    type: "custom",
    section: "user",
    availableAnswers: [],
    parent: "userProfile",
    editable: true,
    required: true,
  },
  {
    label: "Email",
    key: "email",
    type: "text",
    section: "user",
    availableAnswers: [],
    parent: null,
    editable: true,
    required: true,
  },
  {
    label: "Home phone number",
    key: "homePhoneNumber",
    type: "text",
    section: "user",
    availableAnswers: [],
    parent: "userProfile",
    editable: true,
    required: true,
  },
  {
    label: "Mobile phone number",
    key: "mobilePhoneNumber",
    type: "text",
    section: "user",
    availableAnswers: [],
    parent: "userProfile",
    editable: true,
    required: true,
  },
  {
    label: "Address",
    key: "address",
    type: "text",
    section: "user",
    availableAnswers: [],
    parent: "address",
    editable: true,
    required: true,
  },
  {
    label: "Second Address Line", // not to be displayed
    key: "secondAddressLine",
    type: "text",
    availableAnswers: [],
    parent: "address",
    editable: true,
    required: true,
  },
  {
    label: "Post Code",
    key: "postCode",
    type: "text",
    section: "user",
    availableAnswers: [],
    parent: "address",
    editable: true,
    required: true,
  },
  {
    label: "City/Town",
    key: "townOrCity",
    type: "text",
    section: "user",
    availableAnswers: [],
    parent: "address",
    editable: true,
    required: true,
  },
  {
    label: "Gender",
    key: "gender",
    type: "select",
    section: "user",
    parent: "userProfile",
    availableAnswers: [
      "Male",
      "Female",
      "Non-Binary",
      "Transgender",
      "Intersex",
      "Prefer not to say",
    ],
    editable: true,
    required: true,
  },
  {
    label: "Ethnicity",
    key: "ethnicity",
    type: "select",
    section: "user",
    availableAnswers: [
      "Asian or Asian British",
      "Black or Black British",
      "Mixed",
      "Multiple ethnic background",
      "White British",
      "Any other White background",
      "Other",
      "Prefer not to say",
    ],
    parent: "userProfile",
    editable: true,
    required: true,
  },
  {
    label: "Emergency Contact first name",
    key: "firstname",
    type: "text",
    section: "user",
    availableAnswers: [],
    parent: "emergencyContact",
    editable: true,
    required: true,
  },
  {
    label: "Emergency Contact last name",
    key: "lastname",
    type: "text",
    availableAnswers: [],
    parent: "emergencyContact",
    editable: true,
    required: true,
  },
  {
    label: "Emergency Contact Phone Number",
    key: "phoneNumber",
    type: "text",
    section: "user",
    availableAnswers: [],
    parent: "emergencyContact",
    editable: true,
    required: true,
  },
  {
    label: "Emergency Contact's Relationship to you",
    key: "relationship",
    type: "text",
    section: "user",
    availableAnswers: [],
    parent: "emergencyContact",
    editable: true,
    required: true,
  },
  {
    label: "What date would you say you fell ill with COVID?",
    key: "covidInfectionDate",
    type: "date",
    section: "user",
    availableAnswers: [],
    parent: "userProfile",
    editable: true,
    required: true,
  },
  {
    label: "Were you admitted to hospital?",
    key: "hospitalAdmitted",
    type: "select",
    section: "user",
    parent: "userProfile",
    availableAnswers: ["Yes", "No"],
    editable: true,
    required: true,
  },
  {
    label: "What date were you admitted to hospital?",
    key: "hospitalAdmittedDate",
    type: "date",
    section: "user",
    availableAnswers: [],
    parent: "userProfile",
    editable: true,
    required: true,
  },
  {
    label: "What date were you released from hospital?",
    key: "hospitalReleaseDate",
    type: "date",
    section: "user",
    availableAnswers: [],
    parent: "userProfile",
    editable: true,
    required: true,
  },
  {
    label: "Morning availability",
    key: "morningSessionAvailability",
    type: "select",
    availableAnswers: ["Yes", "No"],
    parent: "userProfile",
    section: "user",
    editable: true,
    required: true,
  },
  {
    label: "Lunchtime availability",
    key: "lunchtimeSessionAvailability",
    type: "select",
    availableAnswers: ["Yes", "No"],
    section: "user",
    parent: "userProfile",
    editable: true,
    required: true,
  },
  {
    label: "Afternoon availability",
    key: "afternoonSessionAvailability",
    type: "select",
    section: "user",
    availableAnswers: ["Yes", "No"],
    parent: "userProfile",
    editable: true,
    required: true,
  },
  {
    label: "Evening availability",
    key: "eveningSessionAvailability",
    type: "select",
    section: "user",
    availableAnswers: ["Yes", "No"],
    parent: "userProfile",
    editable: true,
    required: true,
  },
  {
    label: "Is English your first language?",
    key: "englishAsFirstLanguage",
    type: "select",
    section: "user",
    availableAnswers: ["Yes", "No"],
    parent: "userProfile",
    editable: true,
    required: true,
  },
  {
    label: "Specify Language",
    key: "firstLanguage",
    type: "text",
    section: "user",
    availableAnswers: [],
    parent: "userProfile",
    editable: true,
    required: true,
  },
  {
    label: "Consent Given", // not to be displayed
    key: "consentGiven",
    type: "text",
    availableAnswers: [],
    parent: "userProfile",
    editable: false,
    required: true,
  },
  {
    label: "Registration Passed",
    key: "registrationPassed",
    type: "select",
    section: "admin",
    availableAnswers: ["Yes", "No"],
    parent: "userProfile",
    editable: true,
    required: true,
  },
  {
    label: "Pre-programme Health and Wellbeing Survey completed",
    key: "firstSurveyCompleted",
    type: "select",
    section: "admin",
    availableAnswers: ["Yes", "No"],
    parent: "userProfile",
    editable: true,
    required: true,
  },
  {
    label: "Pre Programme Call",
    key: "preProgrammeCall",
    type: "select",
    section: "admin",
    availableAnswers: ["Yes", "No"],
    parent: "userProfile",
    editable: true,
    required: true,
  },
  {
    label: "Additional notes",
    key: "additionalNotes",
    type: "textarea",
    section: "admin",
    availableAnswers: [],
    parent: "userProfile",
    editable: true,
    required: true,
  },
  {
    label: "Assigned Group",
    key: "assignedGroup",
    type: "text",
    section: "admin",
    availableAnswers: [],
    parent: "group",
    editable: false,
    required: true,
  },
  {
    label: "Session Leader",
    key: "sessionLeader",
    type: "text",
    section: "admin",
    availableAnswers: [],
    parent: "group",
    editable: false,
    required: true,
  },
  {
    label: "Group Coordinator",
    key: "groupCoordinator",
    type: "text",
    section: "admin",
    availableAnswers: [],
    parent: "group",
    editable: false,
    required: true,
  },
  {
    label: "Session 1",
    key: "sessionOne",
    type: "select",
    section: "admin",
    availableAnswers: ["Yes", "No"],
    parent: "userProfile",
    editable: true,
    required: true,
  },
  {
    label: "Session 2",
    key: "sessionTwo",
    type: "select",
    section: "admin",
    availableAnswers: ["Yes", "No"],
    parent: "userProfile",
    editable: true,
    required: true,
  },
  {
    label: "Session 3",
    key: "sessionThree",
    type: "select",
    section: "admin",
    availableAnswers: ["Yes", "No"],
    parent: "userProfile",
    editable: true,
    required: true,
  },
  {
    label: "Session 4",
    key: "sessionFour",
    type: "select",
    section: "admin",
    availableAnswers: ["Yes", "No"],
    parent: "userProfile",
    editable: true,
    required: true,
  },
  {
    label: "Session 5",
    key: "sessionFive",
    type: "select",
    section: "admin",
    availableAnswers: ["Yes", "No"],
    parent: "userProfile",
    editable: true,
    required: true,
  },
  {
    label: "Session 6",
    key: "sessionSix",
    type: "select",
    section: "admin",
    availableAnswers: ["Yes", "No"],
    parent: "userProfile",
    editable: true,
    required: true,
  },
  {
    label: "Post programme Health and Wellbeing Survey completed",
    key: "secondSurveyCompleted",
    type: "select",
    section: "admin",
    availableAnswers: ["Yes", "No"],
    parent: "userProfile",
    editable: true,
    required: true,
  },
  {
    label: "Twilight sessions attended",
    key: "twilightSession",
    type: "select",
    section: "admin",
    availableAnswers: ["Yes", "No"],
    parent: "userProfile",
    editable: true,
    required: true,
  },
  {
    label: "Third survey completed",
    key: "thirdSurveyCompleted",
    type: "select",
    section: "admin",
    availableAnswers: ["Yes", "No"],
    parent: "userProfile",
    editable: true,
    required: true,
  },
  {
    label: "Status",
    key: "status",
    type: "custom",
    availableAnswers: [],
    parent: null,
    editable: true,
    required: true,
  },
  {
    label: "Status description",
    key: "statusDescription",
    type: "text",
    section: "admin",
    availableAnswers: [],
    parent: null,
    editable: false,
    required: true,
  },
];

const usersFilters = [
  {
    rowTitle: "First Name",
    key: "firstname",
    tableType: "textfield",
    value: "null",
    queryParamKey: "firstname",
  },
  {
    rowTitle: "Last Name",
    key: "lastname",
    tableType: "textfield",
    value: "null",
    queryParamKey: "lastname",
  },
  {
    rowTitle: "Additional Notes",
    key: "additionalNotes",
    tableType: "textfield",
    value: "null",
    queryParamKey: "userProfile.additionalNotes",
  },
  {
    rowTitle: "Assigned Group",
    key: "assignedGroup",
    tableType: "text",
    values: ["Group 1", "Group 2", "Group 3"],
    active: false,
    value: null,
    queryParamKey: "group.name",
  },
  {
    rowTitle: "Status",
    key: "status",
    tableType: "text",
    values: ["In programme", "Post-programme"],
    active: false,
    value: null,
    queryParamKey: "status",
  },
  {
    rowTitle: "Any barriers to participation",
    key: "sessionBarriers",
    tableType: "boolean",
    active: false,
    value: null,
    queryParamKey: "userProfile.sessionBarriers",
  },
  {
    rowTitle: "Registration date",
    key: "registrationDate",
    tableType: "dateRange",
    active: false,
    value: null,
    queryParamKey: "registrationDate",
  },
  {
    rowTitle: "Referral clinic",
    key: "clinicName",
    tableType: "text",
    values: ["Clinic 1", "Clinic 2"],
    active: false,
    value: null,
    queryParamKey: "referralCode.clinic.fullName",
  },
  {
    rowTitle: "Referral code",
    key: "referralCode",
    tableType: "textfield",
    value: null,
    queryParamKey: "referralCode.code",
  },
  {
    rowTitle: "Region",
    key: "region",
    tableType: "text",
    values: ["North West", "London"],
    active: false,
    value: null,
    queryParamKey: "referralCode.clinic.region.name",
  },
  {
    rowTitle: "Age",
    key: "age",
    tableType: "intRange",
    active: false,
    value: null,
    queryParamKey: "userProfile.dateOfBirth",
  },
  {
    rowTitle: "City/town",
    key: "city",
    tableType: "text",
    values: ["London", "Birmingham", "Southampton"],
    active: false,
    value: null,
    queryParamKey: "userProfile.address.townOrCity",
  },
  {
    rowTitle: "Gender",
    key: "gender",
    tableType: "text",
    values: [
      "Male",
      "Female",
      "Non-binary",
      "Transgender",
      "Intersex",
      "Prefer not to say",
    ],
    active: false,
    value: null,
    queryParamKey: "userProfile.gender",
  },
  {
    rowTitle: "Ethnicity",
    key: "ethnicity",
    tableType: "text",
    values: ["asian", "caucasian"],
    active: false,
    value: null,
    queryParamKey: "userProfile.ethnicity",
  },
  {
    rowTitle: "Felt ill with COVID?",
    key: "dateCovidInfection",
    tableType: "dateRange",
    active: false,
    value: null,
    queryParamKey: "userProfile.covidInfectionDate",
  },
  {
    rowTitle: "Admitted to hospital?",
    key: "admittedToHospital",
    tableType: "boolean",
    active: false,
    value: null,
    queryParamKey: "userProfile.hospitalAdmitted",
  },
  {
    rowTitle: "Session availability",
    key: "sessionAvailability",
    tableType: "text",
    values: ["morning", "lunch time", "afternoon", "evening"],
    active: false,
    value: null,
    queryParamKey:
      "userProfile.morningSessionAvailability OR userProfile.lunchtimeSessionAvailability OR userProfile.afternoonSessionAvailabilityORuserProfile.eveningSessionAvailability",
  },
  {
    rowTitle: "First language - English",
    key: "englishFirstLanguage",
    tableType: "boolean",
    active: false,
    value: null,
    queryParamKey: "userProfile.englishAsFirstLanguage",
  },
  {
    rowTitle: "Twilight sessions attended",
    key: "twilightSession",
    tableType: "boolean",
    active: false,
    value: null,
    queryParamKey: "userProfile.twilightSession",
  },
];

const getAllUsers = async () => {
  return api
    .getAPI(endpoints.getUsers)
    .then((response) => {
      return response.data;
    })
    .then((res) => res);
};

const getAllUsersDashboard = async () => {
  return api
    .getAPI(endpoints.getUsersDashboard)
    .then((response) => {
      return response.data;
    })
    .then((res) => res);
};

const getAllUsersMocked = async () => {
  return dbJson;
};

const getAllUsersHeaders = () => {
  return dataTableHeaders2;
};

const getUser = async (id) => {
  const headers = {
    Authorization: `Bearer ${sessionStorage.getItem("auth.accessToken")}`,
  };

  return fetch(`${environment.enoBackendBasePath}/users/${id}`, {
    headers,
  })
    .then((response) => {
      return response.json();
    })
    .then((res) => res);
};

const getUserFormHeaders = () => {
  return dataLabels;
};

const getUsersFilters = () => {
  return usersFilters;
};

export {
  getAllUsers,
  getAllUsersMocked,
  getUser,
  getAllUsersHeaders,
  getUserFormHeaders,
  getUsersFilters,
  getAllUsersDashboard,
};
