export const genderOptions = [
  {
    value: "MALE",
    label: "Male",
  },
  {
    value: "FEMALE",
    label: "Female",
  },
  {
    value: "NON_BINARY",
    label: "Non-Binary",
  },
  {
    value: "TRANSGENDER",
    label: "Transgender",
  },
  {
    value: "INTERSEX",
    label: "Intersex",
  },
  {
    value: "PREFER_NOT_TO_SAY",
    label: "Prefer not to say",
  },
];

export const pronounsOptions = [
  {
    value: "SHE_HER",
    label: "She/Her",
  },
  {
    value: "HE_HIM",
    label: "He/Him",
  },
  {
    value: "THEY_THEM",
    label: "They/Them",
  },
  {
    value: "OTHER",
    label: "other",
  },
];

export const ethnicityOptions = [
  {
    value: "ASIAN_OR_ASIAN_BRITISH",
    label: "Asian or Asian British",
  },
  {
    value: "BLACK_OR_BLACK_BRITISH",
    label: "Black or Black British",
  },
  {
    value: "MIXED",
    label: "Mixed",
  },
  {
    value: "MULTIPLE_ETHNIC_BACKGROUND",
    label: "Multiple ethnic background",
  },
  {
    value: "WHITE_BRITISH",
    label: "White British",
  },
  {
    value: "ANY_OTHER_WHITE_BACKGROUND",
    label: "Any other White background",
  },
  {
    value: "OTHER",
    label: "Other",
  },
  {
    value: "PREFER_NOT_TO_SAY",
    label: "Prefer not to say",
  },
];
