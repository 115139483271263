import React, { useState } from "react";
import { Button, styled } from "@material-ui/core";
import { HorizontalSplit, TextFields } from "@material-ui/icons";
import MUIRichTextEditor from "mui-rte";
import PropTypes from "prop-types";
import enoBreatheTheme from "../theme/enoBreatheTheme";

const HorizontalRule = () => {
  return <hr />;
};

// eslint-disable-next-line react/prop-types
const Subtitle = ({ children }) => {
  return <h3>{children}</h3>;
};

const RteEditButton = styled(Button)({
  marginBlockEnd: enoBreatheTheme.spacing(3),
});

const draftEditorProps = {
  blockStyleFn: (contentBlock) => {
    // eslint-disable-next-line react/destructuring-assignment
    const type = contentBlock.getType();
    if (type === "header-two") {
      return "MuiTypography-root MuiTypography-h2 MuiTypography-gutterBottom";
    }
    return null;
  },
};

const EditableSection = ({ isAdmin, editableContent }) => {
  const [editMode, setEditMode] = useState(false);

  const handleEditButtonClick = () => {
    setEditMode(true);
  };

  const handleRteSave = (data) => {
    // eslint-disable-next-line no-console
    // setPageContent(data);
    setEditMode(false);
  };

  return (
    <>
      {/*      {isAdmin && !editMode && (
        <RteEditButton
          size="small"
          variant="contained"
          onClick={handleEditButtonClick}
        >
          Edit content
        </RteEditButton>
      )} */}

      <MUIRichTextEditor
        readOnly={!editMode}
        draftEditorProps={draftEditorProps}
        toolbar={editMode}
        defaultValue={editableContent}
        label="Start typing..."
        onSave={handleRteSave}
        inlineToolbar
        toolbarButtonSize="small"
        controls={[
          "title",
          "subtitle",
          "bold",
          "italic",
          "underline",
          "horizontal-line",
          "undo",
          "redo",
          "link",
          "numberList",
          "bulletList",
          "quote",
          "clear",
          "save",
        ]}
        customControls={[
          {
            name: "horizontal-line",
            icon: <HorizontalSplit />,
            type: "block",
            blockWrapper: <HorizontalRule />,
          },
          {
            name: "subtitle",
            icon: <TextFields />,
            type: "block",
            blockWrapper: <Subtitle />,
          },
        ]}
      />
    </>
  );
};

EditableSection.propTypes = {
  isAdmin: PropTypes.bool,
  editableContent: PropTypes.string.isRequired,
};

EditableSection.defaultProps = {
  isAdmin: false,
};

export default EditableSection;
