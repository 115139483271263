import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Box, Breadcrumbs, Grid, Typography } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import EditableSection from "../components/EditableSection";
import SecondaryLink from "../components/SecondaryLink";
import UserContext from "../context/UserContext";

const HowEnoBreatheHelpedMe = ({
  title,
  breadcrumbs,
  heroImage,
  editableContentLocations,
}) => {
  const { isAdmin } = useContext(UserContext);
  return (
    <>
      {heroImage && (
        <Box mb={2} mt={1} clone>
          <img className="Page-hero" src={heroImage.url} alt={heroImage.alt} />
        </Box>
      )}

      {/* TODO: Integrate with React router? */}
      {breadcrumbs && (
        <Breadcrumbs separator="»" aria-label="breadcrumb">
          <SecondaryLink component={NavLink} color="inherit" to="/">
            ENO Breathe
          </SecondaryLink>
          <Typography variant="inherit">{title}</Typography>
        </Breadcrumbs>
      )}

      <Typography variant="h1" gutterBottom>
        {title}
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <EditableSection
            isAdmin={isAdmin}
            editableContent={editableContentLocations[0]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/yX1z_LneMAo"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Grid>
      </Grid>
      <EditableSection
        isAdmin={isAdmin}
        editableContent={editableContentLocations[1]}
      />
    </>
  );
};

HowEnoBreatheHelpedMe.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.bool,
  heroImage: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }),
  editableContentLocations: PropTypes.arrayOf(PropTypes.string).isRequired,
};

HowEnoBreatheHelpedMe.defaultProps = {
  breadcrumbs: true,
  heroImage: null,
};

export default HowEnoBreatheHelpedMe;
