import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { object } from "prop-types";
import {
  getAllGroupParticipantsHeaders,
  getGroupFormHeaders,
} from "../../../services/groupsService";
import useLoadDataFromAPI from "../../../utils/useLoadDataFromAPI";
import endpoints from "../../../utils/endpoints";
import ErrorPanel from "../../../components/ErrorPanel";
import ColorButton from "../../../components/ColorButton";
import EditDetailsForm from "../../../components/admin/EditDetailsForm";
import AdminTable from "../../../components/admin/AdminTable";
import api from "../../../utils/api";
import { SnackbarTypes } from "../../../components/CustomSnackbar";
import NotificationSnackbarContext from "../../../context/NotificationSnackbarContext";
import NeutralButton from "../../../components/NeutralButton";

const GroupDetails = () => {
  const { groupId } = useParams();
  const [formLabels, setFormLabels] = useState([]);
  const [userGroupHeaders, setUserGroupHeaders] = useState([]);
  const { notification, setNotification } = useContext(
    NotificationSnackbarContext
  );

  const history = useHistory();

  const { data: group, loading, statusCode } = useLoadDataFromAPI({
    url: `${endpoints.getGroups}/${groupId}`,
    method: "GET",
  });

  const { data: users, loadingUsers, statusCodeUsers } = useLoadDataFromAPI({
    url: `${endpoints.getGroups}/${groupId}/members`,
    method: "GET",
  });
  const {
    data: emailAddress,
    loadingEmailAddress,
    statusEmailAddress,
  } = useLoadDataFromAPI({
    url: `${endpoints.getMembersEmailAddresses}/${groupId}/members/email-addresses`,
    method: "GET",
  });

  const handleSaveToDb = (newData) => {
    return api
      .patchApi(
        `${endpoints.getGroups}/${groupId}/groupDetails`,
        null,
        JSON.stringify(newData)
      )
      .then(() => {
        setNotification({
          ...notification,
          message: "The user was successfully updated",
          color: SnackbarTypes.SUCCESS,
          key: new Date(),
        });
      })
      .catch(() => {
        setNotification({
          ...notification,
          message: "There was an error on updating the group",
          color: SnackbarTypes.ERROR,
          key: new Date(),
        });
      });
  };

  useEffect(() => {
    const headers = getGroupFormHeaders();
    setFormLabels(headers);

    const userGroupHeadersGet = getAllGroupParticipantsHeaders();
    setUserGroupHeaders(userGroupHeadersGet);
  }, [groupId]);

  const handleActions = (row) => {
    const actions = [
      {
        text: "View",
        query: row,
        handleClick: () => history.push(`/admin/users/${row.id}`),
        enable: true,
      },
    ];
    return getActionsTemplate(actions);
  };

  const handleEmailCopy = () => {
    navigator.clipboard.writeText(emailAddress.join(";"));
  };

  const getActionsTemplate = (actions) => {
    return actions.map((action) => (
      <ColorButton
        key={action.text}
        size="small"
        query={action.query}
        disabled={!action.enable}
        onClick={action.handleClick}
      >
        {action.text}
      </ColorButton>
    ));
  };

  if (loading || loadingUsers || loadingEmailAddress) {
    return <>Loading ... </>;
  }
  if (
    statusCode >= 400 ||
    statusCodeUsers >= 400 ||
    statusEmailAddress >= 400
  ) {
    return (
      <ErrorPanel heading="Error loading data">
        The page could not be loaded. Please try again later ot contact the
        administrator
      </ErrorPanel>
    );
  }

  return (
    <>
      <NeutralButton
        variant="outlined"
        size="small"
        onClick={() => {
          history.push(`/admin/groups/${groupId}/surveys`);
        }}
      >
        View group surveys
      </NeutralButton>
      <EditDetailsForm
        headers={formLabels}
        data={group}
        handleSave={handleSaveToDb}
        forEdit
      />
      <h2>Group participants</h2>
      <NeutralButton
        variant="outlined"
        size="small"
        disabled={!emailAddress?.length}
        onClick={handleEmailCopy}
      >
        Copy Email Addresses
      </NeutralButton>
      <Typography variant="body2">
        *Copy the email addresses of participants who have not left or been
        rejected
      </Typography>
      <AdminTable
        dataColumns={userGroupHeaders}
        dataRows={users}
        actionsList={handleActions}
      />
    </>
  );
};

export default GroupDetails;
