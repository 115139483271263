import { Grid, makeStyles, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import DatePicker from "./DatePicker";

const useStyles = makeStyles((enoBreatheTheme) => ({
  // ageContainer: {
  //   margin: "auto",
  // },
  ageInput: {
    ":disabled": {
      backgroundColor: enoBreatheTheme.palette.background.paper,
      color: enoBreatheTheme.palette.common.black,
    },
  },
}));
const BirthDate = ({
  inputValue,
  name,
  label,
  helperText,
  error,
  handleChangeValue,
  onBlur,
  disabled,
}) => {
  const [age, setAge] = React.useState(0);
  const classes = useStyles();

  useEffect(() => {
    if (inputValue) {
      setAgeLabel(new Date(inputValue));
    }
  });

  const handleDateChange = (value) => {
    setAgeLabel(new Date(value));
    handleChangeValue(value);
  };

  const setAgeLabel = (datepickerValue) => {
    const yearsDiff = calculateYearsDiff(new Date(), datepickerValue);
    setAge(yearsDiff);
  };

  const calculateYearsDiff = (currentDate, selectedValue) => {
    let yearsDiff = currentDate.getFullYear() - selectedValue.getFullYear();
    const monthsDiff = currentDate.getMonth() - selectedValue.getMonth();
    const daysDiff = currentDate.getDate() - selectedValue.getDate();
    if (monthsDiff < 0 || (monthsDiff === 0 && daysDiff < 0)) {
      yearsDiff -= 1;
    }
    return yearsDiff;
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12} lg={6} md={6}>
          <DatePicker
            name={name}
            label={label}
            onBlur={onBlur}
            value={inputValue}
            onChange={(value) => handleDateChange(value)}
            helperText={helperText}
            disabled={disabled}
            error={error}
          />
        </Grid>
        <Grid
          item
          sm={12}
          lg={6}
          md={6}
          xs={12}
          // spacing={1}
          // className={classes.ageContainer}
        >
          <TextField
            className={classes.ageInput}
            type="text"
            label="Age"
            variant="outlined"
            color="secondary"
            disabled
            value={age || "-"}
          />
        </Grid>
      </Grid>
    </>
  );
};
BirthDate.propTypes = {
  inputValue: PropTypes.instanceOf(Date),
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  handleChangeValue: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
};

BirthDate.defaultProps = {
  disabled: false,
  inputValue: null,
  helperText: null,
  error: false,
  onBlur: null,
};
export default BirthDate;
