import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Breadcrumbs, Typography, Grid } from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import UserContext from "../context/UserContext";
import EditableSection from "../components/EditableSection";
import SecondaryLink from "../components/SecondaryLink";

const MeetTheTeam = ({
  title,
  breadcrumbs,
  heroImage,
  editableContentLocations,
}) => {
  const [pageContent, setPageContent] = useState(
    '{ "blocks": [], "entityMap": {} }'
  );
  const { isAdmin } = useContext(UserContext);

  const useStyles = makeStyles({
    imageContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      height: "300px",
      alignItems: "center",
    },
    imageContainerRight: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      height: "300px",
      alignItems: "center",
    },
    img: {
      height: "100%",
      width: "auto",
    },
    editable: {
      // maxHeight: "500px",
      overflowY: "auto",
    },
    editableimg: {
      maxHeight: "300px",
      overflowY: "auto",
    },
    rowContainer: {
      paddingTop: "4rem",
    },
    image: {
      height: "100%",
      paddingTop: "1rem",
      paddingBottom: "1rem",
    },
    imageEdit: {
      backgroundPosition: "center",
      alignItems: "center",
      height: "100%",
      paddingTop: "1rem",
      paddingBottom: "1rem",
    },
  });

  const classes = useStyles();

  const NameImage = styled(Typography)({
    textAlign: "center",
  });

  useEffect(() => {
    // TODO: replace with an async call to API endpoint
    setPageContent(editableContentLocations);
  }, [editableContentLocations]);

  return (
    <>
      {heroImage && (
        <Box mb={2} mt={1} clone>
          <img className="Page-hero" src={heroImage.url} alt={heroImage.alt} />
        </Box>
      )}

      {/* TODO: Integrate with React router? */}
      {breadcrumbs && (
        <Breadcrumbs separator="»" aria-label="breadcrumb">
          <SecondaryLink component={NavLink} color="inherit" to="/">
            ENO Breathe
          </SecondaryLink>
          <Typography variant="inherit">{title}</Typography>
        </Breadcrumbs>
      )}
      <Typography variant="h1" gutterBottom>
        {title}
      </Typography>

      <Grid container spacing={0} className={classes.rowContainer}>
        <Grid item xs={12} md={12} className={classes.editable}>
          <EditableSection
            isAdmin={isAdmin}
            editableContent={editableContentLocations[0]}
          />
        </Grid>
      </Grid>

      <Grid container spacing={0} className={classes.rowContainer}>
        <Grid item xs={12} md={12} className={classes.editable}>
          <EditableSection
            isAdmin={isAdmin}
            editableContent={editableContentLocations[1]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={0} className={classes.rowContainer}>
        <Grid item xs={12} md={12} className={classes.editable}>
          <EditableSection
            isAdmin={isAdmin}
            editableContent={editableContentLocations[4]}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.rowContainer}>
        <Grid item xs={12} md={6} className={classes.editableimg}>
          <EditableSection
            isAdmin={isAdmin}
            editableContent={editableContentLocations[2]}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.imageContainerRight}>
          <div className={classes.image}>
            <img
              src="https://enostoragestatic.blob.core.windows.net/static/Suzi_Zumpe.jpg"
              alt="SuziZumpe"
              title="Suzi Zumpe"
              frameBorder="0"
              className={(classes.imageRight, classes.img)}
            />
            <NameImage>Suzi Zumpe</NameImage>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.rowContainer}>
        <Grid item xs={12} md={6} className={classes.editableimg}>
          <EditableSection
            isAdmin={isAdmin}
            editableContent={editableContentLocations[6]}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.imageContainerRight}>
          <div className={classes.image}>
            <img
              src="https://enostoragestatic.blob.core.windows.net/static/Lea_Cornthwaite.jpg"
              alt="LeaCornthwaite "
              title="Lea Cornthwaite"
              frameBorder="0"
              className={(classes.imageRight, classes.img)}
            />
            <NameImage>Lea Cornthwaite</NameImage>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.rowContainer}>
        <Grid item xs={12} md={6} className={classes.editableimg}>
          <EditableSection
            isAdmin={isAdmin}
            editableContent={editableContentLocations[7]}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.imageContainerRight}>
          <div className={classes.image}>
            <img
              src="https://enostoragestatic.blob.core.windows.net/static/Jonathan_Ainscough.jpg"
              alt="JonathanAinscough"
              title="Jonathan Ainscough"
              frameBorder="0"
              className={(classes.imageRight, classes.img)}
            />
            <NameImage>Jonathan Ainscough</NameImage>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.rowContainer}>
        <Grid item xs={12} md={6} className={classes.imageContainer}>
          <div className={classes.image}>
            <img
              src="https://enostoragestatic.blob.core.windows.net/static/Robert_Gildon.jpg"
              alt="RobertGildon"
              title="Robert Gildon"
              frameBorder="0"
              className={(classes.imageLeft, classes.img)}
            />
            <NameImage>Robert Gildon</NameImage>
          </div>
        </Grid>
        <Grid item xs={12} md={6} className={classes.editableimg}>
          <EditableSection
            isAdmin={isAdmin}
            editableContent={editableContentLocations[8]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.rowContainer}>
        <Grid item xs={12} md={6} className={classes.imageContainer}>
          <div className={classes.image}>
            <img
              src="https://enostoragestatic.blob.core.windows.net/static/Abigail_Kelly.jpg"
              alt="AbigailKelly"
              title="Abigail Kelly"
              frameBorder="0"
              className={(classes.imageLeft, classes.img)}
            />
            <NameImage>Abigail Kelly</NameImage>
          </div>
        </Grid>
        <Grid item xs={12} md={6} className={classes.editableimg}>
          <EditableSection
            isAdmin={isAdmin}
            editableContent={editableContentLocations[9]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.rowContainer}>
        <Grid item xs={12} md={6} className={classes.imageContainer}>
          <div className={classes.image}>
            <img
              src="https://enostoragestatic.blob.core.windows.net/static/Nicola_Wydenbach.jpg"
              alt="NicolaWydenbach"
              title="Nicola Wydenbach"
              frameBorder="0"
              className={(classes.imageLeft, classes.img)}
            />
            <NameImage>Nicola Wydenbach</NameImage>
          </div>
        </Grid>
        <Grid item xs={12} md={6} className={classes.editableimg}>
          <EditableSection
            isAdmin={isAdmin}
            editableContent={editableContentLocations[10]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={0} className={classes.rowContainer}>
        <Grid item xs={12} md={12} className={classes.editable}>
          <EditableSection
            isAdmin={isAdmin}
            editableContent={editableContentLocations[5]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.rowContainer}>
        <Grid item xs={12} md={6} className={classes.editableimg}>
          <EditableSection
            isAdmin={isAdmin}
            editableContent={editableContentLocations[11]}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.imageContainerRight}>
          <div className={classes.image}>
            <img
              src="https://enostoragestatic.blob.core.windows.net/static/Jo_Carey.jpg"
              alt="JoCarey"
              title="Jo Carey"
              frameBorder="0"
              className={(classes.imageRight, classes.img)}
            />
            <NameImage>Jo Carey</NameImage>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.rowContainer}>
        <Grid item xs={12} md={6} className={classes.editableimg}>
          <EditableSection
            isAdmin={isAdmin}
            editableContent={editableContentLocations[12]}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.imageContainerRight}>
          <div className={classes.image}>
            <img
              src="https://enostoragestatic.blob.core.windows.net/static/Amy_Kearsley.jpg"
              alt="AmyKearsley"
              title="Amy Kearsley"
              frameBorder="0"
              className={(classes.imageRight, classes.img)}
            />
            <NameImage>Amy Kearsley</NameImage>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.rowContainer}>
        <Grid item xs={12} md={6} className={classes.editableimg}>
          <EditableSection
            isAdmin={isAdmin}
            editableContent={editableContentLocations[13]}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.imageContainerRight}>
          <div className={classes.image}>
            <img
              src="https://enostoragestatic.blob.core.windows.net/static/Laura_Wyatt_OKeeffe.jpg"
              alt="LauraO’Keefe"
              title="Laura O’Keefe"
              frameBorder="0"
              className={(classes.imageRight, classes.img)}
            />
            <NameImage>Laura O’Keefe</NameImage>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.rowContainer}>
        <Grid item xs={12} md={6} className={classes.imageContainer}>
          <div className={classes.image}>
            <img
              src="https://enostoragestatic.blob.core.windows.net/static/Amy_Hollinrake.jpg"
              alt="AmyHollinrake"
              title="Amy Hollinrake"
              frameBorder="0"
              className={(classes.imageLeft, classes.img)}
            />
            <NameImage>Amy Hollinrake</NameImage>
          </div>
        </Grid>
        <Grid item xs={12} md={6} className={classes.editableimg}>
          <EditableSection
            isAdmin={isAdmin}
            editableContent={editableContentLocations[14]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.rowContainer}>
        <Grid item xs={12} md={6} className={classes.imageContainer}>
          <div className={classes.image}>
            <img
              src="https://enostoragestatic.blob.core.windows.net/static/Vanessa_Havell.jpg"
              alt="VanessaHavell"
              title="Vanessa Havell"
              frameBorder="0"
              className={(classes.imageLeft, classes.img)}
            />
            <NameImage>Vanessa Havell</NameImage>
          </div>
        </Grid>
        <Grid item xs={12} md={6} className={classes.editableimg}>
          <EditableSection
            isAdmin={isAdmin}
            editableContent={editableContentLocations[15]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.rowContainer}>
        <Grid item xs={12} md={6} className={classes.imageContainer}>
          <div className={classes.image}>
            <img
              src="https://enostoragestatic.blob.core.windows.net/static/Jess_Croll-Knight.jpg"
              alt="JessCroll-Knight"
              title="Jess Croll-Knight"
              frameBorder="0"
              className={(classes.imageLeft, classes.img)}
            />
            <NameImage>Jess Croll-Knight</NameImage>
          </div>
        </Grid>
        <Grid item xs={12} md={6} className={classes.editableimg}>
          <EditableSection
            isAdmin={isAdmin}
            editableContent={editableContentLocations[16]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.rowContainer}>
        <Grid item xs={12} md={6} className={classes.editableimg}>
          <EditableSection
            isAdmin={isAdmin}
            editableContent={editableContentLocations[17]}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.imageContainerRight}>
          <div className={classes.image}>
            <img
              src="https://enostoragestatic.blob.core.windows.net/static/Caroline_Foulkes.jpg"
              alt="CarolineFoulkes"
              title="Caroline Foulkes"
              frameBorder="0"
              className={(classes.imageRight, classes.img)}
            />
            <NameImage>Caroline Foulkes</NameImage>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.rowContainer}>
        <Grid item xs={12} md={6} className={classes.editableimg}>
          <EditableSection
            isAdmin={isAdmin}
            editableContent={editableContentLocations[18]}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.imageContainerRight}>
          <div className={classes.image}>
            <img
              src="https://enostoragestatic.blob.core.windows.net/static/Eleanor_Wood .jpg"
              alt="EleanorWood"
              title="Eleanor Wood"
              frameBorder="0"
              className={(classes.imageRight, classes.img)}
            />
            <NameImage>Eleanor Wood</NameImage>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.rowContainer}>
        <Grid item xs={12} md={6} className={classes.editableimg}>
          <EditableSection
            isAdmin={isAdmin}
            editableContent={editableContentLocations[19]}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.imageContainerRight}>
          <div className={classes.image}>
            <img
              src="https://enostoragestatic.blob.core.windows.net/static/Naomi_Cull.jpg"
              alt="NaomiCull"
              title="Naomi Cull"
              frameBorder="0"
              className={(classes.imageRight, classes.img)}
            />
            <NameImage>Naomi Cull</NameImage>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

MeetTheTeam.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.bool,
  heroImage: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }),
  editableContentLocations: PropTypes.arrayOf(PropTypes.string).isRequired,
};

MeetTheTeam.defaultProps = {
  breadcrumbs: true,
  heroImage: null,
};

export default MeetTheTeam;
