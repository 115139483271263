/* eslint-disable react/jsx-props-no-spreading */

import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import UserContext from "../context/UserContext";

const PrivateRoute = ({ component: Component, allowedRoles, ...rest }) => {
  const { roles: userRoles } = useContext(UserContext);
  const [isAuthorized, setIsAuthorized] = useState();

  useEffect(() => {
    userRoles
      ? setIsAuthorized(
          allowedRoles.some((allowedRole) => userRoles.includes(allowedRole))
        )
      : setIsAuthorized(false);
  }, [allowedRoles, userRoles]);

  return (
    <Route
      {...rest}
      render={() => {
        // isAuthorized can have 3 possible states: undefined, true, and false.
        // On mount, until the roles are checked, it is undefined.
        // Nothing should happen until the role check has a result.
        // We cannot use isAuthorized && ... here, because it will create a false
        // negative when isAuthorized is undefined, and will redirect every time.
        if (isAuthorized === true) {
          return <Component />;
        }

        if (isAuthorized === false) {
          return <Redirect to={{ pathname: "/login" }} />;
        }

        return null;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PrivateRoute;
