/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { useParams } from "react-router-dom";
import { Box, Tab, Tabs } from "@material-ui/core";
import TabPanel from "../../../utils/TabPanel";
import useLoadDataFromAPI from "../../../utils/useLoadDataFromAPI";
import endpoints from "../../../utils/endpoints";
import ErrorPanel from "../../../components/ErrorPanel";
import GroupSurvey from "./GroupSurvey";

const GroupSurveys = () => {
  const { groupId } = useParams();
  const [tabIndex, setTabIndex] = React.useState(0);

  const {
    data: surveyOne,
    laoding: loadingSurveyOne,
    statusCode: statusCodeSurveyOne,
  } = useLoadDataFromAPI({
    url: endpoints.getGroupsSurveys
      .replace("{groupId}", groupId)
      .replace("{surveyNumber}", 1),
    method: "GET",
  });

  const {
    data: surveyTwo,
    loading: loadingSurveyTwo,
    statusCode: statusCodeSurveyTwo,
  } = useLoadDataFromAPI({
    url: endpoints.getGroupsSurveys
      .replace("{groupId}", groupId)
      .replace("{surveyNumber}", 2),
    method: "GET",
  });

  const {
    data: surveyThree,
    loading: loadingSurveyThree,
    statusCode: statusCodeSurveyThree,
  } = useLoadDataFromAPI({
    url: endpoints.getGroupsSurveys
      .replace("{groupId}", groupId)
      .replace("{surveyNumber}", 3),
    method: "GET",
  });

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  return (
    <>
      <h1>Surveys for group {surveyOne ? surveyOne[0].groupNumber : " "}</h1>
      {((loadingSurveyOne && !surveyOne) ||
        (loadingSurveyTwo && !surveyTwo) ||
        (loadingSurveyThree && !surveyThree)) && <div>Loading...</div>}

      {(surveyOne || surveyTwo || surveyThree) && (
        <>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabIndex}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Survey One" {...a11yProps(0)} />
              <Tab label="Survey Two" {...a11yProps(1)} />
              <Tab label="Survey Three" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={tabIndex} index={0}>
            {statusCodeSurveyOne >= 400 ? (
              <ErrorPanel heading="Error loading data">
                The page could not be loaded. Please try again later or contact
                the administrator
              </ErrorPanel>
            ) : (
              <GroupSurvey
                surveyValues={surveyOne}
                id={1}
                name={surveyOne ? surveyOne[0].groupNumber : groupId}
              />
            )}
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            {statusCodeSurveyTwo >= 400 ? (
              <ErrorPanel heading="Error loading data">
                The page could not be loaded. Please try again later or contact
                the administrator
              </ErrorPanel>
            ) : (
              <GroupSurvey
                surveyValues={surveyTwo}
                id={2}
                name={surveyOne ? surveyOne[0].groupNumber : groupId}
              />
            )}
          </TabPanel>
          <TabPanel value={tabIndex} index={2}>
            {statusCodeSurveyThree >= 400 ? (
              <ErrorPanel heading="Error loading data">
                The page could not be loaded. Please try again later or contact
                the administrator
              </ErrorPanel>
            ) : (
              <GroupSurvey surveyValues={surveyThree} id={3} />
            )}
          </TabPanel>
        </>
      )}
    </>
  );
};

export default GroupSurveys;
