import React, { useContext } from "react";
import { Box, Breadcrumbs, Link, Typography } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import UserContext from "../context/UserContext";
import SecondaryLink from "../components/SecondaryLink";
import EditableSection from "../components/EditableSection";
import scrollToElement from "../utils/scrollToElement";

const ContactUs = ({
  title,
  breadcrumbs,
  heroImage,
  editableContentLocations,
}) => {
  const { isAdmin } = useContext(UserContext);

  return (
    <>
      {heroImage && (
        <Box mb={2} mt={1} clone>
          <img className="Page-hero" src={heroImage.url} alt={heroImage.alt} />
        </Box>
      )}

      {/* TODO: Integrate with React router? */}
      {breadcrumbs && (
        <Breadcrumbs separator="»" aria-label="breadcrumb">
          <SecondaryLink component={NavLink} color="inherit" to="/">
            ENO Breathe
          </SecondaryLink>
          <Typography variant="inherit">{title}</Typography>
        </Breadcrumbs>
      )}

      <Typography variant="h1" gutterBottom>
        {title}
      </Typography>

      <Typography variant="inherit" component="div" gutterBottom>
        If you are a patient who had COVID-19 and are still suffering from
        breathlessness and its associated anxiety and symptoms, please get in
        touch with your local specialist long COVID clinic to be referred onto
        the programme.{" "}
        <Link
          // href={`#${heading.key}`}
          onClick={() => scrollToElement("covid")}
        >
          View a list of all current participating specialist long COVID clinics
          here.
        </Link>
      </Typography>
      <Typography variant="inherit" component="div" gutterBottom>
        If you are a Healthcare professional and would like to be able to refer
        your patients onto the programme, please get in touch with{" "}
        <Link href="mailto:info.breathe@eno.org">info.breathe@eno.org</Link>
      </Typography>
      <Typography variant="inherit" component="div" gutterBottom>
        If you are a social prescribing link worker, your patient will need to
        have been medically assessed by their local long COVID clinic to be
        referred.
      </Typography>
      <Typography variant="inherit" component="div" gutterBottom>
        If you would like to know more about the programme and your question has
        not been answered on our FAQ page <Link href="/faqs">here</Link>, please
        get in touch with{" "}
        <Link href="mailto:info.breathe@eno.org">info.breathe@eno.org</Link>
      </Typography>
      <Typography variant="inherit" component="div" gutterBottom>
        Additional help for people recovering from COVID-19 can be found at{" "}
        <Link target="_blank" href="http://yourcovidrecovery.nhs.uk">
          yourcovidrecovery.nhs.uk
        </Link>
      </Typography>

      <Box id="covid" mt={10} data-offset-key="covid-0-0">
        <EditableSection
          isAdmin={isAdmin}
          editableContent={editableContentLocations[0]}
        />
      </Box>
      <Box id="covid" mt={10} data-offset-key="covid-0-0">
        <EditableSection
          isAdmin={isAdmin}
          editableContent={editableContentLocations[1]}
        />
      </Box>
    </>
  );
};

ContactUs.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.bool,
  heroImage: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }),
  editableContentLocations: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ContactUs.defaultProps = {
  breadcrumbs: true,
  heroImage: null,
};
export default ContactUs;
