const scrollToElement = (key) => {
  const elementBoundingClientRectangle = document
    .querySelector(`[data-offset-key='${key}-0-0']`)
    .getBoundingClientRect();
  const bodyBoundingClientRectangle = document.body.getBoundingClientRect();
  const headerBoundingClientRectangle = document
    .querySelector("header")
    .getBoundingClientRect();

  window.scrollTo({
    top:
      elementBoundingClientRectangle.top -
      bodyBoundingClientRectangle.top -
      headerBoundingClientRectangle.height -
      30,
    behavior: "smooth",
  });
};

export default scrollToElement;
