import React from "react";
import api from "./api";

const useLoadDataFromAPI = ({
  url,
  queryParams,
  forceReload,
  setForceReload,
}) => {
  const [statusCode, setStatusCode] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [apiData, setApiData] = React.useState();

  React.useEffect(() => {
    url && createRequest();
  }, [url]);

  React.useEffect(() => {
    if (forceReload) {
      createRequest();
      setForceReload(false);
    }
  }, [forceReload]);

  const createRequest = () => {
    setLoading(true);
    api
      .getAPI(url, null, queryParams)
      .then((data) => {
        setStatusCode(data.status);
        if (data.status < 400) {
          return data.data;
        }
        return null;
      })
      .then((data) => {
        setApiData(data);
      })
      .finally(() => setLoading(false));
  };
  return { data: apiData, statusCode, loading };
};

export default useLoadDataFromAPI;
