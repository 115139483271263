import React, { useState } from "react";
import { Grid, Box } from "@material-ui/core";
import UploadFileComponent from "./UploadFileComponent";
import ClinicsList from "./ClinicsList";
import environment from "../../../utils/environment";
import endpoints from "../../../utils/endpoints";

const ClinicsAndReferralPage = () => {
  const [render, setRender] = useState(false);

  const prepareForRender = (value) => {
    setRender(value);
  };
  return (
    <>
      <Grid item xs={12}>
        <UploadFileComponent
          prepareForRender={prepareForRender}
          title="Clinics and regions"
          uploadUrl={`${environment.enoBackendBasePath}${endpoints.uploadClinics}`}
          inputId="clinics"
          uploadButtonLabel="upload clinics"
        />
        <Box mt={3}>
          <ClinicsList render={render} />
        </Box>
      </Grid>
    </>
  );
};

export default ClinicsAndReferralPage;
