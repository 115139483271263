import Keycloak from "keycloak-js";
import environment from "./utils/environment";

const keycloak = new Keycloak({
  url: `${environment.keycloakBasePath}/auth`,
  realm: "Eno",
  clientId: "React-app",
});

export default keycloak;
