import { Box, useMediaQuery } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import React from "react";
import NeutralButton from "./NeutralButton";
import SecondaryButton from "./SecondaryButton";
import { SMALL_SCREEN_MEDIA_CONDITION } from "../responsive/media-conditions";

const NotLoggedInHeaderContent = () => {
  const isSmallScreen = useMediaQuery(SMALL_SCREEN_MEDIA_CONDITION);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: isSmallScreen ? "right" : "center",
          flexDirection: isSmallScreen ? "column" : "row",
          alignItems: isSmallScreen ? "flex-end" : "unset",
          width: "100%",
        }}
      >
        <Box mr={1} marginRight={0} width={isSmallScreen ? "120px" : "auto"}>
          <NavLink
            to="/signup"
            style={{
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <NeutralButton variant="outlined" size="large" fullWidth>
              Sign up
            </NeutralButton>
          </NavLink>
        </Box>
        <Box ml={1} width={isSmallScreen ? "120px" : "auto"}>
          <NavLink
            to="/login"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <SecondaryButton variant="outlined" size="large" fullWidth>
              Sign in
            </SecondaryButton>
          </NavLink>
        </Box>
      </Box>
    </>
  );
};

export default NotLoggedInHeaderContent;
