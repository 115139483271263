import React from "react";
import PropTypes from "prop-types";
import { Refresh, ArrowBack } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const doAction = (action) => {
  switch (action) {
    case "back": {
      window.history.go(-1);
      return;
    }

    default: {
      window.location.reload();
    }
  }
};
const useStyles = makeStyles({
  shout: { fontSize: 100 },
  heading: { fontSize: 32 },
  content: { fontSize: 19 },
  // section: { maxWidth: 360 },
  actionButton: { marginTop: 50 },
});
const ErrorPanel = ({ shout, heading, children, action }) => {
  const classes = useStyles();
  return (
    <section className={classes.section}>
      <div className={classes.shout}>{shout}</div>
      <div className={classes.heading}>{heading}</div>
      <div className={classes.content}>{children}</div>
      {action !== "none" && (
        <Button
          className={classes.actionButton}
          variant="contained"
          startIcon={action === "back" ? <ArrowBack /> : <Refresh />}
          onClick={() => doAction(action)}
        >
          {action === "back" ? "Back" : "Refresh"}
        </Button>
      )}
    </section>
  );
};

ErrorPanel.propTypes = {
  shout: PropTypes.string,
  heading: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  action: PropTypes.oneOf(["back", "reload", "none"]),
};

ErrorPanel.defaultProps = {
  shout: "Oops!",
  action: "none",
};

export default ErrorPanel;
