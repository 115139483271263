import React from "react";
import {
  Box,
  IconButton,
  Typography,
  Grid,
  Link,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import {
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  Instagram as InstagramIcon,
  YouTube as YoutubeIcon,
} from "@material-ui/icons";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import ButtonNewsletter from "./ButtonNewsletter";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    backgroundColor: "white",
    width: "75px",
    marginLeft: "-4px",
  },
});

const useStyles = makeStyles(() => ({
  alignEnd: {
    justifyContent: "flex-end",
  },
  center: {
    justifyContent: "space-evenly",
  },
}));

const artsCouncilLogo = {
  url:
    "https://english-national-opera-live.s3.amazonaws.com/wp-content/uploads/2016/01/arts-logo.svg",
  alt: "Arts Council Logo",
};

const AppFooter = () => {
  const classes = useStyles();
  const appInsights = useAppInsightsContext();

  appInsights.trackMetric("Component 'AppFooter' is in use");

  return (
    <Box clone mt={10} p={10} color="common.white" bgcolor="primary.main">
      <footer>
        <Grid container spacing={5} className={classes.center}>
          <Grid item xs={12} sm={8}>
            <Box display="flex" flexDirection="column">
              <Typography variant="body1">
                ENO Breathe is a project of English National Opera.
              </Typography>
              <Typography variant="caption" gutterBottom>
                English National Opera, London Coliseum, St Martin’s Lane,
                London WC2N 4ES
              </Typography>
              <Typography variant="body1">
                <Link
                  color="inherit"
                  rel="noopener"
                  target="_blank"
                  href="https://eno.org/"
                >
                  Find out more about ENO
                </Link>
              </Typography>
              <Typography variant="body1">
                <Link
                  color="inherit"
                  rel="noopener"
                  target="_blank"
                  href="https://eno.org/support-us/donate-now/"
                >
                  Donate to ENO
                </Link>
              </Typography>
              <Typography variant="body1">
                <Link
                  color="inherit"
                  rel="noopener"
                  target="_blank"
                  href="https://eno.org/privacy-notice/"
                >
                  Privacy Notice
                </Link>
              </Typography>
              <Typography variant="body1">
                <Link
                  color="inherit"
                  rel="noopener"
                  target="_blank"
                  href="https://eno.org/cookie-notice/"
                >
                  Cookie Notice
                </Link>
              </Typography>
              <Typography variant="body1">
                <Link
                  color="inherit"
                  rel="noopener"
                  target="_blank"
                  href="https://eno.org/terms-and-conditions/"
                >
                  Terms and Conditions
                </Link>
              </Typography>
              <Box>
                <IconButton
                  color="inherit"
                  target="_blank"
                  edge="start"
                  aria-label="Facebook"
                  href="https://www.facebook.com/englishnationalopera/"
                >
                  <FacebookIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  target="_blank"
                  aria-label="Twitter"
                  href="https://twitter.com/E_N_O"
                >
                  <TwitterIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  target="_blank"
                  aria-label="Instagram"
                  href="https://www.instagram.com/englishnationalopera/"
                >
                  <InstagramIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  target="_blank"
                  aria-label="Youtube"
                  href="https://www.youtube.com/user/englishnationalopera"
                >
                  <YoutubeIcon />
                </IconButton>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box display="flex" flexDirection="column">
              <Typography variant="body1" gutterBottom>
                Sign up to the ENO’s newsletter
              </Typography>
              <ButtonNewsletter
                type="submit"
                variant="outlined"
                href="https://www.eno.org/about/sign-up-to-our-marketing-newsletter/"
                target="_blank"
                border
                size="medium"
                fullWidth
              >
                Sign up
              </ButtonNewsletter>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box display="flex" flexDirection="column">
              <Typography variant="body1">
                English National Opera is a charitable company limited by
                guarantee incorporated in England and Wales (Company number
                00426792).
              </Typography>
              <Typography variant="body1" gutterBottom>
                Charity Registered Number 257210
              </Typography>
              <Box mr={7}>
                <img src={artsCouncilLogo.url} alt={artsCouncilLogo.alt} />
              </Box>
              <Typography variant="body1">Developed with Endava</Typography>
            </Box>
          </Grid>
        </Grid>
      </footer>
    </Box>
  );
};

export default AppFooter;
