import { createMuiTheme } from "@material-ui/core";
import BrownRegularWoff2 from "../assets/fonts/Brown-Regular.woff2";
import BrownLightWoff2 from "../assets/fonts/Brown-Light.woff2";

// ENO colors
// #000000 #000 #333333 black
// #b2b2b2 #fff #ffffff white
// #515456 #75787b #909395 dark grey
// #919190 #d0d0ce #d9d9d7 light grey

const themeColours = {
  primary: "#0162B4", // #EF3340 #0162B4
  secondary: "#000000",
  neutral: "#ffffff",
  error: "#a7232c",
  darkNeutral: "#75787b", // dark grey main
  darkNeutralDark: "#515456",
  darkNeutralLight: "#909395",
  lightNeutral: "#d0d0ce", // light grey main
  lightNeutralDark: "#919190",
  lightNeutralLight: "#d9d9d7",
  text: {
    primary: "#000000",
    secondary: "#909395", // dark grey -> light
    hint: "#FFFFFF",
  },
};

const brownRegular = {
  fontFamily: "LL Brown",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
        local('LL Brown'),
        local('Brown-Regular'),
        url(${BrownRegularWoff2}) format('woff2')
    `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const brownLight = {
  fontFamily: "LL Brown",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 300,
  src: `
        local('LL Brown'),
        local('Brown-Light'),
        url(${BrownLightWoff2}) format('woff2')
    `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const spacingTheme = createMuiTheme({
  spacing: 8,
});

const enoBreatheTheme = createMuiTheme({
  palette: {
    text: {
      primary: themeColours.text.primary,
      secondary: themeColours.text.secondary,
      hint: themeColours.text.hint,
    },
    primary: {
      main: themeColours.primary,
      neutral: themeColours.neutral,
    },
    secondary: {
      // #000000 #000 #333333 black
      main: themeColours.secondary,
    },
    neutral: {
      // #b2b2b2 #fff #ffffff white
      main: themeColours.neutral,
    },
    darkNeutral: {
      // #515456 #75787b #909395 dark grey
      light: themeColours.darkNeutralLight,
      main: themeColours.darkNeutral,
      dark: themeColours.darkNeutralDark,
    },
    lightNeutral: {
      // #919190 #d0d0ce #d9d9d7 light grey
      light: themeColours.lightNeutralLight,
      main: themeColours.lightNeutral,
      dark: themeColours.lightNeutralDark,
    },
    error: {
      // #a7232c #EF3340 #f25b66 red
      main: themeColours.error,
    },
  },
  typography: {
    fontFamily: [
      brownRegular.fontFamily,
      "Gill Sans",
      "Gill Sans MT",
      "Calibri",
      "sans-serif",
    ].join(","),
    fontSize: 18,
    h1: {
      fontSize: 64,
      fontWeight: 300,
    },
    h2: {
      fontSize: 36,
      fontWeight: 300,
    },
    h3: {
      // should be 24 and allcaps to use it on the login /registration form?
      fontSize: 18,
      fontWeight: 700,
    },
    subtitle1: {
      // or h3?
      fontSize: 18,
      fontWeight: 700,
    },
    body1: {
      // for main text
      fontSize: 18,
      fontWeight: 400,
    },
    body2: {
      // for footer? - should be fontsize: 14?
      fontSize: 14,
      fontWeight: 400,
    },
    caption: {
      fontSize: 14,
      fontWeight: 400,
    },
    button: {
      fontSize: 18,
      fontWeight: 400,
    },
    error: {
      fontSize: 14,
      fontWeight: 400,
    },
  },

  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [brownRegular, brownLight],
      },
    },
    MuiBreadcrumbs: {
      root: {
        marginBlockStart: 50,
        fontSize: 14,
      },
    },
    MuiLink: {
      root: {
        fontWeight: 400,
        textDecoration: "none",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
    MuiListSubheader: {
      root: {
        position: "relative",
        color: "inherit",
        marginInlineStart: spacingTheme.spacing(3),
        "&::before": {
          content: '"•"',
          position: "absolute",
          left: -10,
        },
      },
    },
    MuiButton: {
      root: {
        marginBlock: 10,
        borderRadius: 5,
      },
    },
    MuiTextField: {
      root: {
        marginBlock: 10,
        width: "100%",
        borderRadius: 5,
        backgroundColor: "#fff",
      },
    },
    MuiDivider: {
      root: {
        marginBlock: "1rem",
      },
    },
    MuiTypography: {
      gutterBottom: {
        marginBlockEnd: spacingTheme.spacing(3),
      },
    },
    MuiFormControl: {
      fullWidth: {
        "&.odd": {
          backgroundColor: "#d9d9d7",
        },
        "& label.MuiFormLabel-root": {
          margin: 15,
        },
        "& div>div": {
          display: "flex",
          alignItems: "center",
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: "#000",
        // marginLeft: "15px",
      },
    },
    MuiCard: {
      root: {
        height: "100%",
      },
    },
    MuiSlider: {
      root: {
        marginTop: "20px",
      },
      thumb: {
        width: "24px",
        height: "24px",
        marginTop: "-12px",
      },
    },
    MuiTableCell: {
      head: {
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.5,
        // whiteSpace: "nowrap",
      },
      body: {
        fontSize: 12,
        // whiteSpace: "nowrap",
      },
    },
    MuiTabs: {
      root: {
        overflow: "visible",
        minWidth: "200px",
      },
    },
  },
});

export default enoBreatheTheme;
