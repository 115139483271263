import statuses from "../forms/constants/userStatusesConstants";

export const getStatusValueFromArrObject = (value, options) => {
  const found = options.find((option) => option.value === value);

  if (found) {
    return found.value === "IN_PROGRAMME_TBC"
      ? "Post 1-1 eligibility check"
      : found.label;
  }
  return "-";
};

export const getStatusValueFromArrString = (value, options) => {
  const found = options.find((option) => option === value);
  return found ? getStatusValueFromArrObject(found, statuses) : "-";
};
