import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Header from "../components/admin/Header";
import ClinicsAndReferralPage from "./admin/ClinicsAndReferral/ClinicsAndReferralPage";
import Dashboard from "./admin/Dashboard";
import UserDetails from "./admin/Users/UsersDetails";
import GroupsList from "./admin/Groups/GroupsList";
import SurveysList from "./admin/Surveys/SurveysList";
import GroupDetails from "./admin/Groups/GroupDetails";
import UserSurvey from "./admin/Surveys/UserSurvey";
import SurveyDetail from "./admin/Users/SurveyDetail";
import GroupSurveys from "./admin/Groups/GroupSurveys";
import CreateGroup from "./admin/Groups/CreateGroup";
import PrivateRoute from "./PrivateRoute";
import UsersList from "./admin/Users/UsersList";

const AdminPage = () => {
  return (
    <>
      <Header />
      <Switch>
        <Redirect exact from="/admin" to="/admin/dashboard" />
        <PrivateRoute
          exact
          path="/admin/dashboard"
          component={Dashboard}
          allowedRoles={["admin"]}
        />
        <PrivateRoute
          exact
          path="/admin/users"
          component={UsersList}
          allowedRoles={["admin"]}
        />
        <PrivateRoute
          exact
          path="/admin/users/:userId"
          allowedRoles={["admin"]}
        >
          <UserDetails />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/admin/groups"
          component={GroupsList}
          allowedRoles={["admin"]}
        />
        <PrivateRoute
          exact
          path="/admin/groups/create"
          component={CreateGroup}
          allowedRoles={["admin"]}
        />
        <PrivateRoute
          exact
          path="/admin/groups/:groupId"
          allowedRoles={["admin"]}
        >
          <GroupDetails />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/admin/groups/:groupId/surveys"
          component={GroupSurveys}
          allowedRoles={["admin"]}
        />
        <PrivateRoute
          exact
          path="/admin/user/:userId/surveys/:surveyId"
          component={SurveyDetail}
          allowedRoles={["admin"]}
        />
        <PrivateRoute
          exact
          path="/admin/surveys/:surveyId"
          allowedRoles={["admin"]}
        >
          <UserSurvey />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/admin/clinicsAndReferral"
          component={ClinicsAndReferralPage}
          allowedRoles={["admin"]}
        />
      </Switch>
    </>
  );
};

export default AdminPage;
