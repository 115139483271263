import React, { useState } from "react";
import { Button, Typography, Box } from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { makeStyles } from "@material-ui/core/styles";
import { useKeycloak } from "@react-keycloak/web";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  input: {
    display: "none",
  },
  iconSize: {
    fontSize: "inherit",
  },
}));

const UploadFileComponent = ({
  uploadUrl,
  inputId,
  title,
  uploadButtonLabel,
  prepareForRender,
}) => {
  const { keycloak } = useKeycloak();
  const classes = useStyles();

  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [status, setStatus] = useState(undefined);
  const [errorFile, setErrorFile] = useState(false);

  const handleUpload = (event) => {
    if (
      event.target.value.includes(".xls") ||
      event.target.value.includes(".xlsx")
    ) {
      setErrorFile(false);
      setStatus({ type: "not_sent" });
      setSelectedFile(event.target.files[0]);
      setIsSelected(true);
      if (prepareForRender) {
        prepareForRender(false);
      }
    } else {
      setErrorFile(true);
      setStatus(undefined);
      setSelectedFile();
      setIsSelected(false);
    }
  };

  const handleSend = () => {
    const data = new FormData();

    data.append("file", selectedFile);

    fetch(uploadUrl, {
      method: "POST",
      body: data,
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        if (["INTERNAL_SERVER_ERROR", "BAD_REQUEST"].includes(result.status)) {
          throw new Error(result.message);
        } else {
          setStatus({ type: "success" });
          console.log("Response:", result);
        }
      })
      .catch((error) => {
        setStatus({ type: "error", error });
        console.error("Error:", error);
      })
      .finally(() => {
        document.getElementById(inputId).value = "";
        if (prepareForRender) {
          prepareForRender(true);
        }
      });
  };

  return (
    <>
      <Typography variant="h2" gutterBottom>
        {title}
      </Typography>
      <Box mr={1} display="inline">
        <input
          accept=".xlsx"
          type="file"
          name="clinics"
          id={inputId}
          className={classes.input}
          onChange={handleUpload}
        />
        {/* eslint-disable jsx-a11y/label-has-associated-control */}
        <label htmlFor={inputId}>
          <Button
            variant="contained"
            disableElevation
            size="small"
            aria-label="upload file"
            color="primary"
            component="span"
          >
            {uploadButtonLabel}
          </Button>
        </label>
      </Box>

      <Box display="inline">
        <Button
          disabled={!isSelected || status?.type !== "not_sent"}
          variant="contained"
          disableElevation
          size="small"
          aria-label="send file"
          color="primary"
          component="span"
          onClick={handleSend}
        >
          Send
        </Button>
      </Box>
      {errorFile && (
        <Typography variant="body1" color="error">
          File format should be xls or xlsx.
        </Typography>
      )}
      {isSelected && status?.type === "not_sent" ? (
        <Box mt={1}>
          <Typography variant="body1" display="inline">
            <AttachFileIcon className={classes.iconSize} />
            {selectedFile.name}
          </Typography>
        </Box>
      ) : (
        <div>
          {status?.type === "success" && (
            <Typography variant="body1" color="primary">
              Your file &quot;{selectedFile.name}&quot; was successfully
              uploaded!
            </Typography>
          )}
          {status?.type === "error" && (
            <Typography variant="body1" color="error">
              Error: Your file &quot;{selectedFile.name}
              &quot; was not uploaded
            </Typography>
          )}
        </div>
      )}
    </>
  );
};

UploadFileComponent.propTypes = {
  uploadUrl: PropTypes.string.isRequired,
  inputId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  uploadButtonLabel: PropTypes.string.isRequired,
  prepareForRender: PropTypes.func,
};

UploadFileComponent.defaultProps = {
  prepareForRender: null,
};

export default UploadFileComponent;
