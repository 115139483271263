import {
  AppBar,
  Box,
  Toolbar,
  Link,
  styled,
  useMediaQuery,
} from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import { useKeycloak } from "@react-keycloak/web";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../assets/images/ENO_BREATHE_LOGOTYPE_POS_AW-FINAL.svg";
import LoggedInHeaderContent from "./LoggedInHeaderContent";
import NotLoggedInHeaderContent from "./NotLoggedInHeaderContent";
import { SMALL_SCREEN_MEDIA_CONDITION } from "../responsive/media-conditions";

const StyledAppBar = styled(AppBar)({
  padding: 30,
  position: "sticky",
});

const AppHeader = ({ checkIsFullScreen }) => {
  const { keycloak, initialized } = useKeycloak();
  const location = useLocation();
  const isSmallScreen = useMediaQuery(SMALL_SCREEN_MEDIA_CONDITION);

  React.useEffect(() => {
    checkIsFullScreen(location.pathname);
  }, [location, checkIsFullScreen]);
  return (
    <>
      <StyledAppBar color="inherit" elevation={0}>
        <Toolbar>
          {/* Example of using a <Box/> wrapper to leverage CSS utilities */}
          <Box>
            <img src={logo} className="App-logo" alt="logo" />
          </Box>
          {initialized && (
            <>
              {keycloak.authenticated ? (
                <LoggedInHeaderContent />
              ) : (
                <>
                  <NotLoggedInHeaderContent />
                  {!isSmallScreen && (
                    <Box sx={{ visibility: "hidden" }}>
                      <Link component={NavLink} exact to="/">
                        <img src={logo} className="App-logo" alt="logo" />
                      </Link>
                    </Box>
                  )}
                </>
              )}
            </>
          )}
        </Toolbar>
      </StyledAppBar>
      <Toolbar />
    </>
  );
};
AppHeader.propTypes = {
  checkIsFullScreen: PropTypes.func.isRequired,
};
export default AppHeader;
