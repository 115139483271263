import homeRteContent from "../mocks/homeRteContent";
import aboutRteContent from "../mocks/aboutRteContent";
import whoIsForRteContent from "../mocks/whoIsForRteContent";
import {
  meetTheTeamFirst,
  meetTheTeamSecond,
  meetTheTeamThird,
  meetTheTeamFour,
  meetTheTeamFive,
  meetTheTeamSix,
  meetTheTeamSeven,
  meetTheTeam03,
  meetTheTeam04,
  meetTheTeam05,
  meetTheTeam06,
  meetTheTeam07,
  meetTheTeam08,
  meetTheTeam09,
  meetTheTeam10,
  meetTheTeam11,
  meetTheTeam12,
  meetTheTeam13,
  meetTheTeam14,
  meetTheTeam15,
  meetTheTeam16,
} from "../mocks/meetTheTeam";
import faqRteContent from "../mocks/faqRteContent";
import howEnoBreatheHelpedMeRteContent from "../mocks/howEnoBreatheHelpedMeRteContent";
import Home from "./Home";
import HowEnoBreatheHelpedMe from "./HowEnoBreatheHelpedMe";
import MeetTheTeam from "./MeetTheTeam";
import ContentPage from "./ContentPage";
import howEnoBreatheHelpedMeTestimonialsRteContent from "../mocks/howEnoBreatheHelpedMeTestimonialsRteContent";
import AboutTheEnoBreatheProgramme from "./AboutTheEnoBreatheProgramme";
import {
  contactUsRteContent,
  contactUsRteContent2,
} from "../mocks/contactUsRteContent";
import ContactUs from "./ContactUs";
import AdminPage from "./AdminPage";

/**
 * @name contentPagesConfig
 * @description List of editable pages
 * @type {({path: string, component: ReactElement, meta: {heroImage: {alt: string, url: string}, title: string, editableContentLocations: array, breadcrumbs: boolean, navAnchors: string}})[]}
 */
const contentPagesConfig = [
  {
    path: "",
    component: Home,
    meta: {
      title: "ENO Breathe",
      breadcrumbs: false,
      heroImage: {
        url:
          "https://english-national-opera-live.s3.amazonaws.com/wp-content/uploads/2021/06/ENOBreatheDandelions001_Website-scaled.jpg",
        alt: "The moon on a blue background, and dandelions beneath",
      },
      navAnchors: false,
      editableContentLocations: [homeRteContent],
    },
  },
  {
    path: "about-the-eno-breathe-programme",
    component: AboutTheEnoBreatheProgramme,
    meta: {
      title: "About the ENO Breathe Programme",
      breadcrumbs: true,
      heroImage: {
        url:
          "https://english-national-opera-live.s3.amazonaws.com/wp-content/uploads/2021/01/AboutENOBreatheHero.jpg",
        alt: "A camera filming a set with people and dandelions",
      },
      navAnchors: "header-two",
      // TODO: replace with endpoint URL
      editableContentLocations: [aboutRteContent],
    },
  },
  {
    path: "who-is-the-programme-for",
    component: ContentPage,
    meta: {
      title: "Who is the programme for?",
      breadcrumbs: true,
      heroImage: {
        url:
          "https://english-national-opera-live.s3.amazonaws.com/wp-content/uploads/2021/01/WhoBreatheisForHero.jpg",
        alt: "A dandelion on blue background",
      },
      navAnchors: false,
      // TODO: replace with endpoint URL
      editableContentLocations: [whoIsForRteContent],
    },
  },
  {
    path: "faqs",
    component: ContentPage,
    meta: {
      title: "FAQs",
      breadcrumbs: true,
      heroImage: {
        url:
          "https://english-national-opera-live.s3.amazonaws.com/wp-content/uploads/2021/01/ENOBreathePlaylistHero.jpg",
        alt: "A dandelion on orange background",
      },
      navAnchors: "SUBTITLE",
      // TODO: replace with endpoint URL
      editableContentLocations: [faqRteContent],
    },
  },
  {
    path: "how-eno-breathe-helped-me",
    component: HowEnoBreatheHelpedMe,
    meta: {
      title: "How ENO Breathe helped me",
      breadcrumbs: true,
      heroImage: {
        url:
          "https://english-national-opera-live.s3.amazonaws.com/wp-content/uploads/2021/01/HowBreatheHelpedMeHero.jpg",
        alt:
          "A screenshot of participants in a video call holding up mugs that are personalized with the ENO logo",
      },
      navAnchors: false,
      editableContentLocations: [
        howEnoBreatheHelpedMeRteContent,
        howEnoBreatheHelpedMeTestimonialsRteContent,
      ],
    },
  },
  {
    path: "meet-the-eno-breathe-team",
    component: MeetTheTeam,
    meta: {
      title: "Meet the Team",
      breadcrumbs: true,
      heroImage: {
        url:
          "https://english-national-opera-live.s3.amazonaws.com/wp-content/uploads/2021/01/MeettheteamFeat-800x400.jpg",
        alt: "A member of the team",
      },
      navAnchors: false,
      // TODO: replace with endpoint URL
      editableContentLocations: [
        meetTheTeamFirst,
        meetTheTeamSecond,
        meetTheTeamThird,
        meetTheTeamFour,
        meetTheTeamFive,
        meetTheTeamSix,
        meetTheTeamSeven,
        meetTheTeam03,
        meetTheTeam04,
        meetTheTeam05,
        meetTheTeam06,
        meetTheTeam07,
        meetTheTeam08,
        meetTheTeam09,
        meetTheTeam10,
        meetTheTeam11,
        meetTheTeam12,
        meetTheTeam13,
        meetTheTeam14,
        meetTheTeam15,
        meetTheTeam16,
      ],
    },
  },
  {
    path: "contact-us",
    component: ContactUs,
    meta: {
      title: "Contact Us",
      breadcrumbs: true,
      heroImage: {
        url:
          "https://english-national-opera-live.s3.amazonaws.com/wp-content/uploads/2021/01/BreatheFAQsHero.jpg",
        alt:
          "A screenshot of participants in a video call holding up mugs that are personalized with the ENO logo",
      },
      navAnchors: false,
      editableContentLocations: [contactUsRteContent, contactUsRteContent2],
    },
  },
  {
    path: "admin/dashboard",
    component: AdminPage,
    meta: {
      title: "Admin Dashboard",
    },
    showIfAuthenticated: true,
  },
];

export default contentPagesConfig;
