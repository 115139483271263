import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import { makeStyles } from "@material-ui/core";
import ErrorPanel from "../../../components/ErrorPanel";
import { getAllGroupsHeaders } from "../../../services/groupsService";
import useLoadDataFromAPI from "../../../utils/useLoadDataFromAPI";
import endpoints from "../../../utils/endpoints";

const SurveyTypes = {
  1: "PRE PROGRAMME SURVEY",
  2: "END OF PROGRAMME SURVEY",
  3: "3 MONTHS FOLLOW UP SURVEY",
};

const useStyles = makeStyles({
  orangeText: {
    color: "orange",
  },
  redText: {
    color: "red",
  },
  paddingVertical10: {
    padding: 10,
  },
  paddingLeft50: {
    paddingLeft: 50,
  },
});

const SurveyDetail = () => {
  const { surveyId, userId } = useParams();
  const classes = useStyles();
  const [groupsHeaders, setGroupsHeaders] = useState(getAllGroupsHeaders());
  const [forceReload, setForceReload] = useState(false);

  const { data: surveyScoreData, loading, statusCode } = useLoadDataFromAPI({
    url: endpoints.getUserSurvey
      .replace("{userId}", userId)
      .replace("{surveyId}", surveyId),
    method: "GET",
    forceReload,
    setForceReload,
  });

  const tableConfig = [
    {
      label: "First Name",
      key: "firstname",
    },
    {
      label: "Last Name",
      key: "lastname",
    },
    {
      label: "Group Number",
      key: "groupNumber",
    },
    {
      label: "Pre programme survey completed",
      key: "survey1",
    },
    {
      label: "End of programme survey completed",
      key: "survey2",
    },
    {
      label: "3 months follow up survey completed",
      key: "survey3",
    },
    {
      label: "Hopes to gain",
      key: "HOPES_TO_GAIN",
    },
    {
      label: "Medical conditions",
      key: "MEDICAL_CONDITIONS",
    },
    {
      label: "Medication/Other treatments",
      key: "OTHER_TREATMENT",
    },
  ];
  const table2Config = [
    {
      label: "At rest:",
      key: "AT_REST",
      parent: "Breathlessness",
    },
    {
      label: "During activity - Walking around the house:",
      key: "DURING_ACTIVITY_WALKING_AROUND",
      parent: "Breathlessness",
    },
    {
      label: "During activity - Climbing stairs:",
      key: "DURING_ACTIVITY_CLIMBING_STAIRS",
      parent: "Breathlessness",
    },
    {
      label: "During activity - Running:",
      key: "DURING_ACTIVITY_RUNNING",
      parent: "Breathlessness",
    },
    {
      label: "GAD 7",
      key: "gad7",
    },
    {
      label: "Physical Functioning",
      key: "sf36PhysicalFunctioning",
      parent: "RAND 36",
    },
    {
      label: "Role Limitations Due To Physical Health",
      key: "sf36RoleLimitationsDueToPhysicalHealth",
      parent: "RAND 36",
    },

    {
      label: "Pain",
      key: "sf36Pain",
      parent: "RAND 36",
    },
    {
      label: "General Health",
      key: "sf36GeneralHealth",
      parent: "RAND 36",
    },
    {
      label: "Energy Or Fatigue",
      key: "sf36EnergyOrFatigue",
      parent: "RAND 36",
    },
    {
      label: "Social Functioning",
      key: "sf36SocialFunctioning",
      parent: "RAND 36",
    },
    {
      label: "Role Limitations Due To Emotional Problems",
      key: "sf36RoleLimitationsDueToEmotionalProblems",
      parent: "RAND 36",
    },
    {
      label: "Emotional Well Being",
      key: "sf36EmotionalWellBeing",
      parent: "RAND 36",
    },
    {
      label: "Notes from 1:1",
      key: "notesFromOneOnOne",
      tableType: "text",
    },
  ];
  const getLabel = (value) => {
    if (value !== undefined) {
      if (typeof value == "boolean") {
        return value ? "Yes" : "No";
      }
      return value;
    }
    return value;
  };
  const renderedParents = {};
  const isJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };
  const getNumberOfChildComponents = (owner, parent) => {
    if (!owner || !parent) {
      return 1;
    }
    renderedParents[parent] = true;
    return owner.filter((item) => item.parent === parent).length;
  };
  return (
    <>
      {surveyScoreData && (
        <h1>
          {SurveyTypes[
            surveyScoreData.userSurveyScores?.scores?.survey?.surveyNumber
          ] || "Survey"}
        </h1>
      )}

      {statusCode >= 400 && (
        <ErrorPanel heading="Error loading data">
          The page could not be loaded. Please try again later ot contact the
          administrator
        </ErrorPanel>
      )}
      {loading && <div>Loading...</div>}
      {!loading && statusCode < 400 && surveyScoreData && (
        <>
          <TableContainer component={Paper}>
            <Table
              stickyHeader
              aria-label="survey scores table"
              className={classes.paddingVertical10}
            >
              <TableBody>
                {tableConfig.map((rowInfo) => (
                  <TableRow>
                    <TableCell>{rowInfo.label}</TableCell>
                    <TableCell>
                      {(rowInfo.key &&
                        getLabel(
                          surveyScoreData.userSurveyScores[rowInfo.key]
                        )) ||
                        getLabel(
                          surveyScoreData.userSurveyScores
                            .surveyAnswersByCategory[rowInfo.key]
                        )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <p>Survey details</p>
          <TableContainer component={Paper}>
            <Table
              stickyHeader
              aria-label="survey scores table"
              className={classes.paddingVertical10}
            >
              <TableBody>
                {table2Config.map((rowInfo) => (
                  <TableRow>
                    {rowInfo.parent && !renderedParents[rowInfo.parent] && (
                      <TableCell
                        rowSpan={getNumberOfChildComponents(
                          table2Config,
                          rowInfo.parent
                        )}
                      >
                        {rowInfo.parent}
                      </TableCell>
                    )}
                    <TableCell colSpan={rowInfo.parent ? 1 : 2}>
                      {rowInfo.label}
                    </TableCell>
                    <TableCell>
                      {rowInfo.key &&
                        ((surveyScoreData.userSurveyScores.scores &&
                          (getLabel(
                            surveyScoreData.userSurveyScores[rowInfo.key]
                          ) ||
                            getLabel(
                              surveyScoreData.userSurveyScores.scores[
                                rowInfo.key
                              ]
                            ))) ||
                          (surveyScoreData.userSurveyScores
                            .surveyAnswersByCategory &&
                            getLabel(
                              surveyScoreData.userSurveyScores
                                .surveyAnswersByCategory[rowInfo.key]
                            )))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <p>Survey answers</p>
          <TableContainer component={Paper}>
            <Table stickyHeader className={classes.paddingVertopopaical10}>
              <TableBody>
                {surveyScoreData.questions.map((questionInfo) => (
                  <TableRow>
                    {!questionInfo.answer && (
                      <TableCell colSpan={2}>{questionInfo.text}</TableCell>
                    )}
                    {questionInfo.answer && (
                      <>
                        <TableCell
                          className={
                            questionInfo.parentQuestionOrder
                              ? classes.paddingLeft50
                              : null
                          }
                        >
                          {questionInfo.text.length > 6 && (
                            <>
                              {questionInfo.text}
                              <br />
                            </>
                          )}
                          {questionInfo.type === "SLIDER"
                            ? JSON.parse(questionInfo.availableAnswers).join(
                                " / "
                              )
                            : null}
                        </TableCell>
                        <TableCell>
                          {isJsonString(questionInfo.answer) &&
                          Array.isArray(JSON.parse(questionInfo.answer)) ? (
                            <ul style={{ marginLeft: -40 }}>
                              {JSON.parse(questionInfo.answer).map((answ) => (
                                <li key={answ}>{answ}</li>
                              ))}
                            </ul>
                          ) : (
                            questionInfo.answer
                          )}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
  // <Table stickyHeader className={classes.paddingVertical10}>
  //   <TableHead>
  //     {tableConfig.map((rowInfo) => (
  //         <TableRow>
  //           <TableCell>{rowInfo.label}</TableCell>
  //           <TableCell colSpan={2}>
  //             {rowInfo.key}
  //             {rowInfo.key && getLabel(mockData[rowInfo.key])}
  //           </TableCell>
  //         </TableRow>
  //     ))}
  //   </TableHead>
  //   <TableBody>
  //     {mockData.questions
  //         ?.filter((item) => !!item.answer)
  //         .map((questionInfo) => (
  //             <TableRow>
  //               <TableCell>
  //                 {questionInfo.parentQuestionOrder} - {questionInfo.id}
  //                 {questionInfo.parentQuestionOrder &&
  //                 mockData.questions.filter(
  //                     (row) => row.id === questionInfo.parentQuestionOrder
  //                 )[0].text}
  //               </TableCell>
  //               <TableCell>
  //                 {questionInfo.text.length > 6 && (
  //                     <>
  //                       {questionInfo.text}
  //                       <br />
  //                     </>
  //                 )}
  //                 {questionInfo.type === "SLIDER"
  //                     ? JSON.parse(questionInfo.availableAnswers).join(" / ")
  //                     : null}
  //               </TableCell>
  //
  //               <TableCell>{questionInfo.answer}</TableCell>
  //             </TableRow>
  //         ))}
  //   </TableBody>
  // </Table>
};

export default SurveyDetail;
